import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericFilterObject, Page } from '../../shared/models/generic-filter-object.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssociateDetailsPageRedirectService {
    // parent page state
    genericFilterObj: GenericFilterObject;
    associateId: number;

    redirectToAssociateDetails = false;

    // configs for updating the page state
    paginationConfig = new BehaviorSubject<Page>(null);
    searchFormConfig = new BehaviorSubject<UntypedFormGroup>(null);
    sortHeadersConfig = new BehaviorSubject<string[]>(null);

    constructor() { }

    saveAssociatesPageState(genericFilterObj: GenericFilterObject, merchandiserId: number) {
        this.genericFilterObj = genericFilterObj;
        this.associateId = merchandiserId;
    }

    setRedirectToAssociateDetails() {
        this.redirectToAssociateDetails = true;
    }

    resetRedirectToAssociateDetails() {
        this.genericFilterObj = null;
        this.associateId = null;
        this.redirectToAssociateDetails = false;
    }

    isRedirectToAssociateDetails() {
        return this.redirectToAssociateDetails === true && this.genericFilterObj != null;
    }

    updatePageState() {
        this.paginationConfig.next(this.genericFilterObj.page);
        this.searchFormConfig.next(this.genericFilterObj.form);
        this.sortHeadersConfig.next(this.genericFilterObj.page?.sort);
    }

    getFilterObj(): GenericFilterObject {
        return JSON.parse(JSON.stringify(this.genericFilterObj));
    }
}
