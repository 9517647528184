import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { filter, map, Observable, take } from "rxjs";

import { RolesEnum } from './../../shared/enum/role.enum';
import { UserAuthService } from './../../shared/services/user-auth.service';


@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {
	constructor(
		private router: Router,
		private userAuthService: UserAuthService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if (this.userAuthService.isLoginComplete()) {
			return this.handleRoleAccess(route);
		} else {
			// Wait until authentication is complete
			return this.userAuthService.loginComplete$
				.pipe(
					filter(authComplete => authComplete),
					take(1),
					map(() => {
						return this.handleRoleAccess(route);
					})
				);
		}
	}

	handleRoleAccess(route: ActivatedRouteSnapshot) {
		let excludedRoles: RolesEnum[] = route.data?.excludedRoles || [];
		let currentPath = route['_routerState']?.url.slice(1)

		/* If the roles of the user are included in the exclusion list of the page then it means that he does not have access to that page */
		if (excludedRoles.length > 0
			&& excludedRoles.some(excludedRole => this.userAuthService.getRoles().includes(excludedRole))) {

			if (currentPath === 'my-events' && this.userAuthService.hasLdapCredentials()) {
				this.router.navigate(['/associates']);
				return false;
			}
			this.router.navigate(['/not-authorized']);
			return false;
		}

		return true;
	}
}