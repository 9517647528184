import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import {ChatModuleRouting} from './chat.routing';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {StreamAutocompleteTextareaModule, StreamChatModule} from 'stream-chat-angular';
import { AddChannelComponent } from './add-channel/add-channel.component';


@NgModule({
  declarations: [
    ChatComponent,
    AddChannelComponent
  ],
  imports: [
    CommonModule,
    ChatModuleRouting,
    SharedModule,
    TranslateModule.forRoot(),
    StreamAutocompleteTextareaModule,
    StreamChatModule
  ]
})
export class ChatModule { }
