import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LoginService} from './login.service';
import {Purpose} from '../../shared/models/schedule/purpose.model';

@Injectable({
  providedIn: 'root'
})
export class PurposeService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  getPurposes(country: string, type: string): Observable<Purpose[]> {
    return this.http.get<Purpose[]>(`${environment.apiResourceUri}/purposes/country/${country}/type/${type}`);
  }

  getPurposeIdsByScheduleId(scheduleId: string): Observable<Number[]> {
    return this.http.get<Number[]>(`${environment.apiResourceUri}/purposes/schedule/${scheduleId}`);
  }
}
