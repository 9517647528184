import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NotFoundOrAuthorizedService} from '../../core/services/not-found-or-authorized.service';
import {
  ChannelService,
  ChatClientService, CustomTemplatesService,
  DefaultStreamChatGenerics,
  StreamI18nService, StreamMessage, ThemeService
} from 'stream-chat-angular';
import {Channel} from 'stream-chat';
import {environment} from '../../../environments/environment';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {ChatService} from '../../core/services/chat.service';
import {LoginService} from '../../core/services/login.service';
import {Router} from '@angular/router';
import {AddChannelComponent} from './add-channel/add-channel.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  searchControl = new UntypedFormControl('');
  options: Channel<DefaultStreamChatGenerics>[] = [];

  isMenuOpen = true;
  themeVersion: '1' | '2';
  theme$: Observable<string>;
  hideJumpToLatestButtonDuringScroll: boolean;

  checkActiveChannel = false;
  intervalDisconnect = 2 * 60 * 60000;

  isDisconnectInProgress = false;
  connectPromise?: Promise<any>;
  disconnectPromise?: Promise<any>;
  disconnectTimeoutIdTab;

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.channelService.reset();
    this.notFoundOrAuthorizedService.hide();
  }

  @HostListener('window:beforeunload', ['$event'])
  async onBeforeUnload() {
    if (this.getStreamSvc.chatClient) {
      await this.disconnect();
      console.log('page closed');
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  async visibilitychange() {
    await this.checkHiddenDocument();
  }

  constructor(
    public getStreamSvc: ChatClientService,
    public channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private customTemplatesService: CustomTemplatesService,
    private notFoundOrAuthorizedService: NotFoundOrAuthorizedService,
    private location: Location,
    private dialog: MatDialog,
    private chatSvc: ChatService,
    private loginSvc: LoginService,
    private router: Router,
    private themeService: ThemeService
  ) {
    void this.getStreamSvc.init(
      environment.chat.apiKey,
      this.loginSvc._eid,
      () => this.getToken()
    );
    this.getStreamSvc.chatClient!.wsConnection!._log = console.log;

    this.initChannels();
    this.streamI18nService.setTranslation();
    this.notFoundOrAuthorizedService.show();

    this.themeVersion = themeService.themeVersion;
    this.theme$ = themeService.theme$;

    this.setJumpToLatestOption();
  }

  ngOnInit() {
    this.notFoundOrAuthorizedService.show();
    this.channelService.reset();

    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(),
        switchMap((value) => {
          if (value?.length === 0) {
            this.channelService.reset();
            return this.initChannels();
          }
          if (value?.length < 3) {
            return of(null);
          }
          if (value instanceof Object || value === '' || !value) {
            return of(null);
          }

          const filter = {
            type: 'messaging',
            'member.user.name': {
              $autocomplete: value
            },
            members: { $in: [ this.loginSvc._eid ] }
          };

          this.channelService.reset();
          return this.channelService.init(filter);
        })
      ).subscribe(
        (channels: Channel<DefaultStreamChatGenerics>[]) => (this.options = channels)
      );
  }

  ngOnDestroy() {
    this.disconnect();
  }

  setJumpToLatestOption() {
    if (
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      this.hideJumpToLatestButtonDuringScroll = true;
    } else {
      this.hideJumpToLatestButtonDuringScroll = false;
    }
  }

  async initChannels() {
    await this.channelService.init({
      type: 'messaging',
      members: { $in: [this.loginSvc._eid] },
      joined: true
    }, {
      last_updated: -1
    }, {
      limit: 30
    }).then(_response => {
        this.loadOldChannels();
        setTimeout(() => {
          this.autoOpenConversations();
        }, 1);
    }
    );
  }

  ngAfterViewInit(): void {
    this.notFoundOrAuthorizedService.show();
  }

  goBack() {
    this.location.back();
    this.channelService.reset();
  }

  redirectToMainPage() {
    this.notFoundOrAuthorizedService.hide();
    this.router.navigate(['/my-events']);
  }

  getActiveChannelFromHistoryState() {
    const filter = {
      type: 'messaging',
      cid: history.state.data,
      members: { $in: [ this.loginSvc._eid ] }
    };
    this.getStreamSvc.chatClient.queryChannels(filter, {}, {
      watch: true, // this is the default
      state: true,
      limit: 30
    }).then(res => {
      this.channelService.setAsActiveChannel(res[0]);
    });
  }

  loadChannelsUntilSelectedFound(channelCid: string) {
    let checkActiveChannel = false;
    this.channelService.channels$.subscribe(res => {
      res.forEach(channel => {
        if (channel.cid === channelCid) {
          checkActiveChannel = true;
        }
      });
      if (checkActiveChannel === false) {
        this.channelService.loadMoreChannels();
      }
    });
  }

  createChannel() {
    this.dialog.open(AddChannelComponent, {
      disableClose: true
    }).afterClosed().subscribe(r => {
      if (r) {
        this.isMenuOpen = false;
      }
    });
  }

  async getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.chatSvc.authenticate().subscribe(
        response => resolve(response.token),
        error => reject('An error has occurred when getting the GetStream user authentication token.')
      );
    });
  }

  async checkHiddenDocument() {
    if (document.hidden) {
      console.log('tab left');
      this.disconnectTimeoutIdTab = setTimeout(async () => {
        this.redirectToMainPage();
      }, this.intervalDisconnect);
    } else {
      console.log('tab active');
      clearTimeout(this.disconnectTimeoutIdTab);
      this.disconnectTimeoutIdTab = undefined;
    }
  }

  private async disconnect() {
    if (this.isDisconnectInProgress) {
      return;
    }

    this.isDisconnectInProgress = true;
    this.disconnectPromise = new Promise<void>(async (resolve, reject) => {
      if (!this.getStreamSvc.chatClient.user) {
        this.isDisconnectInProgress = false;
        resolve();
        return;
      }
      try {
        await this.getStreamSvc.disconnectUser();
        this.channelService.reset();
        this.isDisconnectInProgress = false;
        resolve();
      } catch (e) {
        this.isDisconnectInProgress = false;
        reject(e);
      }
    });
  }

  private autoOpenConversations() {
    const chat_icon = document.getElementsByClassName('str-chat__header-hamburger');
    if (chat_icon[0] && window.innerWidth < 961) {
      (chat_icon[0] as HTMLElement).click();
    }
  }

  private loadOldChannels() {
    if (history.state.data) {
      this.getActiveChannelFromHistoryState();
      this.loadChannelsUntilSelectedFound(history.state.data);
    }
  }

  jumpToMessage(message: StreamMessage) {
    this.channelService.jumpToMessage(message.id, message.parent_id);
  }

  unpinMessage(message: StreamMessage<DefaultStreamChatGenerics>) {
    this.channelService.unpinMessage(message);
  }

  closeMenu() {
    this.isMenuOpen = false;
  }
}
