import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(private http: HttpClient) { }

  uploadPhoto(file: File, fileName: string): Observable<HttpEvent<any>>  {
    const formData: FormData = new FormData();
    formData.append('file', file, fileName);

    const req = new HttpRequest('POST', `${environment.apiResourceUri}/gallery`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
}
