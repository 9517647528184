import {Component, OnInit} from '@angular/core';
import {StoresService} from '../../../core/services/stores.service';
import {TerritoryService} from '../../../core/services/territory-management.service';
import {StoreDetails} from '../../../shared/models/stores/store-details.model';
import {AssociateService} from '../../../core/services/associates.service';
import {AssociateDetails} from '../../../shared/models/associates/associate-details.model';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ScheduleModel, ScheduleType} from '../../../shared/models/schedule/schedule.model';
import {ScheduleDetails} from '../../../shared/models/schedule/schedule-details.model';
import {ScheduleService} from '../../../core/services/schedule.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {SCHEDULE_TYPE} from '../../../shared/constants/schedule.constants';
import {buildAddPurposeAction, buildDeletePurposeAction, Purpose, PurposeAction} from '../../../shared/models/schedule/purpose.model';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';
import {LoginService} from '../../../core/services/login.service';
import {TitleService} from '../../../core/services/title.service';
import {PurposeService} from '../../../core/services/purpose.service';
import {PurposeType} from '../../../shared/enum/purpose.enums';
import {ScheduleResponse} from '../../../shared/models/schedule/schedule-response.model';

@Component({
  selector: 'app-schedule-training',
  templateUrl: './schedule-training.component.html',
  styleUrls: ['./schedule-training.component.scss']
})
export class ScheduleTrainingComponent implements OnInit {

  stores: StoreDetails[] = [];
  associate: AssociateDetails;
  schedule: ScheduleDetails;
  isEditFromUnscheduled: boolean;
  isRedirectFromAssociateDetails: boolean;

  storeDataFrom: UntypedFormGroup;
  purposes: Purpose[];

  // error list
	messageList = [];
	showNotification = false;

  constructor(private terrService: TerritoryService,
              private storesService: StoresService,
              private associatesService: AssociateService,
              private msgBanner: MsgBannerService,
              private scheduleService: ScheduleService,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private loginSvc: LoginService,
              private titleSvc: TitleService,
              private router: Router,
              private purposeService: PurposeService)
  {
    this.initFormGroups();
  }

  ngOnInit(): void {
    if (this.loginSvc.isUkUser()) {
      this.titleSvc.updateTitle(CountryGlobalVariables.UK.trainingSchedule);
    } else {
      this.titleSvc.updateTitle(CountryGlobalVariables.US.trainingSchedule);
    }

    this.loadData();
  }

  initFormGroups() {
    this.storeDataFrom = this.fb.group({
      store: [null, [Validators.required], []],
      startTime: [ null, [], []],
      date: [ null, [], []],
    });
  }

  loadData() {
    if (history.state.data) {
      this.isRedirectFromAssociateDetails = history.state.data.isRedirectFromAssociateDetails;
    }

    // new scheduled training
    if (history.state.data && history.state.data.associateId) {
      var associateId = history.state.data.associateId;
      this.getAssociateDetails(associateId);
      this.getStoresByAssociateId(associateId);
      this.getPurposes();
      return;
    }

    // edit scheduled training
    if (history.state.data && history.state.data.scheduleId) {
      var scheduleId = history.state.data.scheduleId;
      this.getSchedule(scheduleId);
      this.processIsUnscheduledEdit(history.state.data.isEditFromUnscheduled);
      return;
    }

    this.goToMyEventsPage(true);
  }

  getSchedule(scheduleId: string) {
    this.scheduleService.getSchedule(scheduleId).subscribe(
      (response: ScheduleDetails) => {
        this.schedule = response;
        this.fillFormWithScheduleValues(this.schedule);
        this.getAssociateDetails(this.schedule.merchandiserId);
        this.getStoresByAssociateId(this.schedule.merchandiserId);
      },
      (error) => {
        // display error
        if (error.status === 404)
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        else
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');

        this.showNotification = true;
      }
    );

  }

  getAssociateDetails(associateId: number) {
    if (associateId == null)
      return;

    this.associatesService.getAssociateDetails(associateId).subscribe(
      (response: AssociateDetails) => {
        this.associate = response;
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
          this.showNotification = true;
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
          this.showNotification = true;
        }
      }
    );
  }

  getStoresByAssociateId(associateId: number) {
    var terr = this.terrService.getTerritory();
    this.storesService.findAllStoresByMerchandiserIdAndTerritory(associateId, terr).subscribe(
      (response: StoreDetails[]) => {
        this.stores = response;
        this.processSelectedStoreIsDisabled();
        if (this.stores.length === 1 && !this.storeDataFrom.controls.store.value) {
          this.storeDataFrom.controls.store.setValue(this.stores[0].id);
        }
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        this.showNotification = true;
      }
    );
  }

  processSelectedStoreIsDisabled() {
    let selectedStoreId = this.storeDataFrom.controls.store.value;
    if (selectedStoreId == null)
      return;

    let store = this.stores.find(s => s.id === selectedStoreId);
    if (store === undefined)
      this.storeDataFrom.controls.store.setValue(null);
  }

  onChangeStoreSelection(store: StoreDetails) { }

  fillFormWithScheduleValues(schedule: ScheduleDetails) {
    this.storeDataFrom.controls.store.setValue(schedule.retailerId);
    if (schedule.scheduledDate) {
      var scheduledDate = new Date(schedule.scheduledDate);
      this.storeDataFrom.controls.date.setValue(scheduledDate);
      var time = this.getFormattedTime(scheduledDate.getHours(), scheduledDate.getMinutes());
      this.storeDataFrom.controls.startTime.setValue(time);
    }

    this.fillPurposesByScheduleData(schedule);
  }

  onSaveEmitter(data: ScheduleModel) {
    this.showNotification = false;
    var scheduleId = this.schedule ? this.schedule.id : null;
    var date = data.scheduledDate;

    // check schedule conflict for date and time
    if (date != null && !data.isAllDayEvent) {
      this.scheduleService.checkScheduledDateTimeConflict(scheduleId, date).subscribe(
        (response: number) => {
          if (response > 0) {
            this.displayDateTimeConflictDialog(data);
            return;
          }
          // no conflict
          if (scheduleId == null) {
            this.createSchedule(data);
          } else {
            data.id = scheduleId;
            this.updateSchedule(data);
          }
        },

        (error) => {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
          this.showNotification = true;
        }
      );
      return;
    }

    if(scheduleId == null) {
      this.createSchedule(data);
    } else {
      data.id = scheduleId;
      this.updateSchedule(data);
    }
  }

  createSchedule(data: ScheduleModel) {
    data.scheduleType = ScheduleType.TRAINING;
    data.purposeActions = this.buildActionsForScheduleAssignedPurposes();

    this.scheduleService.createSchedule(data).subscribe(
      (response: ScheduleResponse) => {
        const scheduledEvent = data.scheduledDate != null;
        this.displayConfirmationMessage(response.outlookEventSuccess, scheduledEvent);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        this.showNotification = true;
      }
    );
  }

  updateSchedule(schedule: ScheduleModel) {
    schedule.purposeActions = this.buildActionsForScheduleAssignedPurposes();

    this.scheduleService.updateSchedule(schedule).subscribe(
      (response: ScheduleResponse) => {
        const scheduledEvent = schedule.scheduledDate != null;
        this.displayConfirmationMessage(response.outlookEventSuccess, scheduledEvent);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        this.showNotification = true;
      }
    );
  }

  displayConfirmationMessage(isOutlookSuccess: boolean, scheduledEvent: boolean) {
    let message = isOutlookSuccess ? 'Submitted successfully!' :
        'Schedule submitted successfully, but the Outlook Event failed to be created!';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: message,
        confirmationRequired: false
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
        this.goToMyEventsPage(scheduledEvent);
    });
  }

  goToMyEventsPage(scheduledEvent: boolean) {
		const data = scheduledEvent ? null : { scheduleType: SCHEDULE_TYPE.UNSCHEDULED }
		this.router.navigate(['/my-events'], { state: { data } });
	}

  displayDateTimeConflictDialog(data: ScheduleModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: 'An event already exists at that time. Are you sure you want to create another one?',
        confirmationRequired: true,
        yesButtonShow: true,
        cancelButtonShow: true
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        var scheduleId = this.schedule ? this.schedule.id : null;
        if(scheduleId == null) {
          this.createSchedule(data);
        } else {
          data.id = scheduleId;
          this.updateSchedule(data);
        }
      }
    });
  }

  getFormattedTime(hrs: number, mins: number) {
    if (hrs == 0 && mins == 0)
      return null;

    let time: string = "";
    time += hrs < 10 ? ("0" + hrs) : hrs.toString();
    time += ":";
    time += mins < 10 ? ("0" + mins) : mins;

    return time;
  }

  processIsUnscheduledEdit(isEditFromUnscheduled: boolean) {
    // if event is edited from the unscheduled page
    if (isEditFromUnscheduled == null)
      return;

    this.isEditFromUnscheduled = isEditFromUnscheduled;
  }

  getPurposes() {
    this.purposeService.getPurposes(this.loginSvc._country, PurposeType.TRAINING).subscribe(
      (response: Purpose[]) => {
        this.purposes = response;
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        this.showNotification = true;
      }
    );
  }

  fillPurposesByScheduleData(schedule: ScheduleDetails) {
    this.purposeService.getPurposes(this.loginSvc._country, PurposeType.TRAINING).subscribe(
      (response: Purpose[]) => {
        this.purposes = response;
        if (schedule.assignedPurposes?.length > 0 ) {
          this.purposes.forEach(purpose => {
              if (schedule.assignedPurposes.includes(purpose.id)) {
                purpose.selected = true;
              }
          });
        }
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        this.showNotification = true;
      }
    );
  }

  buildActionsForScheduleAssignedPurposes (): PurposeAction[] {
    const actions: PurposeAction[] = [];

    if (this.schedule == null) {
      this.purposes.forEach(purpose => {
        if (purpose.selected === true) { actions.push(buildAddPurposeAction(purpose.id)); }});
      return actions;
    }

    this.purposes.forEach(purpose => {
      if (purpose.selected === true && !this.schedule.assignedPurposes.includes(purpose.id)) {
        actions.push(buildAddPurposeAction(purpose.id));
      } else if (purpose.selected === false && this.schedule.assignedPurposes.includes(purpose.id)) {
        actions.push(buildDeletePurposeAction(purpose.id));
      }
    });

    return actions;
  }
}
