import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {MaterialModule} from '../modules/material.module';
import {NotAuthorizedComponent} from './components/not-authorized/not-authorized.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import { ChangeTerritoryDialogComponent } from './components/change-territory-dialog/change-territory-dialog.component';
import { GenericViewComponent } from './components/generic-view/generic-view.component';
import {CoreModule} from '../core/core.module';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MsgBannerComponent} from './components/msg-banner/msg-banner.component';
import {MsgBannerService} from './components/msg-banner/msg-banner.service';
import { GenericGhostViewComponent } from './components/generic-ghost-view/generic-ghost-view.component';
import { GenericScheduleEventComponent } from './components/generic-schedule-event/generic-schedule-event.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormInputComponent } from './components/dynamic-form-input/dynamic-form-input.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { FileProgressComponent } from './components/file-upload-dialog/file-progress/file-progress.component';
import { DynamicHistoryComponent } from './components/dynamic-history/dynamic-history.component';
import { ViewMapComponent } from './components/view-map/view-map.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { EmailRecipientsComponent } from './components/email-recipients/email-recipients.component';
import { TagInputModule } from 'ngx-chips-angular';
import {AgmCoreModule} from '@agm/core';
import { RescheduleDialogComponent } from './components/reschedule-dialog/reschedule-dialog.component';
import { IncompatibleBrowserComponent } from './components/incompatible-browser/incompatible-browser.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { GenericMetricsTableComponent } from './components/generic-metrics-table/generic-metrics-table.component';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './components/dynamic-form-input/camera/camera.component';
import {ErrorHandlerModule} from './errors/error-handler.module';
import { EmailGeneratorComponent } from './components/dynamic-form/email-generator/email-generator.component';
import { LoadingComponent } from './components/loading/loading.component';
import { UserRoleDirective } from './../core/directives/user-role.directive';
import { NotAvailableComponent } from './components/not-available/not-available.component';
import { RescheduleDayDialogComponent } from './components/reschedule-day-dialog/reschedule-day-dialog.component';

@NgModule({
  declarations: [
    SidebarComponent,
    ConfirmDialogComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
	NotAvailableComponent,
    ChangeTerritoryDialogComponent,
    GenericViewComponent,
    MsgBannerComponent,
    GenericGhostViewComponent,
    GenericScheduleEventComponent,
    GenericGhostViewComponent,
    DynamicFormComponent,
    DynamicFormInputComponent,
    GenericViewComponent,
    GenericGhostViewComponent,
    FileUploadDialogComponent,
    FileProgressComponent,
    DynamicHistoryComponent,
    EmailRecipientsComponent,
    ViewMapComponent,
    InfoDialogComponent, 
    RescheduleDialogComponent,
	RescheduleDayDialogComponent,
    IncompatibleBrowserComponent,
    GenericMetricsTableComponent,
    CameraComponent,
    EmailGeneratorComponent,
    LoadingComponent,
	UserRoleDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    CoreModule,
    TagInputModule,
    AgmCoreModule,
    NgxMatSelectSearchModule,
    WebcamModule,
    ErrorHandlerModule
  ],
  exports: [
    SidebarComponent,
    CommonModule,
    MaterialModule,
    ConfirmDialogComponent,
    GenericViewComponent,
    MsgBannerComponent,
    CoreModule,
    GenericScheduleEventComponent,
    DynamicFormComponent,
    CoreModule,
    DynamicHistoryComponent,
    GenericMetricsTableComponent,
    WebcamModule,
    ErrorHandlerModule,
	UserRoleDirective
  ],
  providers: [
    MatSnackBar,
    MsgBannerService
  ]
})
export class SharedModule {}
