<div>
  <div
    fxLayout="column"
  >
    <app-msg-banner
      *ngIf="showNotification"
      [msgList]="messageList"
      (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <div
      mat-dialog-title
      fxLayout="row"
      fxLayoutAlign="space-between center">

      <div fxLayoutAlign="start">{{ title }}</div>
      <div fxLayoutAlign="end">
        <button
          mat-icon-button
          (click)="closeDialog()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div mat-dialog-content>
      <div
        class="header-name"
        *ngIf="isAudit()"
      >{{ firstLine }}</div>

      <div
        *ngFor="let topic of data.topicsData"
      >
        <div class="topic-title">{{ topic.topicName }}</div>

        <mat-divider></mat-divider>

        <div
          *ngFor="let question of topic.topicQuestions"
          class="question"
        >
          <div>
            <span class="question-name">{{ question.questionName }}: </span>
            <span *ngIf="!isEditable(question.questionName)">{{ question.questionValue ? question.questionValue : '' }}</span>

            <mat-form-field
              class="full-width"
              appearance="standard"
              *ngIf="isEditable(question.questionName)"
            >
              <textarea
                matInput
                placeholder="Enter notes..."
                [(ngModel)]="question.questionValue"
              ></textarea>
            </mat-form-field>
          </div>

          <div
            *ngIf="question?.photos"
            fxLayout="row"
            fxLayoutGap="10px"
            class="photo-layout"
          >
            <div
              *ngFor="let photo of question.photos"
              fxLayout="column"
              fxLayoutAlign="space-between center"
            >
              <div>
                <img
                  class="mini-img"
                  [src]="retrievePhoto(photo.photoName)"
                >
              </div>
              <div>
                <mat-checkbox
                  [checked]="!photo.deleted"
                  (change)="updatePhoto($event.checked, question, photo)"
                ></mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div>
          <span class="question-name">Additional Notes:</span>
          <mat-form-field
            class="full-width"
            appearance="standard"
          >
            <textarea
              matInput
              placeholder="Enter additional notes..."
              [(ngModel)]="topic.additionalNotes"
            ></textarea>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center"
      fxLayoutAlign.lt-md=" stretch"
      fxLayoutGap="10px"
      fxLayoutGap.lt-md="0"
    >
      <button
        mat-button
        class="btn btn-cancel btn-width-100"
        ngClass.lt-md="btn-width-full"
        (click)="cancel()"
      >Cancel</button>
      <button
        mat-button
        class="btn btn-send btn-width-100"
        ngClass.lt-md="btn-width-full"
        (click)="sendEmail()"
      >Send</button>
    </div>
  </div>
</div>
