import {CountryGlobalVariables} from './country-global-variables';

export const ROUTE = {
  MY_EVENTS: {
    PATH: 'my-events',
    NAME: 'My Events',
  },
  ASSOCIATES: {
    PATH: 'associates',
    NAME: CountryGlobalVariables.US.associates,
  },
  SERVICED_STORES: {
    PATH: 'serviced',
    NAME: 'Serviced Stores',
    URL: 'serviced'
  },
  NON_SERVICED_STORES: {
    PATH: 'non-serviced',
    NAME: 'Non-Serviced Stores',
    URL: 'non-serviced'
  },
  SCHEDULE_EVENT: {
    PATH: 'schedule-appointment',
    NAME: 'Schedule Event',
    URL: 'schedule-appointment'
  },
  CHAT: {
    PATH: 'chat',
    NAME: 'Chat',
  },
  ADMIN: {
    PATH: 'admin',
    NAME: 'Admin',
  }
};
