import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { Zone } from '../../shared/models/zone-update/zone.model';

@Injectable({
  providedIn: 'root'
})
export class ZoneUpdateService {

  constructor(private http: HttpClient) { }

  getPRoutesByTerritory(territory: number): Observable<Zone[]> {
    return this.http.get<Zone[]>(
        `${environment.apiResourceUri}/zone-update/territory/${territory}/p-route`);
  }

  getZonesByTerritory(territory: number): Observable<String[]> {
    return this.http.get<String[]>(
        `${environment.apiResourceUri}/zone-update/territory/${territory}/zones`);
  }

  public updateZone(zone: Zone): Observable<Zone> {
    return this.http.put<Zone>(
      `${environment.apiResourceUri}/zone-update`,
      zone
    );
  }
}
