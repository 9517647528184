import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Login} from '../../shared/models/login.model';
import { Country } from '../../shared/enum/country.enum';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  _loggedIn = false;
  _admin = false;
  _country: string = null;
  _eid: string = null;
  _name: string = null;
  _role: string = null;
  _isChatUser = false;
  isChatUserSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.isChatUserSubject.subscribe(value => {
      this._isChatUser = value;
    });
  }

  login(): Observable<Login> {
    return this.http.get<Login>(`${environment.apiResourceUri}/supervisors/login`);
  }

  isUkUser(): boolean {
    return this._country === Country.UK;
  }

  setIsChatUser(value: boolean) {
    this.isChatUserSubject.next(value);
  }
}
