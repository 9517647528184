import { FormField } from './../../../shared/models/training-audit/form-field.model';
import { Component, OnInit } from '@angular/core';
import { TrainingAuditService } from '../../../core/services/training-audit.service';
import { TrainingAudit, TrainingAuditData } from '../../../shared/models/training-audit/training-audit.model';
import { combineLatest, map, Observable } from 'rxjs';
import { QuestionsEnum } from '../../../shared/enum/questions.enum';
import { FormSection } from '../../../shared/models/training-audit/form-section.model';
import { FormControlService } from '../../../core/services/form-control.service';
import { PagesEnum } from '../../../shared/models/stores/pages.model';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { StoresService } from '../../../core/services/stores.service';
import { StoreDetails } from '../../../shared/models/stores/store-details.model';
import { Router } from '@angular/router';
import { Associate } from '../../../shared/models/associates/associate.model';
import { ScheduleType } from '../../../shared/models/schedule/schedule.model';
import { TitleService } from '../../../core/services/title.service';
import { CountryGlobalVariables } from '../../../shared/constants/country-global-variables';
import { LoginService } from '../../../core/services/login.service';

@Component({
	selector: 'app-audit-training-history',
	templateUrl: './audit-training-history.component.html',
	styleUrls: ['./audit-training-history.component.scss'],
})
export class AuditTrainingHistoryComponent implements OnInit {
	scheduleId: string;
	scheduleType: string;
	page: number;
	trainingAudit: Observable<TrainingAudit>;
	sections: Observable<FormSection[]>;
	stores: Observable<StoreDetails[]>;
	associate: Associate;
	storeDetails: StoreDetails;
	completedStatus: boolean = false;

	constructor(
		private trainingAuditService: TrainingAuditService,
		private terrService: TerritoryService,
		private storesService: StoresService,
		private formService: FormControlService,
		private router: Router,
		private loginSvc: LoginService,
		private titleSvc: TitleService
	) { }

	ngOnInit(): void {
		if (!history.state.data) {
			this.goToMyEventsPage();
			return;
		}

		this.scheduleId = history.state.data.scheduleId;
		this.scheduleType = history.state.data.scheduleType;
		this.page = Number(history.state.data.page);
		this.associate = history.state.data.associate;
		this.storeDetails = history.state.data.storeDetails;
		this.completedStatus = history.state.data?.completedStatus;

		this.findTrainingAuditById();
		this.sections = this.buildSections();

		if (this.scheduleType === ScheduleType.AUDIT) {
			this.titleSvc.updateTitle('Start Audit');
		} else {
			if (this.loginSvc.isUkUser()) {
				this.titleSvc.updateTitle(CountryGlobalVariables.UK.trainingStart);
			} else {
				this.titleSvc.updateTitle(CountryGlobalVariables.US.trainingStart);
			}
		}

		const terr = this.terrService.getTerritory();
		this.loadStores(this.page);
	}

	get pagesEnum() {
		return PagesEnum;
	}

	findTrainingAuditById() {
		this.trainingAudit =
			this.trainingAuditService.findTrainingAuditsByScheduleId(this.scheduleId);
	}

	loadStores(page) {
		const terr = this.terrService.getTerritory();

		const pageType = Number(page);
		if (pageType === PagesEnum.SERVICED_STORES) {
			this.stores = this.storesService.findServicedStoresByTerritory(terr);
		} else if (pageType === PagesEnum.NON_SERVICED_STORES) {
			this.stores = this.storesService.findNonServicedStoresByTerritory(terr);
		} else {
			this.stores = this.storesService.findAllStoresByMerchandiserIdAndTerritory(this.associate.id, terr);
		}
	}

	goToMyEventsPage() {
		this.router.navigate(['/my-events']);
	}

	buildSections(): Observable<FormSection[]> {
		const questionType = this.getQuestionType();
		let retrieveSections: Observable<FormSection[]> = this.formService.findQuestionsByType(questionType, this.completedStatus);

		/** Display all active topics */
		if (!this.completedStatus)
			return retrieveSections;

		/**
		 * If the status is completed then the form will contain all topics: active or inactive (if possible)
		 * All active topics will be displayed
		 * And in case we have data for a disabled topic it will be displayed as well
		 */
		return combineLatest([retrieveSections, this.trainingAudit]).pipe(
			map(([sections, trainingAudit]) => {
				return sections.map((section: FormSection) => {
					const filteredQuestions = section.questions.filter((question: FormField<any>) => {
						if (question.isDisabled === 'N') {
							return true;
						}
						return trainingAudit.data.some((data: TrainingAuditData) => data.questionId === question.id && !data.deleted);
					});
					return {
						...section,
						questions: filteredQuestions
					};
				}).filter((section: FormSection) => section.questions.length > 0);
			})
		)
	}

	getQuestionType() {
		/* Audit action */
		if (this.scheduleType === ScheduleType.AUDIT) {
			if (Number(this.page) === this.pagesEnum.SERVICED_STORES)
				return QuestionsEnum.AUDIT

			if (Number(this.page) === this.pagesEnum.NON_SERVICED_STORES)
				return QuestionsEnum.AUDIT_CSV
		}
		/* Training action */
		return QuestionsEnum.TRAINING
	}
}
