import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class NotFoundOrAuthorizedService {
  isNotFoundOrAuthorized = new BehaviorSubject<boolean>(false);

  constructor() { }

  show() {
    this.isNotFoundOrAuthorized.next(true);
  }

  hide() {
    this.isNotFoundOrAuthorized.next(false);
  }

}
