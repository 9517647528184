<app-msg-banner
  *ngIf="showNotification"
  [msgList]="messageList"
  (msgListChange)="messageList = $event"
></app-msg-banner>

<div>
  <mat-card class="mat-elevation-z2">
    <mat-accordion displayMode="flat" multi class="mat-table responsive-table">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%" class="mrg">
        <mat-form-field fxFlex="65" fxFlex.lt-md="100">
          <input
            matInput
            #searchInput
            type="search"
            [(ngModel)]="searchValue"
            (keydown.enter)="applyFilter()"
            (keyup)="applyFilter(true)"
            placeholder="Search"
          />
          <button
            mat-button
            matSuffix
            mat-stroked-button
            (click)="applyFilter()"
            aria-label="search"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>

        <button
          fxFlex="30"
          fxFlex.lt-md="100"
          mat-raised-button
          (click)="openAdd()"
          class="btn btn-add row"
        >
          Add user
        </button>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%" class="mrg">
        <mat-checkbox
          *ngFor="let option of checkboxOptions"
          (change)="valueChange(option.value, $event)"
          [checked]="changeCheck(option.value)"
          >{{ option.name }}</mat-checkbox
        >
      </div>
      <table class="tableLayout" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="employeeName">
          <th mat-header-cell *matHeaderCellDef>Employee Name</th>
          <td mat-cell *matCellDef="let row" data-label="Employee Name">
            {{ row.areaSupervisorName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="employeeId">
          <th mat-header-cell *matHeaderCellDef>Employee ID</th>
          <td mat-cell *matCellDef="let row" data-label="Employee ID">
            {{ row.areaSupervisorUsername }}
          </td>
        </ng-container>
        <ng-container matColumnDef="territories">
          <th mat-header-cell *matHeaderCellDef>Territories</th>
          <td mat-cell *matCellDef="let row" data-label="Territories">
            {{ territoryString(row.territories) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Employee Role</th>
          <td mat-cell *matCellDef="let row" data-label="Employee Role">
            {{ row.role }}
          </td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let row" data-label="Country">
            {{ row.country }}
          </td>
        </ng-container>
        <ng-container matColumnDef="manualEntry">
          <th mat-header-cell *matHeaderCellDef>Manual Entry</th>
          <td mat-cell *matCellDef="let row" data-label="Manual Entry">
            <mat-checkbox [checked]="row.isManual == 1" disabled>
              {{ row.manualEntry }}
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="header">Actions</th>
          <td mat-cell *matCellDef="let row" data-label="Actions" class="cell">
            <button mat-button color="primary" (click)="openEdit(row)">
              <mat-icon class="btn-prim">create</mat-icon>
            </button>
            <button
              mat-button
              color="primary"
              (click)="toggleSupervisorStatus(row)"
            >
              {{ row.isDisabled === "N" ? "Disable" : "Enable" }}
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        #paginator
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 50, 100, 250]"
        [length]="totalRows"
        (page)="pageEvent = getServerData($event)"
        showFirstLastButtons
      ></mat-paginator>
    </mat-accordion>
  </mat-card>
</div>
