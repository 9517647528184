import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsgBannerService } from '../msg-banner/msg-banner.service';
import { COMMA } from '@angular/cdk/keycodes';
import { Email } from '../../models/training-audit/history-email.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { LoginService } from '../../../core/services/login.service';
import { ScheduleType } from '../../models/schedule/schedule.model';
import { CountryGlobalVariables } from '../../constants/country-global-variables';
import { TrainingAuditService } from '../../../core/services/training-audit.service';

@Component({
  selector: 'app-email-recipients',
  templateUrl: './email-recipients.component.html',
  styleUrls: ['./email-recipients.component.scss'],
})
export class EmailRecipientsComponent implements OnInit {
  form: UntypedFormGroup;

  // error list
  messageList = [];
  showNotification = false;

  selectable = true;
  removable = true;
  addOnBlur = true;
  copy = [];
  reg = /;|,|\s|\r?\n/;
  separatorKeysCodes = [COMMA];
  validators = [
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
  ];
  isInvalid = false;

  constructor(
    public dialogRef: MatDialogRef<EmailRecipientsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Email,
    public fb: UntypedFormBuilder,
    private msgBanner: MsgBannerService,
    private http: HttpClient,
    private loginService: LoginService,
    private trainingAuditService: TrainingAuditService
  ) {
    this.form = this.fb.group({ recipients: [[]] });
  }

  ngOnInit(): void {}

  public requestAutocompleteItems = (fullName: string): Observable<any> => {
    return this.trainingAuditService.getEmailsForSendingHistory(fullName);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSendEmail() {
    if (this.form.controls.recipients.value) {
      const recipients = this.form.controls.recipients.value
        ?.map((el) => {
          return el.value;
        })
        .join(',');
      this.dialogRef.close(recipients);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onItemAdded(event: string) {}
  onItemRemoved(event: string) {}
  onItemPaste(event: string) {}

  onTagEdited(event: any, formControl: string) {
    this.form.controls[formControl].value[event.index].value = event.value;
    this.form.controls[formControl].value[event.index].display = event.display;
  }

  isUkUser(): boolean {
    return this.loginService.isUkUser();
  }

  getTitle() {
    if (this.data == null) {
      return 'Send History Email';
    }

    if (this.data.scheduleType === ScheduleType.AUDIT) {
      return 'Send Audit History Email';
    }

    if (this.isUkUser()) {
      return 'Send ' + CountryGlobalVariables.UK.training + ' History Email';
    }

    return 'Send ' + CountryGlobalVariables.US.training + ' History Email';
  }

  // Disables the default matching function of the tag-input-dropdown component
  // so it won't eliminate items from the Observable
  tagMatching(): boolean {
     return true;
  }
}
