import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import { ModuleHomeComponent } from './module-home.component';

import {ModuleHomeRouting} from './module-home.routing';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';


@NgModule({
  declarations: [ModuleHomeComponent, HomePageComponent],
  imports: [
    CommonModule,
    ModuleHomeRouting,
    MatToolbarModule,
    MatButtonModule,
    FlexLayoutModule,
  ]
})
export class ModuleHomeModule { }
