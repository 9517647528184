<div
  fxLayoutAlign="end"
  class="close-btn"
>
  <button
    mat-icon-button
    (click)="dialogRef.close()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<agm-map
  class="map"
  ngClass.lt-md="map-mobile"
  [latitude]="midPoint.lat"
  [longitude]="midPoint.lon"
  [fitBounds]="coords?.length > 1"
  [disableDefaultUI]="false"
  [zoom]="16"
>
  <agm-marker
    *ngFor="let m of coords; let i = index"
    [latitude]="m.lat"
    [longitude]="m.lon"
    [markerDraggable]="false"
    [agmFitBounds]="true"
  >
    <agm-info-window
      fxLayout="column"
    >
      <div><strong>{{ m.label }}</strong></div>
      <div><strong>{{ m.chain }}-{{ m.str }} <span *ngIf="m.shipTo">({{ m.shipTo }})</span></strong></div>
      <div><strong>📍 {{ m.address }}</strong></div>
    </agm-info-window>
  </agm-marker>
</agm-map>
