export class TrainingAudit {
  scheduleId: string;
  scheduleType?: string;
  terr: number;
  retailerId: number;
  merchandiserId: number;
  data: TrainingAuditData[] = [];
  isReadOnly: boolean;
  completedDate?: Date;
}

export class TrainingAuditData {
  id: string;
  questionId: number;
  value: string;
  photos: Photo[] = [];
  deleted: boolean;
}

export class Photo {
  id: string;
  photoName: string;
  deleted: boolean;
  base64data?: string | ArrayBuffer;
}
