import { LoginService } from './../../core/services/login.service';
import { GraphService } from './../../core/graph/graph.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from "@angular/core";
import { MsalService } from '@azure/msal-angular';
import { default as config } from '../../modules/app-config.json';
import { AuthResponse } from '../models/ldap/auth-response.model';
import { Router } from '@angular/router';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { User } from '../models/user';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { RolesEnum } from '../enum/role.enum';
import { Login } from '../models/login.model';

@Injectable({
	providedIn: 'root'
})
export class UserAuthService {
	private retrievedLoginDetailsSubject = new BehaviorSubject<boolean>(false);
	loginComplete$ = this.retrievedLoginDetailsSubject.asObservable();

	constructor(
		private authService: MsalService,
		private graphService: GraphService,
		private cookieService: CookieService,
		private loginSvc: LoginService,
		private router: Router
	) { }

	hasLdapCredentials(): boolean {
		const ldapAcct = this.cookieService.get('authResponse');
		return ldapAcct != null && ldapAcct?.length > 0;
	}

	hasAzureCredentials(): boolean {
		const azureAcct = this.authService.instance.getAllAccounts();
		return azureAcct && azureAcct.length > 0;
	}

	isAuthenticated(): boolean {
		return (this.hasAzureCredentials() || this.hasLdapCredentials());
	}

	isLoginComplete(): boolean {
		return this.retrievedLoginDetailsSubject.value;
	}

	setLoginComplete(authComplete: boolean): void {
		this.retrievedLoginDetailsSubject.next(authComplete);
	}

	getLdapToken(): string {
		const ldapAcct = this.cookieService.get('authResponse');

		if (ldapAcct) {
			let authModel: AuthResponse = JSON.parse(ldapAcct);
			return authModel.accessToken;
		}

		return null;
	}

	setLdapToken(accessToken: string) {
		const ldapAcct = this.cookieService.get('authResponse');

		if (ldapAcct) {
			let authModel: AuthResponse = JSON.parse(ldapAcct);
			authModel.accessToken = accessToken;

			this.cookieService.set('authResponse', JSON.stringify(authModel));
		}
	}

	getLdapRefreshToken(): string {
		const ldapAcct = this.cookieService.get('authResponse');

		if (ldapAcct) {
			let authModel: AuthResponse = JSON.parse(ldapAcct);
			return authModel.refreshToken
		}

		return null;
	}

	getUsername(): string {
		let userName: string = null;

		if (this.hasLdapCredentials()) {
			let ldapAcct = this.cookieService.get('authResponse');
			let authModel: AuthResponse = JSON.parse(ldapAcct);

			userName = authModel.username;
		} else if (this.hasAzureCredentials()) {
			let accounts = this.authService.instance.getAllAccounts();
			userName = accounts[0]?.idTokenClaims['preferred_username'];
		}

		return userName;
	}

	setGroupIDs(): void {
		let groupIDs: string[];

		if (this.hasLdapCredentials()) {
			groupIDs = [config.groups.groupAllUsers]
		} else {
			let accounts = this.authService.instance.getAllAccounts();

			if (accounts[0].idTokenClaims['groups']) {
				groupIDs = <string[]>((<unknown>accounts[0].idTokenClaims['groups']));
			}
		}

		this.graphService.user.groupIDs.push(...groupIDs)
	}

	getUserDetails(): User {
		if (this.graphService.user?.displayName?.length <= 0)
			this.graphService.user.displayName = this.getUsername();

		return this.graphService.user;
	}

	getRoles(): RolesEnum[] {
		let role: string = this.loginSvc?._role;

		if (role)
			return [RolesEnum[role as keyof typeof RolesEnum]];

		return [];
	}

	sendClickEvent(user: User) {
		this.graphService.sendClickEvent(user);
	}

	getClickEvent(): Observable<User> {
		return this.graphService.getClickEvent();
	}

	hasProfilePhoto(): boolean {
		return this.graphService.user.profilePhotoUrl != null;
	}

	getProfilePhoto(): SafeUrl {
		return this.graphService.user.profilePhotoUrl;
	}

	getGroupIDs(): string[] {
		return this.graphService.user.groupIDs;
	}

	loadAzureUser(payload: AuthenticationResult, setActiveAccount?: boolean): void;
	loadAzureUser(payload: AccountInfo): void;

	loadAzureUser(payload: AuthenticationResult | AccountInfo, setActiveAccount?: boolean) {
		if ('account' in payload && setActiveAccount)
			this.authService.instance.setActiveAccount(payload?.account);

		this.graphService.user.displayName = payload.idTokenClaims['preferred_username'];
		this.graphService.loadProfilePhoto();

		if (payload.idTokenClaims['groups']) {
			this.graphService.user.groupIDs = <string[]>(
				(<unknown>payload.idTokenClaims['groups'])
			);
		}
	}

	loadLdapUser(payload: AuthResponse) {
		this.graphService.user.displayName = payload.username;
		this.cookieService.set('authResponse', JSON.stringify(payload));
	}

	removeToken() {
		this.cookieService.delete('authResponse', '/');
		this.cookieService.deleteAll();
	}

	logout(popup?: boolean) {
		this.loginSvc._loggedIn = false;
		if (this.hasLdapCredentials()) {
			this.removeToken();
			this.router.navigate(['ldap-login'])
				.then(() => {
					window.location.reload();
				});
		} else {
			if (popup) {
				this.authService.logoutPopup({
					mainWindowRedirectUri: 'landing'
				});
			} else {
				this.authService.logoutRedirect();
			}
		}
	}
}