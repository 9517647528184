import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {GENERIC_VIEW_CONTENTS, SortDefinition} from '../../constants/generic-view.constants';
import {Subject} from 'rxjs';
import {GenericFilterObject, Page} from '../../models/generic-filter-object.model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { PagesEnum } from '../../models/stores/pages.model';
import { AssociateDetailsPageRedirectService } from '../../../core/services/previous-page-redirect.service';
import {take} from 'rxjs/operators';
import {CountryGlobalVariables} from '../../constants/country-global-variables';
import {LoginService} from '../../../core/services/login.service';

@Component({
  selector: 'app-generic-view',
  templateUrl: './generic-view.component.html',
  styleUrls: ['./generic-view.component.scss']
})
export class GenericViewComponent implements OnInit, AfterViewInit {

  @Input() config: any;
  @Input() filterForm: UntypedFormGroup;
  @Input() sortHeaders: SortDefinition[];
  @Input() parentSubject: Subject<any[]>;
  @Input() length: number;
  @Input() sort: string[];
  @Output() filterEmitter: EventEmitter<GenericFilterObject> = new EventEmitter<GenericFilterObject>();
  @Output() viewDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  sortHeaderMap: Map<string, string>;

  genericFilterObj: GenericFilterObject = new GenericFilterObject();
  data: any[] = [];
  reload = false;

  constructor(private redirectToAssociatesPageService: AssociateDetailsPageRedirectService,
              private loginSvc: LoginService) { }

  ngOnInit(): void {
    this.updateSortHeadersDefinition(this.sort);
    this.generateSortHeaderMap();

    this.parentSubject.subscribe(
      data => {
        this.data = data;
        this.reload = false;
      }
    );
  }

  get CountryGlobalVariables() {
    return CountryGlobalVariables;
  }

  ngAfterViewInit(): void {
    if (this.config.page === this.pageType.ASSOCIATES)
      this.subscribeToAssociateSubjects()
  }

  subscribeToAssociateSubjects() {
    this.redirectToAssociatesPageService.paginationConfig
    .pipe(take(1))
    .subscribe((page:Page) => {
      if (page) {
        this.paginator.pageIndex = page.page;
        this.paginator.pageSize = page.size;
        this.redirectToAssociatesPageService.paginationConfig.next(null);
      }
    });

    this.redirectToAssociatesPageService.searchFormConfig
    .pipe(take(1))
    .subscribe(formValue => {
      if (formValue) {
        this.filterForm.setValue(formValue);
        this.redirectToAssociatesPageService.searchFormConfig.next(null);
      }
    });

    this.redirectToAssociatesPageService.sortHeadersConfig
    .pipe(take(1))
    .subscribe(sort => {
      if (sort) {
        this.updateSortHeadersDefinition(sort);
        this.generateSortHeaderMap();
        this.redirectToAssociatesPageService.sortHeadersConfig.next(null);
      }
    });
  }

  onSearch() {
    this.paginator.pageIndex = 0;
    this.constructGenericFilterObj();

    this.reload = true;
    this.filterEmitter.emit(this.genericFilterObj);
  }

  onPageChange(event: PageEvent) {
    this.genericFilterObj.form = this.filterForm?.value;
    this.genericFilterObj.page = new Page(event.pageIndex, event.pageSize, this.getSortDetails());
    this.reload = true;
    this.filterEmitter.emit(this.genericFilterObj);
  }

  constructGenericFilterObj() {
    this.genericFilterObj.form = this.filterForm?.value;
    this.genericFilterObj.page = new Page(0, this.paginator.pageSize, null);
    this.genericFilterObj.page.sort = this.getSortDetails();
  }

  changeSort() {
    const sort: string[] = this.getSortDetails();
    this.genericFilterObj.page = new Page(0, this.paginator.pageSize, sort);
    this.filterEmitter.emit(this.genericFilterObj);
  }

  getSortDetails(): string[] {
    const sort = [];
    this.sortHeaderMap.forEach((value, key) => {
      if (value !== '-') {
        sort.push(key + ',' + value);
      }
    });

    return sort;
  }

  updateSortHeadersDefinition(sort: string[]) {
    if (sort == null)
      return;

    sort.forEach(s => {
      let res = s.split(',');

      let sortHeader = this.sortHeaders.find(sh => sh.colDef === res[0]);
      if (sortHeader != undefined) {
        this.sortHeaders.forEach(sh => sh.sortType = '-');
        sortHeader.sortType = res[1];
      }
    });
  }

  sortName(id: string) {
    return this.sortHeaders.find(s => s.colDef === id)?.colName;
  }

  switchSortHeader(id: string) {
    const status = this.sortHeaderMap.get(id).localeCompare('asc') === 0 ? 'desc' :
      this.sortHeaderMap.get(id).localeCompare('desc') === 0 ? '-' : 'asc';

    this.sortHeaderMap.set(id, status);
    for (let [key, value] of this.sortHeaderMap) {
      if (key != id) {
        this.sortHeaderMap.set(key, '-');
      }
    }

    this.onSearch();
  }

  private generateSortHeaderMap() {
    this.sortHeaderMap = new Map();

    this.sortHeaders
      .forEach(s => {
        this.sortHeaderMap.set(s.colDef, s.sortType);
      });
  }

  get pageType () {
    return PagesEnum;
  }

  goToDetailView(dat: any) {
    this.viewDetailsEmitter.emit(dat);
  }

  onClearSort() {
    switch (this.config.page) {
      case this.pageType.ASSOCIATES:
        this.sortHeaders = JSON.parse(JSON.stringify(GENERIC_VIEW_CONTENTS.ASSOCIATES.SORT));
        break;
      case this.pageType.SERVICED_STORES:
        this.sortHeaders = JSON.parse(JSON.stringify(GENERIC_VIEW_CONTENTS.SERVICED_STORES.SORT));
        break;
      case this.pageType.NON_SERVICED_STORES:
        this.sortHeaders = JSON.parse(JSON.stringify(GENERIC_VIEW_CONTENTS.NON_SERVICED_STORES.SORT));
        break;
    }

    if (this.loginSvc.isUkUser()) {
      const idx = this.sortHeaders.findIndex(s => s.colDef === 'lastTrain');
      if (idx !== -1) {
        this.sortHeaders[idx].colName = CountryGlobalVariables.UK.trainingDate;
      }
    }

    this.generateSortHeaderMap();
    this.onSearch();
  }

  isUkUser(): boolean {
    return this.loginSvc.isUkUser();
  }
}
