import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormSection } from '../../shared/models/training-audit/form-section.model';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QuestionTypeEnum } from '../../shared/enum/question-type.enum';
import { LoginService } from './login.service';

@Injectable({
	providedIn: 'root'
})
export class FormControlService {

	constructor(private http: HttpClient,
		private loginService: LoginService) { }

	toFormGroup(sections: FormSection[]): UntypedFormGroup {
		const group: any = {};
		sections.forEach(inputs => {
			inputs.questions.forEach(input => {
				const validator: ValidatorFn[] = input.isRequired === 1 ? [
					input.questionElementType === QuestionTypeEnum.CHECKBOX ? Validators.requiredTrue : Validators.required
				] : [];
				group[input.id] = validator.length > 0 ? new UntypedFormControl(input.value || null, validator)
					: new UntypedFormControl(input.value || null);
			});
		});

		return new UntypedFormGroup(group);
	}

	public findQuestionsByType(type: string, completedSchedule?: boolean): Observable<FormSection[]> {
		const country = this.loginService._country;
		let params: HttpParams = new HttpParams();

		if (completedSchedule) {
			params = params.append('completedSchedule', completedSchedule)
		}

		return this.http.get<FormSection[]>(`${environment.apiResourceUri}/questions/country/${country}/${type}`, {params: params});
	}
}
