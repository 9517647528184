import { UserAuthService } from './../../services/user-auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotFoundOrAuthorizedService } from '../../../core/services/not-found-or-authorized.service';

@Component({
	selector: 'app-not-authorized',
	templateUrl: './not-authorized.component.html',
	styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

	message = 'Oops, it seems that you are not authorized to see this page.';

	constructor(private router: Router,
		private userAuthService: UserAuthService,
		private notFoundOrAuthorizedService: NotFoundOrAuthorizedService) {
		this.notFoundOrAuthorizedService.show();
	}

	ngOnInit(): void {
		if (history.state.data) {
			this.message = history.state.data;
		}
	}

	public goHome(): void {
		if (this.userAuthService.isAuthenticated()) {
			let redirectPath = this.userAuthService.hasLdapCredentials() ? '/associates' : '/';
			this.notFoundOrAuthorizedService.hide();
			
			this.router.navigate([redirectPath]);
		} else {
			this.goToLoginPage();
		}
	}

	goToLoginPage() {
		let loginPath = history.state?.returnUrl ? history.state?.returnUrl : 'landing';

		localStorage.clear();
		this.router.navigate([loginPath]);
	}
}
 