<div>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    *ngIf="storeDetails"
    class="padding-10 mat-elevation-z0"
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="50px"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="space-around center"
      fxLayoutGap.lt-md="0"
    >
      <mat-card class="mat-elevation-z0" fxLayout="column" fxLayoutGap="20px">
        <div class="header" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon class="desc-icon grey-icon">store</mat-icon>
          <div class="store" fxLayout="column">
            <div>
              {{ storeDetails?.name }} - {{ storeDetails?.chain }}-{{
                storeDetails?.store
              }}
              <span *ngIf="storeDetails.shipTo">({{ storeDetails.shipTo }})</span>
            </div>
          </div>
        </div>

        <div
          class="header"
          fxLayoutAlign="start start"
          fxLayoutGap="10px"
          fxLayout="row"
          fxLayout.lt-md="column"
        >
          <div fxLayout="row">
            <mat-icon class="desc-icon grey-icon">location_on</mat-icon>
            <div class="store">
              <div>&nbsp;&nbsp;{{ storeDetails?.address }}</div>
            </div>
          </div>
          <div fxLayout="row" *ngIf="storeDetails.zone">
            <mat-icon *ngIf="!isUkUser()" class="zones-icon grey-icon">layers</mat-icon>
            <div class="store">&nbsp;Zone {{ storeDetails.zone }}</div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%" class="mrg">
          <mat-checkbox
            *ngFor="let option of checkboxOptions"
            (change)="valueChange(option.value, $event)"
            [checked]="changeCheck(option.value)"
            >{{ option.name }}</mat-checkbox
          >
        </div>
      </mat-card>
    </div>

  </mat-card>

  <app-dynamic-history
    *ngIf="storeDetails"
    [scheduleType]="scheduleType"
    [storeDetails]="storeDetails"
    [checkboxValue]="checkboxValue"
    (errorEmitter)="displayError($event)"
    [page]="page"
  ></app-dynamic-history>
</div>
