import { RoleGuard } from './../../core/guards/role.guard';
import { RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from './home-page/home-page.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {LoginGuard} from '../../core/guards/login.guard';
import {FeatureFlagGuard} from '../../core/guards/feature-flag.guard';
import { MsalLdapGuard } from './../../core/guards/msal-ldap.guard';

const moduleRoutes: Routes = [{
    path: 'home',
    component: HomePageComponent,
    canActivate: [ AuthGuard, MsalLdapGuard, LoginGuard , FeatureFlagGuard, RoleGuard],
    data: {
      expectedGroup: []
    }
}
];

export const ModuleHomeRouting = RouterModule.forChild(moduleRoutes);
