import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Page } from '../../shared/models/generic-filter-object.model';
import { Observable } from 'rxjs';
import { IPagedResponse } from '../../shared/models/IPageResponse';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AreaSupervisor } from '../../shared/models/admin/area-supervisor.model';
import { AzureUsersPage } from '../../shared/models/admin/azure-user.model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class AreaSupervisorService {
  constructor(private http: HttpClient,
              private loginService: LoginService) {}

  public getSupervisors(
    page: Page,
    searchBy: string,
    disabled: string
  ): Observable<IPagedResponse<AreaSupervisor>> {
    const country = this.loginService._country;
    let params = null;

    params = new HttpParams();
    if (!page || typeof page === 'object') {
      params = params.append('size', page.size.toString());
      params = params.append('page', page.page.toString());

      if (page.sort && page.sort.length > 0) {
        page.sort.forEach((s) => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    if (searchBy) {
      params = params.append('name', searchBy);
    }

    if (disabled) {
      params = params.append('disabled', disabled);
    }

    return this.http
      .get<IPagedResponse<AreaSupervisor>>(
        `${environment.apiResourceUri}/supervisors/country/${country}`,
        { params: params }
      )
      .pipe(
        map((res: IPagedResponse<AreaSupervisor>) => {
          return res;
        })
      );
  }

  public addSupervisor(supervisor: AreaSupervisor): Observable<AreaSupervisor> {
    return this.http.post<AreaSupervisor>(`${environment.apiResourceUri}/supervisors`, supervisor)
  }

  public updateSupervisor(
    supervisor: AreaSupervisor
  ): Observable<AreaSupervisor> {
    return this.http.put<AreaSupervisor>(
      `${environment.apiResourceUri}/supervisors/${supervisor.id}`,
      supervisor
    );
  }

  searchAzureUsers(searchField: string): Observable<AzureUsersPage> {
    const graph_endpoint = `https://graph.microsoft.com/v1.0/users`;
    let headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = new HttpParams();

    headers = headers.append('ConsistencyLevel', 'eventual');
    params = params.append('$count', 'true');
    params = params.append(
      '$search',
      '"displayName:' +
      searchField +
      '" OR "mail:' +
      searchField +
      '" OR "employeeId:' +
      searchField +
      '"'
    );
    params = params.append('$orderBy', 'displayName');
    params = params.append(
      '$select',
      'id,displayName,mail,onPremisesSamAccountName,givenName,surname'
    );
    params = params.append('$top', '50');

    return this.http.get<AzureUsersPage>(graph_endpoint, { headers, params });
  }

  public findAllTerritories(): Observable<Number[]> {
    const country = this.loginService._country;
    return this.http.get<Number[]>(`${environment.apiResourceUri}/supervisors/country/${country}/territories`);
  }
}
