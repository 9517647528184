<mat-dialog-content>
	<app-msg-banner 
		*ngIf="showNotification" 
		[msgList]="messageList" 
		(msgListChange)="messageList = $event">
	</app-msg-banner>

	<div
		fxLayout="column">
		<div
			mat-dialog-title
			fxLayout="row"
			fxLayoutAlign="space-between center">

			<div fxLayoutAlign="start">Change Territory</div>
			<div fxLayoutAlign="end">
				<button
					mat-icon-button
					(click)="closeDialog()">
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</div>

		<form [formGroup]="form"
			fxLayout="row"
			fxLayoutAlign="center center">
			<mat-form-field
				appearance="standard"
				fxFlex="85%"
				fxFlex.lt-md="100%">
				<mat-select formControlName="terr">
					<mat-option *ngFor="let terr of territories" [value]="terr">
					{{terr}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>

		<div
			fxLayout="row"
			fxLayout.lt-md="column"
			fxLayoutAlign="center"
			fxLayoutGap="2%">

			<button
				fxFlex="100px"
				fxFlex.lt-md="100%"
				mat-raised-button
				class="btn btn-search"
				color="primary"
				type="button"
				[disabled]="hasTerritoryChanged()"
				(click)="onSave()"
			> Save
			</button>
			<button
				fxFlex="100px"
				fxFlex.lt-md="100%"
				mat-raised-button
				class="btn btn-action"
				type="button"
				(click)="onCancel()"
			> Cancel
			</button>

		</div>
	</div>
</mat-dialog-content>
