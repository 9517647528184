import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.scss']
})
export class ViewMapComponent implements OnInit {

  coords: any[] = [];
  midPoint: any;

  constructor(public dialogRef: MatDialogRef<ViewMapComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.coords = this.data;
    this.midPoint = this.calculateMidPoint();
  }

  calculateMidPoint() {
    let x = 0;
    let y = 0;
    let z = 0;

    this.coords.forEach(geoCoordinate => {
      const latitude = geoCoordinate.lat * Math.PI / 180;
      const longitude = geoCoordinate.lon * Math.PI / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    });

    const total = this.coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      lat: centralLatitude * 180 / Math.PI,
      lon: centralLongitude * 180 / Math.PI
    };
  }
}
