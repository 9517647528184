import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  titleSubject = new BehaviorSubject<string>(null);

  constructor() { }

  updateTitle(title: string) {
    this.titleSubject.next(title);
  }
}
