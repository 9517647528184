<div class="wrapper" fxLayout="column">
    <app-msg-banner
        *ngIf="showNotification" [msgList]="messageList"
        (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <mat-card
        class="mat-elevation-z2"
        fxLayout="column"
        fxLayoutGap="30px"
        fxLayoutGap.lt-md="10px">
        <div
            fxLayout="row"
            fxLayout.lt-gt="column"
            fxLayout.lt-md="column"
            fxLayoutGap="50px"
            fxLayoutGap.lt-md="20px"
            [formGroup]="formData">

            <!-- appointment name and notes -->
            <div
                fxFlex="100%"
                fxLayout="column"
                fxLayout.lt-md="column"
                fxLayoutGap="10px"
                fxLayoutGap.lt-md="10px">

                <div
                    fxLayout="column">
                    <h3> Appointment name * </h3>
                    <mat-form-field
                        appearance="standard"
                        class="remove-text-field-margin">
                        <textarea
                            matInput
                            placeholder="Enter appointment name..."
                            formControlName="appointmentName"></textarea>
                    </mat-form-field>
                </div>

                <div
                    fxLayout="column">
                    <h3> Notes </h3>
                    <mat-form-field
                        appearance="standard"
                        class="remove-text-field-margin">
                        <textarea
                            matInput
                            placeholder="Enter notes..."
                            formControlName="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <!-- store, date, time -->
            <div class="mobile-divider"><mat-divider></mat-divider></div>
            <div
                fxFlex="100%"
                fxLayout="column"
                fxLayout.lt-md="column"
                fxLayoutAlign="start"
                fxLayoutGap="60px"
                fxLayoutGap.lt-md="10px">

                <!-- store -->
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                    class="store-column">
                    <mat-icon class="grey-icon margin-10">store</mat-icon>
                    <mat-form-field
                        class="white-background remove-default-styling"
                        fxFlex="100%;"
                        fxFlex.lt-md="100%">
                        <mat-label>Select Store</mat-label>
                        <mat-select formControlName="store" required>
                            <mat-option>
                                <ngx-mat-select-search
                                  [formControl]="storeFilterCtrl"
                                  placeholderLabel="Find store ..."
                                  noEntriesFoundLabel="No matching store found"
                                >
                                  <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                                </ngx-mat-select-search>
                              </mat-option>
                            <mat-option
                                *ngFor="let store of filteredStores | async"
                                [value]="store.id"
                                class="multiline-mat-option">
                                <div> {{store.name}} {{ store.chain }} - {{store.store}} <span *ngIf="store.shipTo">({{store.shipTo}})</span> </div>
                                <div> {{ store.address}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- date and time picker -->
                <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px">
                    <mat-icon class="grey-icon margin-10">schedule</mat-icon>
                    <div
                        fxFlex="100%"
                        fxLayout="row"
                        fxLayout.lt-md="column"
                        fxLayoutGap="10px"
                        fxLayoutGap.lt-md="10px">
                        <mat-form-field
                            class="white-background remove-default-styling"
                            fxFlex="50%"
                            fxFlex.lt-md="100%">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="date" [min]="min">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field
                            class="white-background remove-default-styling"
                            fxFlex="50%"
                            fxFlex.lt-md="100%">
                            <mat-label>Select Time</mat-label>
                            <mat-select
                                formControlName="startTime"
                                [disabled]="!formData.controls.date.value">
                                <mat-option>--</mat-option>
                                <mat-option *ngFor="let slot of timeSlots" [value]="slot.value" class="multiline-mat-option">
                                    {{ slot.text }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- action buttons -->
        <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxFlex="23%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="center"
            fxLayoutAlign.lt-md="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-md="5px"
        >
            <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                mat-raised-button
                class="btn btn-clear"
                color="primary"
                type="button"
                (click)="onCancel()"
            >Cancel
            </button>
            <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                mat-raised-button
                class="btn btn-search"
                color="primary"
                (click)="onSave()"
                [disabled]="formData.invalid"
            >Save
            </button>
        </div>
    </mat-card>
</div>
