import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TokenProvider} from '../../shared/models/token-provider.model';
import {Observable} from 'rxjs';
import { UnreadMessages } from '../../shared/models/getstream/unread-messages.model';
import {Channel} from '../../shared/models/getstream/channel.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  authenticate(): Observable<TokenProvider> {
    return this.http.get<TokenProvider>(`${environment.apiResourceUri}/chat/authenticate`);
  }

  countUnreadMessages() {
    return this.http.get<UnreadMessages>(`${environment.apiResourceUri}/chat/count-unread`);
  }

  getOrCreateMerchandiserChannel(merchandiserId: number) {
    return this.http.get<Channel>(`${environment.apiResourceUri}/chat/create-channel/merchandiser/${merchandiserId}`);
  }
}
