<mat-dialog-content>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="title">Upload</div>
    <button
      mat-icon-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <img src="assets/img/ic-file.svg" width="45px" alt="file">
      <div class="info">

        <h4 class="name" *ngIf="file?.name.length > 35">
          {{ file?.name.substring(0, 32) }}
          <button
            mat-button
            class="more-button"
            matTooltip="{{ file?.name }}"
            matTooltipClass="tooltip-description"
          >...
          </button>
        </h4>
        <h4 class="name" *ngIf="file?.name.length <= 35">
          {{ file?.name }}
        </h4>

        <p class="size">
          {{ formatBytes(file?.file?.size) }}
        </p>
        <app-file-progress [progress]="file?.progress"></app-file-progress>
      </div>

      <mat-spinner
        *ngIf="loadingFile[i]"
        class="delete"
        style="display: table;"
        strokeWidth="2"
        [diameter]="20"
      ></mat-spinner>

      <mat-icon
        *ngIf="!loadingFile[i] && file?.progress === 100 && file?.success === true"
        class="delete"
        style="color: green"
      >check</mat-icon>

      <mat-icon
        *ngIf="!loadingFile[i] && file?.success === false"
        class="delete"
        style="color: red"
        [matTooltip]="failMessage[i]"
      >close</mat-icon>

      <mat-icon
        *ngIf="!loadingFile[i] && file?.success === false"
        class="delete"
        (click)="upload(i)"
      >refresh</mat-icon>
    </div>
  </div>
</mat-dialog-content>
