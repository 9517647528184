<app-dynamic-form
  [stores]="stores"
  [store]="store"
  [associate]="associate"
  [sections]="sections"
  [page]="stateData?.page"
  [scheduleId]="stateData?.scheduleId"
  [trainingAudit]="trainingAudit"
  [isRedirectFromAssociateDetails]="isRedirectFromAssociateDetails"
></app-dynamic-form>
