import { RescheduleEventsModel } from './../../shared/models/schedule/reschedule-events.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { ScheduleDetails } from '../../shared/models/schedule/schedule-details.model';
import { ScheduleModel, ScheduleType } from '../../shared/models/schedule/schedule.model';
import { TerritoryService } from './territory-management.service';
import { ScheduleResponse } from '../../shared/models/schedule/schedule-response.model';
import { Page } from '../../shared/models/generic-filter-object.model';
import { IPagedResponse } from '../../shared/models/IPageResponse';
import { TrainingAuditHistory } from '../../shared/models/training-audit/training-audit-history.model';
import { map } from 'rxjs/operators';
import { RescheduleModel } from '../../shared/models/schedule/reschedule.model';
import { UtilsService } from './utils.service';
import { Email } from '../../shared/models/training-audit/history-email.model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient,
              private territoryService: TerritoryService,
              private loginService: LoginService) { }

  public getSchedule(scheduleId: string): Observable<ScheduleDetails> {
    return this.http.get<ScheduleDetails>(
      `${environment.apiResourceUri}/schedules/schedule/${scheduleId}`)
    .pipe(map((response: ScheduleDetails) => {
        if (response.isAllDayEvent != true && response.scheduledDate) {
          response.scheduledDate = UtilsService.dateToServerDate(response.scheduledDate);
        }
        return response;
    }));
  }

  public createSchedule(schedule: ScheduleModel): Observable<ScheduleResponse> {
    const terr = this.territoryService.getTerritory();
    return this.http.post<ScheduleResponse>(
      `${environment.apiResourceUri}/schedules/territory/${terr}`,
        schedule
    );
  }

  public updateSchedule(schedule: ScheduleModel): Observable<ScheduleResponse> {
    return this.http.put<ScheduleResponse>(
      `${environment.apiResourceUri}/schedules/${schedule.id}`,
      schedule
    );
  }

  public rescheduleEvent(scheduleId: string, details: RescheduleModel) {
    return this.http.put<ScheduleResponse>(
      `${environment.apiResourceUri}/schedules/${scheduleId}/reschedule`,
      details
    );
  }

  public rescheduleEventsInBulk(rescheduleEvents: RescheduleEventsModel): Observable<ScheduleResponse[]> {
    return this.http.put<ScheduleResponse[]>(
      `${environment.apiResourceUri}/schedules/bulk/reschedule`,
      rescheduleEvents
    );
  }

  public checkScheduledDateTimeConflict(scheduleId: string, date: Date): Observable<number> {
    let params = null;
    params = new HttpParams();

    var dateStr = date.toISOString();
    params = params.append('date', dateStr);
    if (scheduleId)
      params = params.append('scheduleId', scheduleId);

    return this.http.get<number>(
      `${environment.apiResourceUri}/schedules/validate`,
      {params: params});
  }

  public submit(scheduleId: string): Observable<ScheduleResponse> {
    return this.http.put<ScheduleResponse>(
      `${environment.apiResourceUri}/schedules/submit/schedule/${scheduleId}`,
      null
    );
  }

  public findPurposes(scheduleId: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiResourceUri}/schedules/schedule/${scheduleId}/purposes`);
  }

  public retrieveTrainingAuditHistory(
    page: Page,
    scheduleType: string,
    id: number,
    territory: number,
    checkboxValue: number
  ): Observable<IPagedResponse<TrainingAuditHistory>> {
    const country = this.loginService._country;
    let params = null;

    params = new HttpParams();
    if (!page || typeof page === 'object') {
      params = params.append('size', page.size.toString());
      params = params.append('page', page.page.toString());

      if (page.sort && page.sort.length > 0) {
        page.sort.forEach((s) => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    const userType = scheduleType === ScheduleType.AUDIT ? 'retailer' : 'merchandiser';
    return this.http
      .get<IPagedResponse<TrainingAuditHistory>>(
        `${environment.apiResourceUri}/schedules/country/${country}/territory/${territory}/schedule-type/${scheduleType}/${userType}/${id}/completed-status/${checkboxValue}/history`,
        { params: params }
      )
      .pipe(
        map((res: IPagedResponse<TrainingAuditHistory>) => {
          if (res?.size > 0) {
            res?.content?.forEach(history => {
              if (history.lastUpdateDate) {
                history.lastUpdateDate = UtilsService.dateToServerDate(history.lastUpdateDate);
              }
            });
          }
          return res;
        })
      );
  }

  public sendHistoryEmail(historyEmail: Email) {
    historyEmail.country = this.loginService._country;
    return this.http.post<any>(
      `${environment.apiResourceUri}/schedules/schedule-type/${historyEmail.scheduleType}/email`,
      historyEmail
    );
  }
}
