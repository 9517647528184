<div>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    *ngIf="associate"
    class="padding-10 mat-elevation-z0"
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxLayoutAlign="start">
        <mat-icon class="grey-icon">account_circle</mat-icon>
      </div>

      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayoutAlign="start" class="title">{{ associate.fullName }}</div>
        <div fxLayoutAlign="start">{{ associate.userName }}</div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%" class="mrg">
      <mat-checkbox
        *ngFor="let option of checkboxOptions"
        (change)="valueChange(option.value, $event)"
        [checked]="changeCheck(option.value)"
        >{{ option.name }}</mat-checkbox
      >
    </div>
  </mat-card>

  <app-dynamic-history
    *ngIf="associate"
    [associate]="associate"
    [scheduleType]="scheduleType"
    [merchandiserId]="merchandiserId"
    [checkboxValue]="checkboxValue"
    (errorEmitter)="displayError($event)"
    [page]="page"
  ></app-dynamic-history>
</div>
