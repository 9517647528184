import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TrainingAuditService } from '../../../core/services/training-audit.service';
import { ScheduleService } from '../../../core/services/schedule.service';
import { Associate } from '../../models/associates/associate.model';
import { Dialog } from '../../models/dialog';
import { Page } from '../../models/generic-filter-object.model';
import { ScheduleModel, ScheduleType } from '../../models/schedule/schedule.model';
import { StoreDetails } from '../../models/stores/store-details.model';
import { ScheduleResponse } from '../../models/schedule/schedule-response.model';
import { TrainingAuditHistory } from '../../models/training-audit/training-audit-history.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MsgBannerService } from '../msg-banner/msg-banner.service';
import {LoginService} from '../../../core/services/login.service';
import { CountryGlobalVariables } from '../../constants/country-global-variables';

@Component({
  selector: 'app-dynamic-history',
  templateUrl: './dynamic-history.component.html',
  styleUrls: [
    './dynamic-history.component.scss',
    '../../../modules/pages/associate-details/associate-details.component.scss',
  ],
})
export class DynamicHistoryComponent implements OnInit {
  @Input() scheduleType: string;
  @Input() associate: Associate;
  @Input() storeDetails: StoreDetails;
  @Input() merchandiserId: number;
  @Input() page: number;
  @Input() checkboxValue: number;
  @Output() errorEmitter: EventEmitter<string> = new EventEmitter<string>();

  trainingScheduleType = ScheduleType.TRAINING;

  data: TrainingAuditHistory[] = [];
  length = 0;

  reload = false;
  showNotification = false;
  messageList = [];

  pageEvent;

  constructor(
    private scheduleService: ScheduleService,
    private trainingAuditService: TrainingAuditService,
    private msgBanner: MsgBannerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private loginSvc: LoginService
  ) {}

  ngOnInit(): void {
    this.retrieveTrainingAuditHistory();
  }

  ngOnChanges(): void {
    this.retrieveTrainingAuditHistory();
  }

  isUkUser(): boolean {
    return this.loginSvc.isUkUser();
  }

  retrieveTrainingAuditHistory() {
    this.showNotification = false;
    let pageable: Page =
      this.pageEvent?.pageIndex != undefined &&
      this.pageEvent?.pageSize != undefined
        ? new Page(this.pageEvent.pageIndex, this.pageEvent.pageSize, [
            'updatedDate,desc',
          ])
        : new Page(0, 10, ['updatedDate,desc']);

    const id = this.storeDetails?.id ? this.storeDetails?.id : this.merchandiserId;
    const territory = Number(localStorage.getItem('territory'));

    this.scheduleService
      .retrieveTrainingAuditHistory(pageable, this.scheduleType, id, territory, this.checkboxValue)
      .subscribe(
        (response) => {
          this.errorEmitter.emit(null);
          if (response?.totalElements > 0) {
            this.data = response.content;
            this.length = response.totalElements;
          }
        },
        (error) => {
          if (error.status === 404) {
            this.data = [];
            this.length = 0;
            this.errorEmitter.emit(error.error.message);
          } else {
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            this.showNotification = true;
          }
        }
      );
  }

  onPageChange(event: PageEvent) {
    this.reload = true;
    this.pageEvent = event;
    this.retrieveTrainingAuditHistory();
  }

  onDelete(scheduleId: string) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: new Dialog(
          'Are you sure you want to delete this ' +
            this.scheduleType.toLowerCase() +
            '?',
          true,
          false,
          true
        ),
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.trainingAuditService.deleteTrainingAudit(scheduleId).subscribe(
            (response: ScheduleResponse) => {
              this.displayConfirmationMessage(response.outlookEventSuccess);
            },
            (error) => {
              if (error.status === 404) {
                this.msgBanner.addMsgError(
                  this.messageList,
                  error.error.message
                );
              } else {
                this.msgBanner.addMsgError(
                  this.messageList,
                  'An error has occurred. Please contact your administrator!'
                );
              }
              this.showNotification = true;
            }
          );
        }
      });
  }

  private displaySnackBar(msg: string) {
    this.snackBar.open(msg, 'Close', {
      duration: 10 * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  goToTrainingAudit(schedule: ScheduleModel) {
    const data = {
      scheduleId: schedule.id,
      scheduleType: this.scheduleType,
      page: this.page,
      associate: this.associate,
      storeDetails: this.storeDetails ? this.storeDetails : schedule?.retailer,
	    completedStatus: schedule?.completedStatus == 1
    };
    this.router.navigate(['audit-training-history'], { state: { data } });
  }

  getDeleteMessage() {
      if (this.scheduleType === ScheduleType.AUDIT)
        return ScheduleType.AUDIT + ' successfully deleted';

      if (this.isUkUser())
        return CountryGlobalVariables.UK.training + ' successfully deleted';

      return CountryGlobalVariables.US.training + ' successfully deleted';
  }

  displayConfirmationMessage(isOutlookSuccess: boolean) {
    let message = this.getDeleteMessage();
    message += isOutlookSuccess ? "!" : " but the Outlook Event failed to be deleted!";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: message,
        confirmationRequired: false
      },
    });


    dialogRef.afterClosed().subscribe((dialogResult) => {
        this.retrieveTrainingAuditHistory();
        return;
    });
  }

  displayCompletedByName(history: TrainingAuditHistory) {
    let emailValidation = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    let validationRes = history?.completedBy?.match(emailValidation);

    if (validationRes?.length > 0){
      let completedBy = history.completedBy.toUpperCase().split('@')[0].split('.');
      return completedBy[0] + ' ' + completedBy[1];
    }
    return '';
  }
}
