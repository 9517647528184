import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {PagesComponent} from './pages.component';
import {PagesRouting} from './pages.routing';
import {SharedModule} from '../../shared/shared.module';
import { NonServicedStoresComponent } from './non-serviced-stores/non-serviced-stores.component';
import { AssociatesComponent } from './associates/associates.component';
import { AssociateDetailsComponent } from './associate-details/associate-details.component';
import { StoreDetailsComponent } from './store-details/store-details.component';
import {AgmCoreModule} from '@agm/core';
import {CoreModule} from '../../core/core.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ServicedStoresComponent } from './serviced-stores/serviced-stores.component';
import { ScheduleAuditComponent } from './schedule-audit/schedule-audit.component';
import { ScheduleTrainingComponent } from './schedule-training/schedule-training.component';
import { TrainingComponent } from './training/training.component';
import { AuditComponent } from './audit/audit.component';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { TrainingHistoryComponent } from './training-history/training-history.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { ScheduleAppointmentComponent } from './schedule-appointment/schedule-appointment.component';
import { MyEventLabelComponent } from './my-event-label/my-event-label.component';
import { AuditTrainingHistoryComponent } from './audit-training-history/audit-training-history.component';
import { AdminComponent } from './admin/admin.component';
import { UserDialogComponent } from './admin/user-dialog/user-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ZoneUpdateComponent } from './zone-update/zone-update.component';
import {MomentDateModule} from '@angular/material-moment-adapter';
import { MetricsDialogComponent } from './associate-details/metrics-dialog/metrics-dialog.component';

@NgModule({
  declarations: [
    PagesComponent,
    ServicedStoresComponent,
    NonServicedStoresComponent,
    AssociatesComponent,
    AssociateDetailsComponent,
    StoreDetailsComponent,
    ScheduleAuditComponent,
    ScheduleTrainingComponent,
    TrainingComponent,
    AuditComponent,
    StoreDetailsComponent,
    AuditTrainingHistoryComponent,
    AuditHistoryComponent,
    TrainingHistoryComponent,
    MyEventsComponent,
    AdminComponent,
    UserDialogComponent,
    ScheduleAppointmentComponent,
    MyEventLabelComponent,
    ZoneUpdateComponent,
    MetricsDialogComponent
  ],
  imports: [
    CommonModule,
    PagesRouting,
    SharedModule,
    AgmCoreModule,
    CoreModule,
    NgxMatSelectSearchModule,
    ClipboardModule,
    MomentDateModule
  ],
  providers: [
    DatePipe
  ]
})
export class PagesModule { }
