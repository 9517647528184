<mat-dialog-content>
	<div fxFlex fxLayout="column" fxLayoutGap="25px" fxLayoutGap.lt-md="15px" [formGroup]="formData">

		<!-- error banner -->
		<app-msg-banner *ngIf="showNotification" [msgList]="messageList"
			(msgListChange)="messageList = $event"></app-msg-banner>

		<!-- title and close button -->
		<div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
			<div class="title"> {{ title }} </div>
			<button mat-icon-button (click)="onClose()">
				<mat-icon>close</mat-icon>
			</button>
		</div>

		<!-- date and time picker -->
		<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="10px">
			<mat-form-field fxFlex="1 0 0">
				<mat-label>Choose a date</mat-label>
				<input matInput [matDatepicker]="picker" formControlName="date" [min]="min">
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div>

		<!-- save and cancel buttons -->
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex="23%" fxFlex.lt-md="100%" fxLayoutAlign="center"
			fxLayoutAlign.lt-md="start" fxLayoutGap="20px" fxLayoutGap.lt-md="5px">
			<button fxFlex="100px" fxFlex.lt-md="100%" mat-raised-button class="btn btn-clear" color="primary"
				type="button" (click)="onClose()">Cancel
			</button>
			<button fxFlex="100px" fxFlex.lt-md="100%" mat-raised-button class="btn btn-save" color="primary"
				(click)="onSave()" [disabled]="formData.invalid">Save
			</button>
		</div>
	</div>
</mat-dialog-content>