import {Component, OnInit} from '@angular/core';
import {StoreDetails} from '../../../shared/models/stores/store-details.model';
import {TerritoryService} from '../../../core/services/territory-management.service';
import {StoresService} from '../../../core/services/stores.service';
import {Associate} from '../../../shared/models/associates/associate.model';
import {Router} from '@angular/router';
import {QuestionsEnum} from '../../../shared/enum/questions.enum';
import {Observable} from 'rxjs';
import {FormControlService} from '../../../core/services/form-control.service';
import {FormSection} from '../../../shared/models/training-audit/form-section.model';
import {TrainingAudit} from '../../../shared/models/training-audit/training-audit.model';
import {TrainingAuditService} from '../../../core/services/training-audit.service';
import {LoginService} from '../../../core/services/login.service';
import {TitleService} from '../../../core/services/title.service';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  stores: Observable<StoreDetails[]>;
  store: StoreDetails;
  associate: Associate;
  trainingAudit: Observable<TrainingAudit>;

  sections: Observable<FormSection[]>;
  scheduleId = null;

  isRedirectFromAssociateDetails: boolean;

  constructor(private terrService: TerritoryService,
              private storesService: StoresService,
              private trainingAuditService: TrainingAuditService,
              private formService: FormControlService,
              private loginSvc: LoginService,
              private titleSvc: TitleService,
              private router: Router) { }

  ngOnInit(): void {
    if (this.loginSvc.isUkUser()) {
      this.titleSvc.updateTitle(CountryGlobalVariables.UK.trainingStart);
    } else {
      this.titleSvc.updateTitle(CountryGlobalVariables.US.trainingStart);
    }

    if (!history.state.data) {
      this.goToMyEventsPage();
      return;
    }

    this.sections = this.formService.findQuestionsByType(QuestionsEnum.TRAINING);

    this.isRedirectFromAssociateDetails = history.state.data.isRedirectFromAssociateDetails;
    
	this.associate = history.state.data.associate;

	if (history.state.data?.store)
		this.store = history.state.data?.store;

    this.loadStores();
    if (this.stateData.scheduleId) {
      this.findTrainingAuditById(this.stateData.scheduleId);
    } else {
      this.checkStoreList();
    }
  }

  findTrainingAuditById(scheduleId: string) {
    this.trainingAudit = this.trainingAuditService.findTrainingAuditsByScheduleId(scheduleId);
    this.setSelectedStore();
  }

  get stateData() {
    return history.state.data;
  }

  loadStores() {
    const terr = this.terrService.getTerritory();
    this.stores = this.storesService.findAllStoresByMerchandiserIdAndTerritory(this.associate.id, terr);
  }

  goToMyEventsPage() {
    this.router.navigate(['/my-events']);
  }

  setSelectedStore() {
    this.trainingAudit.subscribe(
      (trainingResponse: TrainingAudit) => {
        this.stores.subscribe(
          (storeResponse: StoreDetails[]) => {
            this.store = storeResponse.find(s => s.id === trainingResponse.retailerId);
          }
        );
      }
    );
  }

  checkStoreList() {
    this.stores.subscribe(
      (storeResponse: StoreDetails[]) => {
        if (storeResponse.length === 1 && !this.store) {
          this.store = storeResponse[0];
        }
      }
    );
  }
}
