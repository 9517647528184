import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  restrictedFeatures = null;
  features = null;

  constructor(private http: HttpClient) { }

  getFeatures(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiResourceUri}/features`);
  }

  getRestrictedFeatures(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiResourceUri}/features/restricted`);
  }

  hasAccess(feature: string) {
    if (feature && this.restrictedFeatures?.includes(feature)) {
      if (!this.features?.includes(feature)) {
        return false;
      }
    }

    return true;
  }

}
