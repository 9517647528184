import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AssociateService} from '../../../core/services/associates.service';
import {MerchandiserGetStream} from '../../../shared/models/getstream/merchandiser-get-stream.model';
import {ChannelService, ChatClientService} from 'stream-chat-angular';
import {LoginService} from '../../../core/services/login.service';
import {MsgBannerService} from "../../../shared/components/msg-banner/msg-banner.service";

@Component({
  selector: 'app-add-channel',
  templateUrl: './add-channel.component.html',
  styleUrls: ['./add-channel.component.scss']
})
export class AddChannelComponent implements OnInit {

  form: UntypedFormGroup;
  filteredUsers: MerchandiserGetStream[] = [];

  // error list
  messageList = [];
  showNotification = false;

  user: MerchandiserGetStream = null;

  constructor(private dialogRef: MatDialogRef<AddChannelComponent>,
              private associateSvc: AssociateService,
              public getStreamSvc: ChatClientService,
              private loginSvc: LoginService,
              private msgBanner: MsgBannerService,
              public channelService: ChannelService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = new UntypedFormGroup({
      searchBy: new UntypedFormControl(null, [])
    });
  }

  ngOnInit(): void {
    this.form.controls.searchBy.valueChanges.pipe(
      debounceTime(300),
      tap(),
      switchMap(value => {
        if (value?.length < 3) {
          this.filteredUsers = [];
          return of(null);
        }
          if (value instanceof Object || value === '' || !value) {
            return of(null);
          }
        return this.associateSvc.autocompleteUsers(value);
        }
      )
    ).subscribe((users) => {
      this.filteredUsers = users;
    });
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  createChannel(user: MerchandiserGetStream) {
    this.user = user;
  }

  onSubmit() {
    this.showNotification = false;

    const channel = this.getStreamSvc.chatClient.channel('messaging', {
      members: [ this.loginSvc._eid, this.user.eid ]
    });
    channel.create().then(async (res) => {
      console.log('channel created ' + res);
      await channel.watch();
      this.channelService.setAsActiveChannel(channel);
      this.dialogRef.close(this.user);
    }).catch(err => {
      console.error(err);

      this.showNotification = true;
      this.msgBanner.addMsgError(this.messageList, 'Chat could not been created. Please contact your administrator.');
    });
  }

  validateForm() {
    return !this.user;
  }
}
