import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Count} from '../../shared/models/my-events/count.model';
import {environment} from '../../../environments/environment';
import { MyEventsFilter } from '../../shared/models/my-events/my-events-filter.model';
import { MyEventsDetails } from '../../shared/models/my-events/my-events-details.model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class MyEventsService {

  constructor(private http: HttpClient,
              private loginService: LoginService) { }

  getUnscheduledCount(terr: number): Observable<Count> {
    const country = this.loginService._country;
    return this.http.get<Count>(`${environment.apiResourceUri}/schedules/country/${country}/territory/${terr}/unscheduled`);
  }

  public getScheduledEvents(territory: number, searchFilter: MyEventsFilter): Observable<MyEventsDetails[]> {
    const country = this.loginService._country;
    let params = null;
    params = new HttpParams();

    var startScheduledDate = searchFilter.startScheduledDate.toISOString();
    params = params.append('startScheduledDate', startScheduledDate);

    var endScheduledDate = searchFilter.endScheduledDate.toISOString();
    params = params.append('endScheduledDate', endScheduledDate);

    return this.http.get<MyEventsDetails[]>(
      `${environment.apiResourceUri}/schedules/country/${country}/scheduled/territory/${territory}`,
      {params: params});
  }

  public getUnscheduledEvents(territory: number): Observable<MyEventsDetails[]> {
    const country = this.loginService._country;
    return this.http.get<MyEventsDetails[]>(
      `${environment.apiResourceUri}/schedules/country/${country}/unscheduled/territory/${territory}`);
  }
}
