<div fxLayout="column" fxLayoutGap="10px">
	<app-msg-banner *ngIf="showNotification" [msgList]="messageList"
		(msgListChange)="messageList = $event"></app-msg-banner>

	<mat-card fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-between center" fxLayoutGap="20px"
		fxLayoutGap.lt-md="10px">
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center" fxLayoutGap="20px"
			fxLayoutGap.lt-md="10px">
			<mat-form-field *ngIf="scheduled" appearance="outline" style="font-size: 15px">
				<mat-label>Events range:</mat-label>
				<mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="min" [max]="max">
					<input matStartDate formControlName="start" placeholder="Start date">
					<input matEndDate formControlName="end" (dateChange)="onRangeDateChange($event)"
						placeholder="End date">
				</mat-date-range-input>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>

				<mat-error *ngIf="range.controls.end.hasError('required')">End Date is required</mat-error>
				<mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
				<mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
			</mat-form-field>

			<button *ngIf="scheduled" fxFlex.gt-sm="130px" mat-stroked-button class="btn unscheduled"
				[disabled]="range.invalid" (click)="loadData()">Search</button>

			<button *ngIf="!scheduled" fxFlex.gt-sm="230px" class="btn unscheduled" mat-stroked-button
				(click)="changePage()">Scheduled Events</button>
		</div>

		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center" fxLayoutAlign.gt-sm="start center"
			fxLayoutGap="20px" fxLayoutGap.lt-md="10px">
			<mat-slide-toggle color="primary" [formControl]="ownedByMeCtrl">Owned by me</mat-slide-toggle>

			<button *ngIf="scheduled" fxFlex.gt-sm="230px" class="btn unscheduled" mat-stroked-button matBadge="0"
				matBadgeColor="primary" matBadgeSize="small" [matBadgeHidden]="!displayUnscheduledBadge"
				(click)="changePage()">Unscheduled Events</button>
		</div>
	</mat-card>

	<mat-card *ngIf="noRecords" class="no-records-error">
		There are no {{ scheduled ? 'scheduled' : 'unscheduled' }} events{{ scheduled ? ' for this period.' : '.' }}
	</mat-card>

	<mat-accordion class="mat-accordion-spacing" [multi]="true">
		<mat-expansion-panel *ngFor="let dat of data; let i = index" [expanded]="true" [hidden]="!dat.show">
			<mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center" #toggle>
				<mat-panel-title *ngIf="scheduled">{{ dat.scheduleDate | date: 'fullDate' }}</mat-panel-title>
				<mat-panel-title *ngIf="!scheduled">{{ dat.scheduleDate }}</mat-panel-title>

				<div class="week-actions-content">
					<mat-icon *ngIf="scheduled && eventsUtils.hasActiveNonOutlookEvents(dat)" class="info-button b-1" matRipple
						(click)="toggle._toggle(); rescheduleDayEvents(dat)" onkeypress="">
						event_repeat
					</mat-icon>
					<mat-icon *ngIf="scheduled && eventsUtils.hasActiveNonOutlookEvents(dat)" class="info-button t-1" matRipple
						(click)="toggle._toggle(); deleteDayEvents(dat)" onkeypress="">
						auto_delete
					</mat-icon>
					<mat-icon *ngIf="scheduled && checkLocation(i)" class="info-button" matRipple
						(click)="toggle._toggle(); viewMap(i)"  onkeypress="">
						map
					</mat-icon>
				</div>
			</mat-expansion-panel-header>

			<section *ngIf="!scheduled" class="filter" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
				<div fxLayoutAlign="center">
					<label class="filter-title">Show:</label>
				</div>
				<div fxLayoutGap="20px" fxLayoutGap.lt-md="30px">
					<span>
						<mat-checkbox [checked]="allComplete" [indeterminate]="someComplete()"
							(change)="setAll($event.checked)">{{ task.name }}</mat-checkbox>
					</span>
					<span fxLayoutGap="20px" fxLayoutGap.lt-md="30px">
						<mat-checkbox *ngFor="let subtask of task.subtasks" [(ngModel)]="subtask.checked"
							(ngModelChange)="updateAllComplete()">{{ subtask.name }}</mat-checkbox>
					</span>
				</div>
			</section>

			<div>
				<app-my-event-label class="event" *ngFor="let event of eventsUtils.myEventsList(dat)" 
					[event]="event"
					[ownedByMeFilter]="formOwnedBy.value" 
					[scheduled]="scheduled"
					[scheduledDate]="buildScheduleDate(dat.scheduleDate)"
					(onRemoveEventEmitter)="onReloadAllEvents($event)" 
					[conflict]="isConflict(event, dat.myEvents)"
					[isAllDayEvent]="event.isAllDayEvent"
					(onReloadAllEventsEmitter)="onReloadAllEvents($event)">
				</app-my-event-label>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>