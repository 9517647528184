import {UntypedFormGroup} from '@angular/forms';

export class GenericFilterObject {
  form: UntypedFormGroup;
  page: Page;
}

export class Page {
  page: number;
  size: number;
  sort: string[];

  constructor(page: number, size: number, sort: string[]) {
    this.page = page;
    this.size = size;
    this.sort = sort;
  }
}
