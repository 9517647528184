import {CountryGlobalVariables} from './country-global-variables';

export class SortDefinition {
  colDef: string;
  colName: string;
  sortType: string;
  sortOrder: number;
}

export const GENERIC_VIEW_CONTENTS = {
  SERVICED_STORES: {
    SORT: [
      { colDef: 'zone', colName: 'Zone', sortType: '-', sortOrder: 1 },
      { colDef: 'lastCall', colName: 'Last audit date', sortType: 'asc', sortOrder: 2 },
      { colDef: 'accountName', colName: 'Store Name', sortType: '-', sortOrder: 3 },
      { colDef: 'merchandiser.fullName', colName: CountryGlobalVariables.US.filterMerch, sortType: '-', sortOrder: 4 },
      { colDef: 'chainNumber', colName: 'Chain', sortType: '-', sortOrder: 5 },
      { colDef: 'topStoreFlg', colName: 'Top Store', sortType: '-', sortOrder: 6 },
      { colDef: 'route', colName: 'Route', sortType: '-', sortOrder: 7 },
    ]
  },
  NON_SERVICED_STORES: {
    SORT: [
      { colDef: 'zone', colName: 'Zone', sortType: '-', sortOrder: 1 },
      { colDef: 'lastCall', colName: 'Last audit date', sortType: 'asc', sortOrder: 2 },
      { colDef: 'accountName', colName: 'Store Name', sortType: '-', sortOrder: 3 },
      { colDef: 'merchandiser.fullName', colName: CountryGlobalVariables.US.filterMerch, sortType: '-', sortOrder: 4 },
      { colDef: 'chainNumber', colName: 'Chain', sortType: '-', sortOrder: 5 },
      { colDef: 'topStoreFlg', colName: 'Top Store', sortType: '-', sortOrder: 6 },
      { colDef: 'route', colName: 'Route', sortType: '-', sortOrder: 7 },
    ]
  },
  ASSOCIATES: {
    SORT: [
      { colDef: 'lastTrain', colName: CountryGlobalVariables.US.trainingDate, sortType: 'asc', sortOrder: 1 },
      { colDef: 'fullName', colName: 'Name', sortType: '-', sortOrder: 2 },
      { colDef: 'jobTitle', colName: 'Job Title', sortType: '-', sortOrder: 3 },
    ]
  }
};
