import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UK_METRICS_TABLE_CONTENTS } from '../../../../shared/constants/metrics.constants';

@Component({
  selector: 'app-metrics-dialog',
  templateUrl: './metrics-dialog.component.html',
  styleUrls: ['./metrics-dialog.component.scss']
})
export class MetricsDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<MetricsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  closeDialog() {
    this.dialogRef.close(false);
  }

  get ukMerchandiserMetrics() {
    return this.data;
  }

  get ukMerchandiserMetricsColumns() {
    return UK_METRICS_TABLE_CONTENTS.MERCHANDISER_DISPLYED_COLUMNS;
  }
}
