import { Injectable } from "@angular/core";
import { MyEvents } from "../models/my-events/my-events.model";
import { MyEventsArray } from "../models/my-events/my-events-array.model";
import { MyEventType } from "../enum/my-event-type.enum";

@Injectable({
	providedIn: 'root'
})
export class EventsUtilsService {

	myEventsList(dat: MyEvents): MyEventsArray[] {
		return dat.myEvents.filter(m => m.show === true);
	}

	getNonOutlookEvents(events: MyEvents): MyEventsArray[] {
		if (!events)
			return [];

		var availableEvents: MyEventsArray[] = this.myEventsList(events);
		return availableEvents.filter((event: MyEventsArray) => event.type !== MyEventType.OUTLOOK);
	}

	getActiveNonOutlookEvents(events: MyEvents): MyEventsArray[] {
		const eventsList: MyEventsArray[] = this.getNonOutlookEvents(events);

		/** Filter out all the events that are complete */
		return eventsList.filter(($event: MyEventsArray) => {
			if ($event.completedStatus !== 1)
				return $event;
		})
	}

	hasNonOutlookEvents(events: MyEvents) {
		var availableEvents: MyEventsArray[] = this.getNonOutlookEvents(events);
		return availableEvents?.length > 0;
	}

	hasActiveNonOutlookEvents(events: MyEvents) {
		var activeEvents: MyEventsArray[] = this.getActiveNonOutlookEvents(events);
		return activeEvents?.length > 0;
	}

	parseEventTime(time: string): { hour: number, minute: number } {
		const timeParts = time.match(/(\d+):(\d+) (\w+)/);
		if (!timeParts) {
			throw new Error('Invalid time format');
		}

		let hour = parseInt(timeParts[1], 10);
		const minute = parseInt(timeParts[2], 10);
		const period = timeParts[3];

		if (period === 'PM' && hour < 12) {
			hour += 12;
		} else if (period === 'AM' && hour === 12) {
			hour = 0;
		}

		return { hour, minute };
	}
}