import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ScheduleType} from '../../../models/schedule/schedule.model';
import {Country} from '../../../enum/country.enum';
import {Email, QuestionData} from '../../../models/training-audit/history-email.model';
import {environment} from '../../../../../environments/environment';
import {Photo} from '../../../models/training-audit/training-audit.model';
import {ScheduleService} from "../../../../core/services/schedule.service";
import {MsgBannerService} from "../../msg-banner/msg-banner.service";

@Component({
  selector: 'app-email-generator',
  templateUrl: './email-generator.component.html',
  styleUrls: ['./email-generator.component.scss']
})
export class EmailGeneratorComponent implements OnInit {

  // error list
  messageList = [];
  showNotification = false;

  constructor(public dialogRef: MatDialogRef<EmailGeneratorComponent>,
              private scheduleService: ScheduleService,
              private msgBanner: MsgBannerService,
              @Inject(MAT_DIALOG_DATA) public data: Email) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  get title() {
    if (this.data.scheduleType === ScheduleType.TRAINING) {
      const scheduleTypeString = this.data.country === Country.UK ?
        ScheduleType.TRAINING_UK : ScheduleType.TRAINING;
      return `${scheduleTypeString} of ${this.data.associateName} ${this.data.completedDate}`;
    }
    return `${this.data.scheduleType} of ${this.data.chain}-${this.data.store} ${this.data.retailerName} ${this.data.completedDate}`;
  }

  isAudit(): boolean {
    return this.data.scheduleType === ScheduleType.AUDIT;
  }

  get firstLine() {
    return `${this.data.scheduleType} of ${this.data.chain}-${this.data.store} ${this.data.retailerName}, ` +
      `${this.data.city.trim()}, ${this.data.address.trim()}, ${this.data.completedDate}`;
  }

  retrievePhoto(img: string) {
    const url = localStorage.getItem(environment.cloudfrontKey);
    if (url) {
      return JSON.parse(url).url.replace('*', environment.awsS3PhotoPath + img);
    }
  }

  isEditable(questionName: string) {
    return questionName === 'Notes';
  }

  updatePhoto(checked: boolean, question: QuestionData, photo: Photo) {
    const photoIdx = question.photos.findIndex(p => p.photoName === photo.photoName);
    if (checked) {
      question.photos[photoIdx].deleted = false;
    } else {
      question.photos[photoIdx].deleted = true;
    }
  }

  sendEmail() {
    this.scheduleService.sendHistoryEmail(this.data).subscribe(
      resp => {
        this.dialogRef.close('Email sent');
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(
            this.messageList,
            'An error has occurred. Please contact your administrator!'
          );
        }
        this.showNotification = true;
      }
    );
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
