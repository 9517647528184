import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { of, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  finalize,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { LoginService } from '../../../../core/services/login.service';
import { AreaSupervisorService } from '../../../../core/services/area-supervisor.service';
import { MsgBannerService } from '../../../../shared/components/msg-banner/msg-banner.service';
import { AreaSupervisor } from '../../../../shared/models/admin/area-supervisor.model';
import {
  AzureUser,
  AzureUsersPage,
} from '../../../../shared/models/admin/azure-user.model';
import { Country } from '../../../../shared/enum/country.enum';
import { CountryGlobalVariables } from '../../../../shared/constants/country-global-variables';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('territoryMultiSelect', { static: true })
  territoryMultiSelect: MatSelect;

  form: UntypedFormGroup;
  action: string;

  public territoryMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTerritoriesMulti: ReplaySubject<number[]> = new ReplaySubject<
    number[]
  >(1);
  protected _onDestroy = new Subject<void>();
  public tooltipMessage = 'Select All / Unselect All';

  roles: any[] = [];
  territories: number[] = [];
  filteredUsers: AzureUser[] = [];
  isLoading = false;
  countries = []

  messageList = [];
  showNotification = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private service: AreaSupervisorService,
    private msgBanner: MsgBannerService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: AreaSupervisor
  ) {
    if (data) {
      this.action = 'Update';
      this.form = this.fb.group({
        fullNam: new UntypedFormControl({
          value: data.areaSupervisorName,
          disabled: true,
        }),
        usrNam: new UntypedFormControl({
          value: data.areaSupervisorUsername,
          disabled: true,
        }),
        usrRole: [data.role],
        terrs: [data.territories],
        country: [data.country]
      });
    } else {
      this.action = 'Add';
      this.form = this.fb.group({
        fullNam: new UntypedFormControl('', Validators.required),
        usrNam: new UntypedFormControl('', Validators.required),
        usrRole: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl('', []),
      });
    }
  }

  ngOnInit(): void {
    if (!this.isUkUser()) {
      this.countries = [Country.US, Country.CA];
    }

    Promise.all(this.populateDefaultValues())
      .then(() => {
        this.filteredTerritoriesMulti.next(this.territories);
        this.territoryMultiFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterTerritoriesMulti();
          });

        this.form.controls.fullNam.valueChanges
          .pipe(
            debounceTime(300),
            tap(() => (this.isLoading = true)),
            switchMap((value) => {
              if (value?.length < 3) {
                this.filteredUsers = [];
                return of(null);
              }
              if (value instanceof Object || value === '' || !value) {
                return of(null);
              }

              return this.service
                .searchAzureUsers(value)
                .pipe(finalize(() => (this.isLoading = false)));
            })
          )
          .subscribe(
            (users: AzureUsersPage) => (this.filteredUsers = users?.value)
          );

        this.form.controls.usrNam.valueChanges
          .pipe(
            debounceTime(300),
            tap(() => (this.isLoading = true)),
            switchMap((value) => {
              if (value?.length < 3) {
                this.filteredUsers = [];
                return of(null);
              }
              if (value instanceof Object || value === '' || !value) {
                return of(null);
              }

              return this.service
                .searchAzureUsers(value)
                .pipe(finalize(() => (this.isLoading = false)));
            })
          )
          .subscribe(
            (users: AzureUsersPage) => (this.filteredUsers = users?.value)
          );
      })
      .catch((error) => {
        this.msgBanner.addMsgError(
          this.messageList,
          'An error has occurred. Please contact your administrator!'
        );
      });
  }

  populateDefaultValues(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];
    this.roles = this.getSupervisorRoles();

    if (this.action === 'Update') {
      promiseList.push(
        new Promise((release, reject) => {
          this.service.findAllTerritories().subscribe(
            (response: any) => {
              this.territories = response;
              release(true);
            },
            (error) => {
              reject(error);
            }
          );
        })
      );
    }

    return promiseList;
  }

  saveFullName(user: AzureUser) {
    this.form.controls.fullNam.setValue(user.givenName + ' ' + user.surname);
    this.form.controls.usrNam.setValue(user.onPremisesSamAccountName);
  }

  ngAfterViewInit() {
    if (this.action === 'Update') {
      this.setInitialValue();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSubmit() {
    this.showNotification = false;

    if (this.action === 'Add') {
      let supervisor = {
        areaSupervisorName: this.form.controls.fullNam.value,
        areaSupervisorUsername: this.form.controls.usrNam.value,
        role: this.form.controls.usrRole.value,
        country: this.isUkUser() ? Country.UK : this.form.controls.country.value
      } as AreaSupervisor;

      this.service.addSupervisor(supervisor).subscribe(
        (res) => {
          this.dialogRef.close('Add');
        },
        (error) => {
          if (error.status === 400) {
            this.msgBanner.addMsgError(this.messageList, error.error.message);
          } else {
            this.msgBanner.addMsgError(
              this.messageList,
              'An error has occurred. Please contact your administrator!'
            );
          }
          this.showNotification = true;
        }
      );
    }
    if (this.action === 'Update') {
      this.data.role = this.form.controls.usrRole.value;
      this.data.territories = this.form.controls.terrs.value;
      this.data.country = this.isUkUser() ? Country.UK : this.form.controls.country.value;

      this.service.updateSupervisor(this.data).subscribe(
        (res) => {
          this.dialogRef.close('Update');
        },
        (error) => {
          if (error.status === 400 || error.status === 404) {
            this.msgBanner.addMsgError(this.messageList, error.error.message);
          } else {
            this.msgBanner.addMsgError(
              this.messageList,
              'An error has occurred. Please contact your administrator!'
            );
          }
          this.showNotification = true;
        }
      );
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  compareFunction(o1: string, o2: string) {
    return o1 && o2 && o1 === o2;
  }

  protected setInitialValue() {
    this.filteredTerritoriesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.territoryMultiSelect != undefined) {
          this.territoryMultiSelect.compareWith = (a: number, b: number) =>
            a && b && a === b;
        }
      });
  }

  toggleTerrsSelectAll(selectAllValue: boolean) {
    this.filteredTerritoriesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          this.form.controls.terrs.patchValue(val);
        } else {
          this.form.controls.terrs.patchValue([]);
        }
        this.form.controls.terrs?.markAsDirty();
      });
  }

  protected filterTerritoriesMulti() {
    if (!this.territories) {
      return;
    }
    // get the search keyword
    let search = this.territoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerritoriesMulti.next(this.territories);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the territories
    this.filteredTerritoriesMulti.next(
      this.territories?.filter(
        (territory) => territory.toString().toLowerCase().indexOf(search) > -1
      )
    );
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  validateForm() {
    let disableUpdateBtn = true;
    if ( this.action === 'Update' && this.isChangeOnFormFields()) {
      disableUpdateBtn = false;
    }
    if (this.action === 'Add' && this.form?.valid) disableUpdateBtn = false;

    return disableUpdateBtn;
  }

  isChangeOnFormFields(): boolean {
    return (this.form.controls.usrRole?.value != this.data.role || this.form.controls.terrs?.dirty) ||
            (this.form.controls.country?.value != this.data.country);
  }

  isUkUser () {
    return this.loginService.isUkUser();
  }

  getSupervisorRoles() {
    if (this.isUkUser()) {
      return CountryGlobalVariables.UK.SUPERVISOR_ROLES;
    }
    return CountryGlobalVariables.US.SUPERVISOR_ROLES;
  }
}
