import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleService } from '../../../core/services/schedule.service';
import { UtilsService } from '../../../core/services/utils.service';
import { ScheduleResponse } from '../../models/schedule/schedule-response.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MsgBannerService } from '../msg-banner/msg-banner.service';
import { MyEvents } from '../../models/my-events/my-events.model';
import { MyEventsArray } from '../../models/my-events/my-events-array.model';
import { RescheduleEventsModel } from '../../models/schedule/reschedule-events.model';
import { EventsUtilsService } from '../../services/events-utils.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
	selector: 'app-reschedule-day-dialog',
	templateUrl: './reschedule-day-dialog.component.html',
	styleUrls: ['./reschedule-day-dialog.component.scss']
})
export class RescheduleDayDialogComponent implements OnInit {

	public title: string = "Reschedule Events";
	public events: MyEvents
	public formData: UntypedFormGroup;

	public min = new Date();

	// error
	messageList = [];
	showNotification = false;

	constructor(public dialogRef: MatDialogRef<RescheduleDayDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: UntypedFormBuilder,
		private dialog: MatDialog,
		private eventsUtils: EventsUtilsService,
		private scheduleService: ScheduleService,
		private msgBanner: MsgBannerService) {
		this.formData = this.fb.group({
			date: [null, [Validators.required], []],
		});
	}

	ngOnInit(): void {
		if (this.data.events == null)
			this.dialogRef.close();

		this.events = this.data.events;
		this.fillFormValues();
	}

	onClose() {
		/* confirmation dialog */
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				title: null,
				message:
					'Are you sure you want to discard all changes?',
				confirmationRequired: true,
				yesButtonShow: true,
				noButtonShow: true
			},
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				this.dialogRef.close();
			}
		});
	}

	fillFormValues() {
		let date = this.data.date;
		if (date == null)
			return;

		this.title = "Reschedule Events";
		this.formData.controls.date.setValue(this.events.scheduleDate);
	}

	onSave() {
		const eventsList: MyEventsArray[] = this.eventsUtils.getActiveNonOutlookEvents(this.events);
		const rescheduleDate = this.getRescheduleDate();

		const rescheduleRequest: RescheduleEventsModel = {
			rescheduleDate: rescheduleDate,
			scheduledIds: eventsList.map(event => event.scheduleId)
		} as RescheduleEventsModel;

		if (rescheduleDate != null) {
			let checkList: Observable<number>[] = []

			eventsList.forEach((event: MyEventsArray) => {
				let eventRescheduleDate = this.getRescheduleDate(event);
				let observableCheck = this.scheduleService.checkScheduledDateTimeConflict(event.scheduleId, eventRescheduleDate);

				checkList.push(observableCheck);
			})

			forkJoin(checkList).subscribe({
				next: (resultList: number[]) => {
					if (resultList.some((value) => value > 0)) {
						this.displayDateTimeConflictDialog(rescheduleRequest);
						return;
					} else {
						this.rescheduleEvent(rescheduleRequest);
					}
				},
				error: () => {
					this.msgBanner.addMsgError(this.messageList, 'An error occurred while trying to validate the selected events. ' +
						'Please try again or contact your administrator.');
					this.showNotification = true;
				}
			});

			return;
		} else {
			this.msgBanner.addMsgError(this.messageList, 'The selected reschedule date cannot be null or empty.');
			this.showNotification = true;
		}
	}

	rescheduleEvent(rescheduleRequest: RescheduleEventsModel) {
		this.scheduleService.rescheduleEventsInBulk(rescheduleRequest).subscribe({
			next: (responseList: ScheduleResponse[]) => {
				let outlookEventSuccessResult: boolean = responseList.every((value) => value.outlookEventSuccess);
				this.displayConfirmationMessage(outlookEventSuccessResult);
			},
			error: (error) => {
				if (error.status === 404)
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				else
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');

				this.showNotification = true;

			}
		})
	}


	getRescheduleDate(event?: MyEventsArray) {
		if (!this.formData.controls.date.value) {
			return null;
		}

		var hours = 0, minutes = 0, seconds = 0, milliseconds = 0;
		var scheduledDate = new Date(this.formData.controls.date.value);

		if (event) {
			let timeEvent = this.eventsUtils.parseEventTime(event.time);
			hours = timeEvent.hour;
			minutes = timeEvent.minute;
		}

		scheduledDate.setHours(hours);
		scheduledDate.setMinutes(minutes);
		scheduledDate.setSeconds(seconds);
		scheduledDate.setMilliseconds(milliseconds);

		return UtilsService.dateToServerDate(scheduledDate);
	}




	displayConfirmationMessage(isOutlookSuccess: boolean) {
		let message = isOutlookSuccess ? 'Events have been updated successfully!' :
			'Events were successfully updated, but Outlook Events could not be updated!';

		const confirmationDialogref = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: message,
				confirmationRequired: false
			},
		});

		confirmationDialogref.afterClosed().subscribe((dialogResult) => {
			this.dialogRef.close(true);
		});
	}

	displayDateTimeConflictDialog(rescheduleRequest: RescheduleEventsModel) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: 'An event already exists at that time. Do you want to continue?',
				confirmationRequired: true,
				yesButtonShow: true,
				cancelButtonShow: true
			},
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				this.rescheduleEvent(rescheduleRequest);
			}
		});
	}
}
