export const environment = {
  production: false,
  baseHref: '/my-team/',
  clientId: '3d1427cb-1c55-4c14-811c-84be6da0230f',
  backendApiScope: 'api://ebf4d37f-47e9-4321-a03d-4f5990a20a7f/access_as_user',
  apiResourceUri: 'https://myteam-api.amgreetings.com/myteam-service/api',
  googleMapsApi: 'AIzaSyDQvR0FIt2qTkts_a9OrpEDHvdKhKdcepE',
  cloudfrontKey: 'cloud-front-secured-url',
  cloudFrontRequests: '.cloudfront.net/',
  awsS3PhotoPath: 'photocapture_prod/',
  powerBiUrl: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=dad8e6a3-839b-40a5-ba88-818a1166f58d&ctid=6b29189e-3d5d-4d76-970c-abe758468ae4',
  chat: {
    apiKey: 'hndbj8dunnxr',
    url: 'https://myteam-api.amgreetings.com/assets/img/group_chat.png'
  },
  appInsights: {
    instrumentationKey: '330fbc2a-f35f-4324-bb5f-63ef1267379b'
  },
  maxFileUploadSize: 10
};
