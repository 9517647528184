import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {SCHEDULE_CONTENTS, SCHEDULE_TYPE} from '../../constants/schedule.constants';
import {AssociateDetails} from '../../models/associates/associate-details.model';
import {StoreDetails} from '../../models/stores/store-details.model';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {UtilsService} from '../../../core/services/utils.service';
import {ScheduleModel} from '../../models/schedule/schedule.model';
import {Purpose} from '../../models/schedule/purpose.model';
import {Router} from '@angular/router';
import {AssociateDetailsPageRedirectService} from '../../../core/services/previous-page-redirect.service';
import {LoginService} from '../../../core/services/login.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {CountryGlobalVariables} from '../../constants/country-global-variables';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgBannerService } from '../msg-banner/msg-banner.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: CountryGlobalVariables.US.dateFormat,
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-generic-schedule-event',
  templateUrl: './generic-schedule-event.component.html',
  styleUrls: ['./generic-schedule-event.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class GenericScheduleEventComponent implements OnInit, OnDestroy {

  // config object
  @Input() associate: AssociateDetails;
  @Input() isEditFromUnscheduled: boolean;
  @Input() isRedirectFromAssociateDetails: boolean;
  @Input() storeDataFrom: UntypedFormGroup;
  @Output() storeDropdownEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSaveEmitter: EventEmitter<ScheduleModel> = new EventEmitter<ScheduleModel>();

  protected _onDestroy = new Subject<void>();
  storeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredStores: ReplaySubject<StoreDetails[]> = new ReplaySubject<StoreDetails[]>();
  stores: StoreDetails[];

  @Input() set storeList(stores: StoreDetails[]) {
    if (stores == null)
      return;
    this.stores = stores;
    this.initStoreSearchFilter();
  }

  @Input() purposesOfCall: Purpose[];
  purposesErr: string = '';

  min = new Date();

  // error list
  messageList = [];
  showNotification = false;
  @Input() set hasAssociateNotFound(value: boolean) {
    if (value === true) {
      this.showNotification = true;
      this.msgBanner.addMsgWarning(this.messageList,'Store has no merchandiser assigned.');
    }
  };

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.isRedirectFromAssociateDetails) {
      this.redirectToAssociatesPageService.setRedirectToAssociateDetails();
    }
  }

  constructor(private dialog: MatDialog,
              private location: Location,
              private router: Router,
              private loginSvc: LoginService,
              private redirectToAssociatesPageService: AssociateDetailsPageRedirectService,
              private msgBanner: MsgBannerService) {
    if (this.loginSvc.isUkUser()) {
      MY_FORMATS.display.dateInput = CountryGlobalVariables.UK.dateFormat;
    }
  }

  ngOnInit(): void {
    this.initFormGroups();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  isUkUser(): boolean {
    return this.loginSvc.isUkUser();
  }

  get CountryGlobalVariables() {
    return CountryGlobalVariables;
  }

  initFormGroups() {
    this.storeDataFrom.controls.store.valueChanges.subscribe((storeId) => {
      this.showNotification = false;
      this.messageList = [];
      this.storeDropdownEmitter.emit(storeId);
    });
  }

  validatePurposes() {
    if (this.purposesOfCall == null)
      return true;

    var checked = 0;
    this.purposesOfCall.forEach(purpose => {
      if (purpose.selected)
        checked++;
    });

    // under or over selection limit
    if (checked == 0) {
      this.purposesErr = '* At least one purpose has to be selected!';
      return false;
    } else if (checked > SCHEDULE_CONTENTS.MAX_PURPOSES_OF_CALL) {
      this.purposesErr = '* A maximum of three purposes can be selected!';
      return false;
    } else {
      this.purposesErr = '';
      return true;
    }
  }

  get timeSlots() {
    return SCHEDULE_CONTENTS.TIME_SLOTS.filter(t => t.country.includes(this.loginSvc._country));
  }

  onCancel() {
    // confirmation dialog
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: null,
        message:
          'Are you sure you want to discard all changes?',
        confirmationRequired: true,
        yesButtonShow: true,
        noButtonShow: true
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // go to previous page and reset form
        if (this.isEditFromUnscheduled) {
          this.goToMyEventsPage(false);
        } else {
          this.location.back();
        }
      }
    });
  }

  goToMyEventsPage(scheduledEvent: boolean) {
    const data = scheduledEvent ? null : {scheduleType: SCHEDULE_TYPE.UNSCHEDULED}
    this.router.navigate(['/my-events'], {state: {data}});
  }

  onSave() {
    if (this.storeDataFrom.invalid || !this.validatePurposes())
      return;

    var schedule: ScheduleModel = {
      id: null,
      merchandiserId: this.associate ? this.associate.id : null,
      areaSupervisorId: null,
      retailerId: this.storeDataFrom.controls.store.value,
      scheduledDate: null,
      scheduledOutlookDate: null,
      isAllDayEvent: this.isAllDayEvent(),
      scheduleType: null,
      completedStatus: null,
      territoryNumber: null,
      purposeActions: []
    };
    this.setScheduledDates(schedule);

    this.onSaveEmitter.emit(schedule);
  }

  setScheduledDates(schedule: ScheduleModel) {
    if (!this.storeDataFrom.controls.date.value) {
      return null;
    }

    var scheduledDate = new Date(this.storeDataFrom.controls.date.value);
    scheduledDate.setHours(0);
    scheduledDate.setMinutes(0);
    scheduledDate.setSeconds(0);
    scheduledDate.setMilliseconds(0);

    if (this.storeDataFrom.controls.startTime.value) {
      let scheduledTime: string = this.storeDataFrom.controls.startTime.value;
      const hourMinutes: string[] = scheduledTime.split(':');
      scheduledDate.setHours(Number(hourMinutes[0]));
      scheduledDate.setMinutes(Number(hourMinutes[1]));
    }

    if (this.isAllDayEvent()) {
      schedule.scheduledDate = UtilsService.dateToServerDate(scheduledDate);
      schedule.scheduledOutlookDate = UtilsService.dateToServerDate(scheduledDate);
    } else {
      schedule.scheduledDate = scheduledDate;
      schedule.scheduledOutlookDate = scheduledDate;
    }
  }

  isAllDayEvent() {
    if (this.storeDataFrom.controls.date.value &&
      this.storeDataFrom.controls.startTime.value == null)
      return true;

    return false;
  }

  initStoreSearchFilter() {
    this.filteredStores.next(this.stores);
    this.storeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStores();
      });
  }

  protected filterStores() {
    if (!this.stores) {
      return;
    }

    // get the search keyword
    let search = this.storeFilterCtrl.value;
    if (!search) {
      this.filteredStores.next(this.stores);
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the stores
    this.filteredStores.next(
      this.stores?.filter(
        (store: StoreDetails) =>
          store.chain?.toLowerCase().indexOf(search) > -1 ||
          store.store?.toLowerCase().indexOf(search) > -1
      )
    );
  }
}

