export class Associate {
    id: number;
    fullName: string;
    lastTrain: Date;
    hireDate: string;
    birthday: string;
    isDisabled: string;
    userName: string;
    country: string;
    zones: string;
    newHire: Boolean = true;
    jobTitle: string;
    employeeNo: number;
    contractedHours: number;
    isChatActive?: boolean;

    // metrics
    latePayrollEntriesActual: number;
}

export const DefaultAssociateId = {
  US: 99998,
  CA: -2,
  UK: -1
};

export function isTheDefaultAssociate(associate: Associate): boolean {
  return associate != null &&
        (associate.id === DefaultAssociateId.US || associate.id === DefaultAssociateId.CA || associate.id === DefaultAssociateId.UK);
}
