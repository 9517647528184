import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { AuthGuard } from '../../core/guards/auth.guard';
import { LoginGuard } from '../../core/guards/login.guard';
import { ChatComponent } from './chat.component';
import { FeatureFlagGuard } from '../../core/guards/feature-flag.guard';
import { RolesEnum } from './../../shared/enum/role.enum';
import { RoleGuard } from './../../core/guards/role.guard';


const moduleRoutes: Routes = [{
	path: 'chat',
	component: ChatComponent,
	canActivate: [AuthGuard, MsalGuard, LoginGuard, FeatureFlagGuard, RoleGuard],
	data: {
		excludedRoles: [RolesEnum.TRS]
	}
}];

export const ChatModuleRouting = RouterModule.forChild(moduleRoutes);
