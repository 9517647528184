import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnDefinition, ColumnType } from '../../constants/metrics.constants';

@Component({
  selector: 'app-generic-metrics-table',
  templateUrl: './generic-metrics-table.component.html',
  styleUrls: ['./generic-metrics-table.component.scss']
})
export class GenericMetricsTableComponent implements OnInit {

  @Input() title: string;
  @Input() columns: ColumnDefinition[];
  @Input() data: any;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = this.columns?.map(d => d.colDef);
    this.dataSource = new MatTableDataSource([this.data]);
  }

  get getColumnType() {
    return ColumnType;
  }
}
