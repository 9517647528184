import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Email } from "../../shared/models/training-audit/history-email.model";
import jsPDF from 'jspdf';
import { environment } from '../../../environments/environment';
import { Photo } from "../../shared/models/training-audit/training-audit.model";
import { ScheduleType } from "../../shared/models/schedule/schedule.model";
import { Country } from "../../shared/enum/country.enum";

@Injectable({
  providedIn: 'root'
})
export class ExportSchedulePdf {
	// A4 defaults
	pageWidth = 8.3;
	pageheight = 11.7;
	margin = 0.5;
	lineHeight = 1.2;
	maxLineWidth = this.pageWidth - this.margin * 2;
	fontSize = 12;
	ptsPerInch = 72;
	oneLineHeight = (this.fontSize * this.lineHeight) / this.ptsPerInch;
	margin_top_bot = this.margin + 2 * this.oneLineHeight;
  
  constructor(private http: HttpClient) { }

	exportScheduleAsPdf(data: Email) {
		Promise.all(this.loadScheduleImages(data))
			.then(() => {
			this.exportPdf(data);
		});
	}

	private exportPdf(data: Email) {
    let nextLineOffset = this.margin_top_bot;

    let doc = new jsPDF({ unit: "in" }).setFont("helvetica").setFontSize(this.fontSize);

    data.topicsData.forEach(topic => {
			nextLineOffset = this.writeText(doc, topic.topicName, "bold", nextLineOffset);

			topic.topicQuestions.forEach(question => {
				nextLineOffset = this.writeText(doc, question.questionName + ": ", "bold", nextLineOffset);
				nextLineOffset = this.writeText(doc, question.questionValue, "normal", nextLineOffset);
				nextLineOffset = this.writePhotos(doc, question.photos, nextLineOffset);

				// space after topic
				nextLineOffset += this.oneLineHeight;
			});
    });

    // build title 
    doc.save(this.buildFileName(data));
  }

	private writeText(doc: jsPDF, text: string, fontStyle: string, nextLineOffset: number): number {
		if (text == null) {
			return nextLineOffset;
		}

		let textLines: string[] = doc.splitTextToSize(text, this.maxLineWidth);
		if (nextLineOffset + textLines.length * this.oneLineHeight + this.margin_top_bot > this.pageheight) {
			doc.addPage();
			nextLineOffset = this.margin_top_bot;
		}

		doc.setFont("helvetica", fontStyle);
		doc.text(textLines, this.margin, nextLineOffset);

		return nextLineOffset + textLines.length * this.oneLineHeight;
	}

	private writePhotos(doc: jsPDF, photos: Photo[], nextLineOffset): number {
		if (photos == null) {
			return nextLineOffset;
		}

		let photoLeftMargin = this.margin;
		let photoNextLineOffset = 0.0;
		photos.forEach(photo => {
			if (photo.base64data == null) return;

			let photoDetails = doc.getImageProperties(photo.base64data.toString());
			let maxWidthHeight = 150.0;
			let resizeRatio = (photoDetails.height > maxWidthHeight || photoDetails.width > maxWidthHeight) ?
													(Math.min(maxWidthHeight / photoDetails.height, maxWidthHeight / photoDetails.width)) : 1;

			let overflowsPage = nextLineOffset + this.margin_top_bot +
													(photoDetails.height * resizeRatio) / this.ptsPerInch > this.pageheight;
			if (overflowsPage === true) {
				doc.addPage();
				nextLineOffset = this.margin_top_bot;
			}
			
			doc.addImage(photo.base64data.toString(), 
									"JPEG", photoLeftMargin, nextLineOffset, 
									(photoDetails.width * resizeRatio) / this.ptsPerInch, 
									(photoDetails.height * resizeRatio) / this.ptsPerInch);
			photoLeftMargin += (photoDetails.width * resizeRatio) / this.ptsPerInch + this.margin;
			photoNextLineOffset = Math.max(photoNextLineOffset, (photoDetails.height * resizeRatio) / this.ptsPerInch);
		});

		return nextLineOffset + photoNextLineOffset + 2 * this.oneLineHeight;
	}

	private buildFileName(data) {
		if (data == null) {
			return "myteam_schedule_pdf_export.pdf";
		}

		let fileName = null;
		if (data.scheduleType === ScheduleType.TRAINING) {
      const scheduleTypeString = data.country === Country.UK ? 
																	ScheduleType.TRAINING_UK : ScheduleType.TRAINING;
      fileName = scheduleTypeString + " of " + data.associateName + " " + data.completedDate;
    } else {
			fileName = data.scheduleType + " of " + data.chain + "-" + data.store + 
								" " + data.retailerName + " " + data.completedDate;
		}

    return fileName + ".pdf";
	}

	private getImageURL(imgName: string) {
		const url = localStorage.getItem(environment.cloudfrontKey);
		return JSON.parse(url).url.replace('*', environment.awsS3PhotoPath + imgName);
  }

  private loadScheduleImages(data: Email): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];

    let photos: Photo[] = [];
    data.topicsData.forEach(topic => {
        topic.topicQuestions.forEach(question => {
        if (question.photos != null) {
            photos = photos.concat(question.photos);
        }
        });
    });

    photos.forEach(photo => {
      promiseList.push(
        new Promise((release, reject) => {
          let photoUrl = this.getImageURL(photo.photoName);
          this.http.get(photoUrl + '&crossOrigin', { responseType: 'blob' }).subscribe(            
            (response: Blob) => {
              var reader = new FileReader();
              reader.readAsDataURL(response); 
              reader.onloadend = function() {
                var base64data = reader.result;
                photo.base64data = base64data;
                release(true);
              }
            },
            (error) => {
              release(false);
            }
          );
        })
      );
    });

    return promiseList;
  }
}