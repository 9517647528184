import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphService } from './graph/graph.service';
import { TerritoryService } from './services/territory-management.service';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { BirthdayPipePipe } from './pipes/birthday-pipe.pipe';

@NgModule({
	declarations: [
		CustomDatePipe,
		BirthdayPipePipe
	],
	imports: [
		CommonModule
	],
	exports: [CommonModule, CustomDatePipe, BirthdayPipePipe],
	providers: [
		GraphService,
		TerritoryService]
})
export class CoreModule { }