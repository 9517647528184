<mat-dialog-content
  fxLayout="column"
>

  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event">
  </app-msg-banner>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="title">Create New</div>
    <div>
      <button
        mat-icon-button
        (click)="closeDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="20px">
    <form
      [formGroup]="form"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
    >
      <mat-form-field
        appearance="standard"
        style="width: 100%"
      >
        <input
          type="text"
          formControlName="searchBy"
          matInput
          placeholder="Enter User Name or Eid*"
          id="searchBy"
          [matAutocomplete]="auto"
          required
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let user of filteredUsers"
            [value]="user.name + ' (' + user.eid  +')'"
            (onSelectionChange)="createChannel(user)"
          >
            {{ user.name }} ({{ user.eid }})
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>

  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="center"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="2%"
    fxLayoutGap.lt-md="0"
  >
    <button
      fxFlex.gt-sm="25%"
      id="submit"
      class="btn btn-submit"
      (click)="onSubmit()"
      [disabled]="validateForm()"
    >
      Create
    </button>
    <button id="cancel" class="btn btn-cancel" (click)="closeDialog()" fxFlex.gt-sm="25%">
      Cancel
    </button>
  </div>

</mat-dialog-content>
