import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MyEventsArray} from '../../../shared/models/my-events/my-events-array.model';
import {MyEventType} from '../../../shared/enum/my-event-type.enum';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { TrainingAuditService } from '../../../core/services/training-audit.service';
import { ScheduleResponse } from '../../../shared/models/schedule/schedule-response.model';
import { PagesEnum } from '../../../shared/models/stores/pages.model';
import { Associate } from '../../../shared/models/associates/associate.model';
import { StoreDetails } from '../../../shared/models/stores/store-details.model';
import { AssociateService } from '../../../core/services/associates.service';
import { StoresService } from '../../../core/services/stores.service';
import { RescheduleDialogComponent } from '../../../shared/components/reschedule-dialog/reschedule-dialog.component';
import { CountryGlobalVariables } from '../../../shared/constants/country-global-variables';
import { LoginService } from '../../../core/services/login.service';
import {AssociateDetailsComponent} from '../associate-details/associate-details.component';

@Component({
  selector: 'app-my-event-label',
  templateUrl: './my-event-label.component.html',
  styleUrls: ['./my-event-label.component.scss']
})
export class MyEventLabelComponent implements OnInit {

  @Input() event: MyEventsArray;
  @Input() ownedByMeFilter: boolean;
  @Input() scheduled: boolean;
  @Input() scheduledDate: Date;
  @Output() onRemoveEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() conflict: boolean;
  @Input() isAllDayEvent: boolean;
  @Output() onReloadAllEventsEmitter: EventEmitter<any> = new EventEmitter<any>();

  // error
  messageList = [];
  showNotification = false;

  constructor(private dialog: MatDialog,
              private trainingAuditService: TrainingAuditService,
              private router: Router,
              private associateService: AssociateService,
              private storesService: StoresService,
              private loginService: LoginService) { }

  ngOnInit(): void {
  }

  get myEventType() {
    return MyEventType;
  }

  onReschedule() {
    const dialogRef = this.dialog.open(RescheduleDialogComponent, {
      data: {
        scheduleId: this.event.scheduleId,
        date: this.scheduledDate,
        time: this.event.time
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.onReloadAllEventsEmitter.emit();
      }
    });
  }

  editSchedule() {
    if (this.event.scheduleId == null) {
      return;
    }

    const data = {
      scheduleId: this.event.scheduleId,
      isEditFromUnscheduled: this.scheduled === false
    };

    switch (this.event.type) {
      case MyEventType.APPOINTMENT:
        this.router.navigate(['schedule-appointment'], { state: { data } });
        break;
      case MyEventType.AUDIT:
        this.router.navigate(['/schedule-audit'], { state: { data } });
        break;
      case MyEventType.TRAINING:
        this.router.navigate(['schedule-training'], { state: { data } });
        break;
    }
  }

  onRemoveSchedule() {
    if (this.event.scheduleId == null) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: 'Are you sure you want to remove this event?',
        confirmationRequired: false,
        yesButtonShow: true,
        noButtonShow: true
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.trainingAuditService.deleteTrainingAudit(this.event.scheduleId).subscribe(
          (response: ScheduleResponse) => {
            this.displayRemoveEventConfirmationMessage(response.outlookEventSuccess);
          },
          (error) => {
            if (error.status === 404) {
              this.displayErrorDialog(error.error.message);
            } else {
              this.displayErrorDialog('An error has occurred. Please contact your administrator.');
            }
          }
        );
      }
    });
  }

  displayRemoveEventConfirmationMessage(isOutlookSuccess: boolean) {
    const message = isOutlookSuccess ? 'Event has been successfully removed!' :
        'Event has been successfully removed, but the Outlook Event failed to be deleted!';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: message,
        confirmationRequired: false
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
        // reload MyEvents data
        this.onRemoveEventEmitter.emit();
    });
  }

  onStartTrainingAudit() {
    if (this.event.scheduleId == null) {
      return;
    }

    switch (this.event.type) {
      case MyEventType.AUDIT:
        this.openAuditForScheduledEvent(this.event.scheduleId);
        break;
      case MyEventType.TRAINING:
        this.openTrainingForScheduledEvent(this.event.scheduleId);
        break;
    }
  }

  openTrainingForScheduledEvent(scheduleId: string) {
    this.associateService.getAssociateByScheduleId(scheduleId).subscribe(
      (response: Associate) => {
        this.goToTraining(scheduleId, response);
      },
      (error) => {
        if (error.status === 404) {
          this.displayErrorDialog(error.error.message);
        } else {
          this.displayErrorDialog('An error has occurred. Please contact your administrator.');
        }
      }
    );
  }

  goToTraining(scheduleId: string, associate: Associate) {
    const data = {
      associate: associate,
      page: PagesEnum.ASSOCIATES,
      scheduleId: scheduleId
    };
    this.router.navigate(['training'], { state: { data } });
  }

  openAuditForScheduledEvent(scheduleId: string) {
    this.storesService.getStoreDetailsByScheduleId(scheduleId).subscribe(
      (response: StoreDetails) => {
        this.goToAudit(scheduleId, response);
      },
      (error) => {
        if (error.status === 404) {
          this.displayErrorDialog(error.error.message);
        } else {
          this.displayErrorDialog('An error has occurred. Please contact your administrator.');
        }
      }
    );
  }

  goToAudit(scheduleId: string, storeDetails: StoreDetails) {
    const page = storeDetails.isNonServiced === true ? PagesEnum.NON_SERVICED_STORES : PagesEnum.SERVICED_STORES;
    const data = {
      store: storeDetails,
      page: page,
      scheduleId: scheduleId
    };
    this.router.navigate(['audit'], { state: { data } });
  }

  displayErrorDialog(message: string) {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: message,
        confirmationRequired: false
      },
    });
  }

  isUkUser(): boolean {
    return this.loginService.isUkUser();
  }

  getTrainingText() {
    if (this.isUkUser()) {
      return CountryGlobalVariables.UK.training;
    }
    return CountryGlobalVariables.US.training;
  }

  doShowDirections() {
    if (!this.event.lat || !this.event.lon) {
      return;
    }

    const url = `https://www.google.com/maps/dir/?api=1&destination=${this.event.lat},${this.event.lon}`;
    window.open(url);
  }

  goToAssociateDetails() {
    this.associateService.getAssociateByScheduleId(this.event.scheduleId).subscribe(
      (response: Associate) => {
        this.viewAssociateDetails(response);
      },
      (error) => {
        if (error.status === 404) {
          this.displayErrorDialog(error.error.message);
        } else {
          this.displayErrorDialog('An error has occurred. Please contact your administrator.');
        }
      }
    );
  }

  viewAssociateDetails(data: Associate) {
    this.dialog.open(AssociateDetailsComponent, {
      panelClass: 'mobile-dialog',
      disableClose: true,
      data: data,
    });
  }

  viewStoreDetails() {
    this.storesService.getStoreDetailsByScheduleId(this.event.scheduleId).subscribe(
      (response: StoreDetails) => {
        this.goToStoreDetails(response);
      },
      (error) => {
        if (error.status === 404) {
          this.displayErrorDialog(error.error.message);
        } else {
          this.displayErrorDialog('An error has occurred. Please contact your administrator.');
        }
      }
    );
  }

  goToStoreDetails(store: StoreDetails) {
    const data = {
      id: store.id,
      page: PagesEnum.SERVICED_STORES
    };
    this.router.navigate(['serviced-details'], { state: { data } });
  }
}
