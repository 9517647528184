export class Purpose {
    id: number;
    desc: string;
    selected: boolean;
}

export class PurposeAction {
    purposeId: number;
    action: string;

    constructor(purposeId: number, action: string) {
      this.purposeId = purposeId;
      this.action = action;
    }
}

export function buildAddPurposeAction(purposeId: number) {
  return new PurposeAction(purposeId, 'add');
}

export function buildDeletePurposeAction(purposeId: number) {
  return new PurposeAction(purposeId, 'delete');
}
