<mat-dialog-content>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  >
  </app-msg-banner>

  <div fxLayout="column">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="start">
        {{ getTitle() }}
      </div>
      <div fxLayoutAlign="end">
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <form [formGroup]="form" fxLayout="row" fxLayoutAlign="center center">
      <tag-input
        formControlName="recipients"
        (onAdd)="onItemAdded($event)"
        [separatorKeyCodes]="separatorKeysCodes"
        [addOnPaste]="true"
        (onPaste)="onItemPaste($event)"
        [pasteSplitPattern]="reg"
        [editable]="true"
        [addOnBlur]="addOnBlur"
        (onRemove)="onItemRemoved($event)"
        (onTagEdited)="onTagEdited($event, 'recipients')"
        [validators]="validators"
        [onlyFromAutocomplete]="false"
        placeholder=" + Recipient"
        secondaryPlaceholder="Recipient"
        fxFlex="90%"
      >
        <tag-input-dropdown
          [autocompleteObservable]="requestAutocompleteItems"
          [matchingFn]="tagMatching">
          <ng-template let-item="item" let-index="index">
            {{ item.display }}
          </ng-template>
        </tag-input-dropdown>
      </tag-input>
    </form>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center"
      fxLayoutGap="2%"
    >
      <button
        fxFlex="140px"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn btn-action"
        type="button"
        (click)="onCancel()"
      >
        Cancel
      </button>
      <button
        fxFlex="140px"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn btn-search"
        color="primary"
        type="button"
        (click)="onSendEmail()"
        [disabled]="form.controls.recipients.value?.length == 0"
      >
        Preview
      </button>
    </div>
  </div>
</mat-dialog-content>
