import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormField} from '../../models/training-audit/form-field.model';
import {QuestionTypeEnum} from '../../enum/question-type.enum';
import {ImageFile} from '../../models/training-audit/image-file.model';
import {Dialog} from '../../models/dialog';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import { CameraComponent } from './camera/camera.component';
import { WebcamImage } from 'ngx-webcam';
import {LoadingComponent} from '../loading/loading.component';
import {MsgBannerService} from "../msg-banner/msg-banner.service";

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent implements OnInit {

  images: ImageFile[] = [];

  @Input() input: FormField<string>;
  @Input() form: UntypedFormGroup;
  @Input() readOnly: boolean;

  // error list
  messageList = [];
  showNotification = false;


  constructor(private dialog: MatDialog,
              private msgBanner: MsgBannerService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.input.questionElementType === null) {
      this.form.controls[this.input.id]?.valueChanges.subscribe(
        response => {
          const resp = response instanceof Array ? response : [response];
          this.images = response != null ? resp : null;
        }
      );
    }
  }

  get questionType() {
    return QuestionTypeEnum;
  }

  get actualImages() {
    return this.images?.filter(i => i.deleted === false);
  }

  onFileChange(event, id) {
    let files = <File[]>event.target.files;

    if (this.filesUploadLimit(files)) {
      return;
    }

    if (files && files[0]) {
      files = Array.from(files).sort(function (a, b) {
        return a.size - b.size;
      });

      const allowedFiles = this.allowedFiles(files);
      if (allowedFiles.length === 0) {
        return;
      }

      const dialogRef = this.openLoadingDialog(allowedFiles.length);

      for (let i = 0; i < allowedFiles.length; i++) {
        const reader = new FileReader();

        reader.onload = (ev: any) => {
          const extIdx = allowedFiles[i].name.lastIndexOf('.');

          reduceFileSize(allowedFiles[i], 500 * 1024, 1000, Infinity, 0.8, file => {
            this.images.push({
              id: null,
              file: file,
              name: file.name.substring(0, extIdx) + '_' + new Date().getTime() + 1 + file.name.substring(extIdx),
              res: ev.target.result,
              success: false,
              progress: 0,
              deleted: false
            });
            this.updateForm(id);

            if (i === allowedFiles.length - 1) {
              dialogRef.close();
            }
          });
        };

        reader.readAsDataURL(allowedFiles[i]);
      }
    }
  }

  private allowedFiles(files: any[]): any[] {
    const allowedFiles: any[] = [];
    for (const element of files) {
      if ((element.size / 1024 / 1024) > environment.maxFileUploadSize) {
        this.msgBanner.addMsgWarning(this.messageList, 'File size exceeded(' + environment.maxFileUploadSize + 'MB) for ' + element.name);
        this.showNotification = true;
        continue;
      }
      allowedFiles.push(element);
    }
    return allowedFiles;
  }

  private filesUploadLimit(files: File[]): boolean {
    if (this.actualImages?.length === 3 || (this.actualImages?.length + files.length) > 3) {
      this.snackBar.open('You can upload maximum 3 photos.', 'Close', {
        duration: 10 * 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return true;
    }
    return false;
  }

  private openLoadingDialog(len: number) {
    return this.dialog.open(LoadingComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        len
      }
    });
  }

  remove(index, id) {
    this.dialog.open(ConfirmDialogComponent, {
      data: new Dialog('Are you sure you want to remove this photo?', true, false, true),
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result === true) {
        this.images[index].deleted = true;
        this.updateForm(id);
      }
    });
  }

  updateForm(id) {
    this.form.controls[id].setValue(this.images);
    this.form.controls[id].updateValueAndValidity();
  }

  getFullImage(img: ImageFile) {
    if (img.res) {
      return img.res;
    } else {
      const url = localStorage.getItem(environment.cloudfrontKey);
      if (url) {
        return JSON.parse(url).url.replace('*', environment.awsS3PhotoPath + img.name);
      }
    }
  }

  openCamera() {
    const dialogRef = this.dialog.open(CameraComponent, {
      panelClass: 'mobile-dialog',
      disableClose: true,
      data: { photoLimit: this.actualImages?.length ? 3 - this.actualImages?.length : 3 },
    });

    dialogRef.afterClosed().subscribe((photos: WebcamImage[]) => {
      if (photos && photos.length > 0) {
        this.onCameraPhotosChange(photos);
      }
    });
  }

  onCameraPhotosChange(photos: WebcamImage[]) {
    if (this.webcamUploadLimit(photos)) {
      return;
    }

    const allowedPhotos = this.allowedFiles(photos);
    if (allowedPhotos.length === 0) {
      return;
    }
    const dialogRef = this.openLoadingDialog(allowedPhotos.length);

    allowedPhotos.forEach((photo, index) => {
      const imageFile = this.buildFileFromDataUrl(photo.imageAsDataUrl);
      reduceFileSize(imageFile, 500 * 1024, 1000, Infinity, 0.8, file => {
        this.images.push({
          id: null,
          file: file,
          name: file.name,
          res: photo.imageAsDataUrl,
          success: false,
          progress: 0,
          deleted: false
        });
        this.updateForm(this.input.id);

        if (index === photos.length - 1) {
          dialogRef.close();
        }
      });
    });
  }

  private webcamUploadLimit(photos: WebcamImage[]): boolean {
    if (this.actualImages?.length === 3 || this.actualImages?.length + photos?.length > 3) {
      this.snackBar.open('You can upload maximum 3 photos.', 'Close', {
        duration: 10 * 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return true;
    }
    return false;
  }

  buildFileFromDataUrl(dataurl: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const filename = 'photoCapture' + '_' + new Date().getTime() + 1 + '.' + mime.split('/')[1];
    return new File([u8arr], filename, {type: mime});
  }

  isDesktopDevide() {
    const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      return false;
    return true;
  }

  getHelpTextForTextAreaFieldInput(input): string {
    if (input.questionOptions && input.questionOptions.length > 0) {
      return input.questionOptions.join(',');
    } else {
      return 'Enter notes...';
    }
  }
}

function getExifOrientation(file, callback) {
  // Suggestion from http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/:
  if (file.slice) {
    file = file.slice(0, 131072);
  } else if (file.webkitSlice) {
    file = file.webkitSlice(0, 131072);
  }

  const reader = new FileReader();
  reader.onload = function(e) {
    const view = new DataView(e.target.result as ArrayBuffer);
    if (view.getUint16(0, false) != 0xffd8) {
      callback(-2);
      return;
    }
    const length = view.byteLength;
    let offset = 2;
    while (offset < length) {
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          callback(-1);
          return;
        }
        const little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++)
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            callback(view.getUint16(offset + i * 12 + 8, little));
            return;
          }
      } else if ((marker & 0xff00) != 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

function isMobileDevice() {
  const ua = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      return true;
    return false;
}

// Derived from https://stackoverflow.com/a/40867559, cc by-sa
function imgToCanvasWithOrientation(img, rawWidth, rawHeight, orientation) {
  const canvas = document.createElement('canvas');
  if (isMobileDevice()) {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
    return canvas;
  }

  if (orientation > 4) {
    canvas.width = rawHeight;
    canvas.height = rawWidth;
  } else {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
  }

  if (orientation > 1) {
    console.log('EXIF orientation = ' + orientation + ', rotating picture');
  }

  const ctx = canvas.getContext('2d');
  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, rawWidth, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, rawHeight);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, rawHeight, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, rawHeight, rawWidth);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, rawWidth);
      break;
  }
  ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}

function reduceFileSize(
  file,
  acceptFileSize,
  _maxWidth,
  _maxHeight,
  quality,
  callback
) {
  console.log(file);
  if (file.size <= acceptFileSize) {
    callback(file);
    return;
  }
  const img = new Image();
  img.onerror = function() {
    URL.revokeObjectURL(this.src);
    callback(file);
  };
  img.onload = function() {
    URL.revokeObjectURL((this as HTMLImageElement).src);
    getExifOrientation(file, function(orientation) {
      let w = img.width,
        h = img.height;
      const scale = 1;

      h = Math.round(h * scale);
      w = Math.round(w * scale);

      const canvas = imgToCanvasWithOrientation(img, w, h, orientation);
      canvas.toBlob(
        function(blob) {
          console.log(
            'Resized image to ' + w + 'x' + h + ', ' + (blob.size >> 10) + 'kB'
          );
          const compressedFile = new File([blob], file.name);
          callback(compressedFile);
        },
        'image/jpeg',
        quality
      );
    });
  };
  img.src = URL.createObjectURL(file);
}
