/**
 *    Copyright 2016 Sven Loesekann
   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at
	   http://www.apache.org/licenses/LICENSE-2.0
   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
 */
import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject, HostListener, ViewChild } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subscription, Subject } from 'rxjs';
import { User } from '../shared/models/user';
import { LoadingService } from '../core/interceptors/loading-service';
import {
	Router,
	Event,
	NavigationStart,
	NavigationEnd,
	NavigationCancel,
	NavigationError,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, Logger, LogLevel } from '@azure/msal-browser';
import { NotFoundOrAuthorizedService } from '../core/services/not-found-or-authorized.service';
import { ChangeTerritoryDialogComponent } from '../shared/components/change-territory-dialog/change-territory-dialog.component';
import { TerritoryService } from '../core/services/territory-management.service';
import { TitleService } from '../core/services/title.service';
import { UtilsService } from '../core/services/utils.service';
import { CountryGlobalVariables } from '../shared/constants/country-global-variables';
import { UserAuthService } from './../shared/services/user-auth.service';
import { Observable } from 'rxjs-compat';
import { MatDrawer } from '@angular/material/sidenav';
import {AppVersionService} from '../core/services/app-version.service';

const pageTitleRouteMap = {
	'/home': 'MyTeam',
	'/serviced': 'Serviced Stores',
	'/non-serviced': 'Non-Serviced Stores',
	'/associates': CountryGlobalVariables.US.associates,
	'/serviced-details': 'Serviced Stores',
	'/non-serviced-details': 'Non-Serviced Stores',
	'/schedule-audit': 'Schedule Audit',
	'/schedule-training': CountryGlobalVariables.US.trainingSchedule,
	'/schedule-appointment': 'Schedule Event',
	'/audit': 'Start Audit',
	'/training': CountryGlobalVariables.US.trainingStart,
	'/audit-history': 'Audit History',
	'/training-history': CountryGlobalVariables.US.trainingHistory,
	'/admin': 'Admin',
	'/my-events': 'My Events'
};

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	/* Used to close the sidenav when logout */
	@ViewChild('drawer', { static: false }) drawer: MatDrawer;

	private loginSubscription: Subscription;
	private tokenSubscription: Subscription;
	private readonly _destroying$ = new Subject<void>();

	title = 'MyTeam';
	mode = 'side';

	isIframe: boolean;
	loginDisplay: boolean;
	notFoundOrNotAuthorized = false;

	user: User = {
		displayName: '',
		profilePhotoUrl: null,
		groupIDs: []
	};

	isLoading: Observable<boolean> = this.loadingService.isLoading.pipe(delay(0));

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
		this.adaptSidebar();
	}

	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		public loadingService: LoadingService,
		private router: Router,
		private dialog: MatDialog,
		private notFoundOrAuthorizedService: NotFoundOrAuthorizedService,
		private terrService: TerritoryService,
		private titleSvc: TitleService,
		private changeDetector: ChangeDetectorRef,
		private userAuthService: UserAuthService,
    private appVersionService: AppVersionService
	) {
		this.initViewForSafari();
		this.isIframe = window !== window.parent && !window.opener;

		this.titleSvc.titleSubject.subscribe(title => {
			if (title) {
				this.title = title;
				changeDetector.detectChanges();
			}
		});

		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loadingService.show();
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.title = (pageTitleRouteMap[event['url']] || pageTitleRouteMap[String(event['url']).split('?')[0]])
						? (pageTitleRouteMap[event['url']] || pageTitleRouteMap[String(event['url']).split('?')[0]])
						: 'MyTeam';
					this.loadingService.hide();
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	initViewForSafari() {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
	}

	ngOnInit(): void {
		if (!UtilsService.checkBrowserCompatibility()) {
			this.router.navigate(['/incompatible-browser']);
			return;
		}
    this.appVersionService.checkAppVersionChange();

		this.adaptSidebar();

		this.notFoundOrAuthorizedService.isNotFoundOrAuthorized.subscribe((val) => {
			this.setLoginDisplay();
			this.notFoundOrNotAuthorized = val;
		});

		this.isIframe = window !== window.parent && !window.opener;
		this.setLoginDisplay();

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.setLoginDisplay();
			});

		this.loginSubscription = this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
			)
			.subscribe((result: EventMessage) => {
				const payload = result.payload as AuthenticationResult;
				console.log('login success ' + JSON.stringify(payload));

				this.userAuthService.loadAzureUser(payload, true);
				this.user = this.userAuthService.getUserDetails();

				this.setLoginDisplay();
			});

		function loggerCallback(logLevel: LogLevel, message: string) {
			console.log('client logging' + message);
		}

		this.authService.setLogger(
			new Logger(
				{
					loggerCallback,
					logLevel: LogLevel.Info,
					piiLoggingEnabled: true
				}
			)
		);

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
			)
			.subscribe((result: EventMessage) => {
				console.log('Acquire Token Failure callback');
				const payload = result.payload as AuthenticationResult;
				const dialogRef = this.dialog.open(ConfirmDialogComponent, {
					maxWidth: '400px',
					disableClose: true,
					data: {
						title: 'Confirm Action',
						message:
							'An authentication exception occurred. You need to log out of your American Greetings account and then log back in.',
						confirmationRequired: false
					},
				});
				dialogRef.afterClosed().subscribe((dialogResult) => {
					if (dialogResult) {
						this.logout();
						return;
					}
				});
			});

		this.tokenSubscription = this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
			)
			.subscribe((result: EventMessage) => {
				const payload = result.payload as AuthenticationResult;
				console.log('Acquire Token Success callback');

				if (this.user.displayName.length === 0) {
					this.userAuthService.loadAzureUser(payload);
					this.user = this.userAuthService.getUserDetails();

					console.log(this.user);
				}
			}
			);

		if (this.loginDisplay) {
			const accounts = this.authService.instance.getAllAccounts();
			if (accounts.length > 0) {
				this.userAuthService.loadAzureUser(accounts[0]);
			}
		}

		if (this.user.displayName.length === 0) {
			this.user = this.userAuthService.getUserDetails();
			console.log(this.user);
		}

		if (!this.userAuthService.isAuthenticated()) {
			// save the state of the last url
			this.persistRedirectUri();
		}
	}

	persistRedirectUri() {
		let redirectUri = window.location.pathname.replace('/my-team', '');
		let redirectUriParams = window.location.search;

		if (redirectUri === '/landing') {
			redirectUri = redirectUri.replace('/landing', '');
			redirectUriParams = '';
		}

		if (redirectUri !== '/') {
			localStorage.setItem('redirect-uri', redirectUri);
			localStorage.setItem('redirect-uri-params', redirectUriParams);
		}
	}

	setLoginDisplay() {
		this.loginDisplay = this.userAuthService.isAuthenticated();
		if (this.loginDisplay) {
			this.userAuthService.sendClickEvent(this.user);
		}
	}

	logout(popup?: boolean) {
		if (this.drawer.opened) {
			this.drawer.close().finally(() => {
				this.userAuthService.logout(popup);
				this.setLoginDisplay()
			})
		} else {
			this.userAuthService.logout(popup);
		}
	}

	ngOnDestroy() {
		// disconnect from broadcast service on component destroy
		this._destroying$.next(null);
		this._destroying$.complete();
		if (this.loginSubscription) {
			console.log('Unsubscribe Login Subscription');
			this.loginSubscription.unsubscribe();
		}
		if (this.tokenSubscription) {
			console.log('Unsubscribe Token Subscription');
			this.tokenSubscription.unsubscribe();
		}
	}

	private adaptSidebar() {
		if (window.innerWidth <= 991) {
			this.mode = 'over';
		} else {
			this.mode = 'side';
		}
	}

	get innerWidth() {
		return window.innerWidth;
	}

	get territory() {
		return this.terrService.getTerritory();
	}

	changeTerritory() {
		this.dialog.open(ChangeTerritoryDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				terr: this.territory
			}
		});
	}

	hasProfilePhoto(): boolean {
		return this.userAuthService.hasProfilePhoto();
	}

	getProfilePhoto() {
		return this.userAuthService.getProfilePhoto();
	}
}
