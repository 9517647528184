import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {RestErrorResponse} from './models/rest-error-response.model';
import {MonitoringService} from '../services/monitoring.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private injector: Injector,
    private monitoringService: MonitoringService
  ) {}

  handleError(errorResponse: any) {
    const router = this.injector.get(Router);
    console.log('URL: ' + router.url);
    if (errorResponse instanceof HttpErrorResponse) {
      // Backend returns unsuccessful response codes such as 404, 500 etc.
      const error = errorResponse.error as RestErrorResponse;
      if (error?.warningMessages?.length > 0) {
        this.monitoringService.logException(errorResponse, SeverityLevel.Warning);
      }
      if (error?.errorMessages?.length > 0) {
        this.monitoringService.logException(errorResponse, SeverityLevel.Error);
      }
    } else {
      // THIS IS NEEDED FOR FRONTEND EXCEPTION (IN CONSOLE!!)
      // PLEASE DO NOT DELETE THIS
      throw errorResponse;
    }
  }
}
