import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenericFilterObject} from '../../shared/models/generic-filter-object.model';
import {Observable} from 'rxjs';
import {IPagedResponse} from '../../shared/models/IPageResponse';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { Associate } from '../../shared/models/associates/associate.model';
import { AssociateDetails } from '../../shared/models/associates/associate-details.model';
import { LoginService } from './login.service';
import { UtilsService } from './utils.service';
import {MerchandiserGetStream} from "../../shared/models/getstream/merchandiser-get-stream.model";

@Injectable({
  providedIn: 'root'
})
export class AssociateService {

  constructor(private http: HttpClient,
              private loginService: LoginService) { }

  public getAssociates(genericFilterObj: GenericFilterObject, terr: number): Observable<IPagedResponse<Associate>> {
    const country = this.loginService._country;
    let params = null;

    params = new HttpParams();
    if (genericFilterObj.form != null) {
      Object.keys(genericFilterObj.form).map(function (key) {
        if (genericFilterObj.form[key] !== null && genericFilterObj.form[key] !== undefined && genericFilterObj.form[key] !== '') {
          params = params.append(key, genericFilterObj.form[key]);
        }
      });
    }

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size.toString());
      params = params.append('page', genericFilterObj.page.page.toString());

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    return this.http.get<IPagedResponse<Associate>>(
        `${environment.apiResourceUri}/merchandisers/country/${country}/territory/${terr}`, {params: params})
      .pipe(map((res: IPagedResponse<Associate>) => {
        if (res?.size > 0) {
          res?.content?.forEach(associate => {
            if (associate.lastTrain) {
              associate.lastTrain = UtilsService.dateToServerDate(associate.lastTrain);
            }
          });
        }
        return res;
      }));
  }

  public getAssociateDetails(associateId: number): Observable<AssociateDetails> {
    return this.http.get<AssociateDetails>(`${environment.apiResourceUri}/merchandisers/${associateId}/details`)
      .pipe(map((response: Associate) => {
        if (response.lastTrain) {
          response.lastTrain = UtilsService.dateToServerDate(response.lastTrain);
        }
        return response;
      }));
  }

  public getAssociateByRetailerId(retailerId: number): Observable<Associate> {
    return this.http.get<Associate>(
      `${environment.apiResourceUri}/merchandisers/retailer/${retailerId}`)
      .pipe(map((response: Associate) => {
        if (response.lastTrain) {
          response.lastTrain = UtilsService.dateToServerDate(response.lastTrain);
        }
        return response;
      }));
  }

  public getAssociateById(merchandiserId: number): Observable<Associate> {
    return this.http.get<Associate>(`${environment.apiResourceUri}/merchandisers/merchandiser/${merchandiserId}`)
    .pipe(map((response: Associate) => {
        if (response.lastTrain) {
          response.lastTrain = UtilsService.dateToServerDate(response.lastTrain);
        }
        return response;
    }));
  }

  public getAssociateByScheduleId(scheduleId: string): Observable<Associate> {
    return this.http.get<Associate>(`${environment.apiResourceUri}/merchandisers/schedule/${scheduleId}`)
      .pipe(map((response: Associate) => {
        if (response.lastTrain) {
          response.lastTrain = UtilsService.dateToServerDate(response.lastTrain);
        }
        return response;
      }));
  }

  autocompleteUsers(searchBy: string): Observable<MerchandiserGetStream[]> {
    const territory = localStorage.getItem('territory');
    return this.http.get<MerchandiserGetStream[]>(
      `${environment.apiResourceUri}/merchandisers/search/${searchBy}/territory/${territory}`);
  }
}
