import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsgBannerService } from '../../msg-banner/msg-banner.service';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

  // error list
	messageList = [];
	showNotification = false;

  isCameraExist = true;
  isMultipleCameras = false;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  photoLimit: number = 0;
  photos: WebcamImage[] = [];

  constructor(private dialogRef: MatDialogRef<CameraComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private msgBanner: MsgBannerService,
              ) { }

  ngOnInit(): void {
    this.initCamera();
    this.setPhotoLimit();
  }

  initCamera() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.isCameraExist = mediaDevices && mediaDevices.length > 0;
        this.isMultipleCameras = mediaDevices && mediaDevices.length > 1;
      });
  }

  setPhotoLimit() {
    if (this.data?.photoLimit) {
      this.photoLimit = this.data.photoLimit;
    }
  }

  takeSnapshot(): void {
    this.trigger.next();
  }

  changeWebCame(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  handleImage(webcamImage: WebcamImage) {
    this.photos.push(webcamImage);
  }

  submitPhotos() {
    this.dialogRef.close(this.photos);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  deletePhoto(photoToDelete: WebcamImage) {
    this.photos = this.photos.filter(photo => photo != photoToDelete);
  }
}