<div *ngIf="(isLoading | async)" class="loading-indicator">
	<mat-spinner class="pos-center"></mat-spinner>
</div>

<mat-drawer-container class="wrapper">
	<mat-drawer *ngIf="loginDisplay && !notFoundOrNotAuthorized" #drawer class="example-sidenav" [mode]="mode"
		[opened]="innerWidth > 991">
		<div class="sidebar" data-color="blue" data-background-color="white">
			<app-sidebar [drawer]="drawer"></app-sidebar>
			<div class="sidebar-background"></div>
		</div>
	</mat-drawer>

	<mat-toolbar *ngIf="loginDisplay && !notFoundOrNotAuthorized" class="toolbar-color">
		<div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
			<div class="app-link">
				<button mat-button style="margin-right: 10px" (click)="drawer.toggle()">
					<i class="fa fa-bars" style="font-size: 28px"></i>
				</button>
				<a fxHide.xs fxShow.gt-xs mat-button>{{ title }}</a>
				<a fxShow.xs fxHide.gt-xs style="font-size: 20px" mat-button>{{ title }}</a>
			</div>

			<div fxLayout="row" fxLayoutAlign="center">
				<button mat-raised-button style="margin-right: 10px; font-weight: bold" (click)="changeTerritory()">
					<div fxLayout="row" fxLayoutAlign="center">
						<div fxLayout="column" fxLayoutAlign="center">
							<mat-icon>flag</mat-icon>
						</div>
						<div fxLayout="column" fxLayoutAlign="center">
							<span>{{ territory }}</span>
						</div>
					</div>
				</button>

				<div>
					<span fxHide.xs class="delim"></span>
				</div>

				<button fxHide.xs mat-icon-button class="white-icon" [matMenuTriggerFor]="menu"
					*ngIf="!hasProfilePhoto()">
					<mat-icon>account_circle</mat-icon>
				</button>

				<button fxHide.xs mat-icon-button class="white-icon" [matMenuTriggerFor]="menu"
					*ngIf="hasProfilePhoto()">
					<input type="image" [src]="getProfilePhoto()" class="profile-photo" />
				</button>

				<mat-menu #menu="matMenu">
					<button mat-menu-item>
						<span>{{ user.displayName }} </span>
					</button>
				</mat-menu>

				<button fxHide.xs mat-raised-button color="warn" (click)="logout()">
					Logout
				</button>
			</div>
		</div>
	</mat-toolbar>

	<div [ngClass]="{ 'page-view' : loginDisplay && !notFoundOrNotAuthorized }" class="chat-view page-view">
		<router-outlet style="text-align: center"></router-outlet>
	</div>
</mat-drawer-container>