<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="20px">
    <app-msg-banner
      *ngIf="showNotification"
      [msgList]="messageList"
      (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <!-- row with icon + userName + close button -->
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>
          <mat-icon
            *ngIf="associate.newHire"
            matBadge="0"
            matBadgePosition="before"
            class="grey-icon"
            >account_circle</mat-icon
          >
          <mat-icon *ngIf="!associate.newHire" class="grey-icon"
            >account_circle</mat-icon
          >
        </div>

        <div fxLayout="column" fxLayoutGap="5px">
          <div class="title">{{ associate.fullName }}</div>
          <div>{{ associate.userName }}</div>
        </div>
      </div>

      <mat-icon (click)="closeDialog()" class="close-button">close</mat-icon>
    </div>

    <!-- associate's details -->
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
    >
      <div fxLayout="column">
        <span *ngIf="associate.jobTitle"
          > {{ associate.jobTitle }} </span>
        <span *ngIf="associate.lastTrain"
          > {{ isUkUser() ? CountryGlobalVariables.UK.trainingLastOn :
          CountryGlobalVariables.US.trainingLastOn }}: {{ associate.lastTrain | customDate: isUkUser() }}</span>
        <span *ngIf="associate.hireDate"
          > {{ isUkUser() ? CountryGlobalVariables.UK.hireDate :
          CountryGlobalVariables.US.hireDate }}: {{ associate.hireDate | customDate: isUkUser() }}</span
        >
        <span *ngIf="associate.birthday"
          > Birthday: {{ associate.birthday | birthdayPipe: isUkUser() }}</span
        >
      </div>

      <div fxLayout="column">
        <div *ngIf="associate.zones" fxLayout="row" class="limit-zone-width">
          <mat-icon *ngIf="!isUkUser()" class="zones-icon">layers</mat-icon>
          <span>{{ isUkUser() ? CountryGlobalVariables.UK.zoneMerch :
            CountryGlobalVariables.US.zoneMerch }}: </span>
          <span>&nbsp;{{ associate.zones }}</span>
        </div>

        <div fxLayout="column" *ngIf="isUkUser()">
          <span *ngIf="associate.employeeNo != null && associate.employeeNo != undefined">
            Employee Number: {{ associate.employeeNo }}</span>
          <span *ngIf="associate.contractedHours != null && associate.contractedHours != undefined">
            Contracted Hours: {{ associate.contractedHours }}</span>
        </div>
      </div>
    </div>

    <!-- associate's assigned stores -->
    <div
      fxLayout="column"
      fxLayoutAlign="start"
      fxLayoutGap="0px"
      fxLayoutGap.lt-md="0px"
    >
      <mat-card
        fxLayout="row"
        fxLayoutAlign="start"
        class="mat-card-title"
        *ngIf="!showNotification"
      >
        <mat-icon class="store-icon"> store </mat-icon>
        <span> {{ isUkUser() ? CountryGlobalVariables.UK.assignedStores :
          CountryGlobalVariables.US.assignedStores }}: </span>
      </mat-card>

      <!-- store details row -->
      <div *ngIf="!showNotification" class="scollable">
        <mat-card
          *ngIf="this.stores && this.stores.length === 0"
          fxLayout="row"
          fxLayoutAlign="space-between"
          class="mat-card-element no-stores-msg"
        >
          There are no active stores assigned to this user.
        </mat-card>

        <mat-card
          fxLayout="row"
          class="mat-card-element"
          *ngFor="let store of stores"
        >
          <div
            fxFlex
            (click)="onViewStoreDetails(store)">
            {{ store.name }} {{ store.chain }} - {{ store.store }}
            <span *ngIf="store.shipTo"> ({{ store.shipTo }})</span>
            <div *ngIf="isUkUser()">
              <span *ngIf="store.address">{{store.address}}</span>
              <span *ngIf="store.city">, {{store.city}}</span>
              <span *ngIf="store.state">, {{store.state}}</span>
              <span *ngIf="store.postalCode">, {{store.postalCode}}</span>
            </div>
          </div>

          <div>
            <button
              *ngIf="isUkUser()"
              mat-icon-button
              (click)="onViewStoreMetrics(store)">
              <mat-icon>info</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="onViewStoreDetails(store)">
              <mat-icon class="next-page-icon">navigate_next</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
    </div>

    <!-- assigned stores message -->
    <div *ngIf="assignedStoresMessage" fxLayoutAlign="start">
      {{ assignedStoresMessage }}
    </div>

    <!-- training and linking u buttons -->
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="0"
      fxLayoutGap="2%"
      fxLayoutAlign="center"
    >
      <button
        fxFlex="30%"
        mat-button
        class="btn"
        fxLayout="row"
        fxLayoutAlign="center center"
        [matMenuTriggerFor]="trainingMenu"
      >
        <mat-icon>storefront</mat-icon>
        <span>{{ isUkUser() ? CountryGlobalVariables.UK.trainingButton : CountryGlobalVariables.US.trainingButton }}</span>
      </button>

      <mat-menu #trainingMenu="matMenu">
        <button mat-menu-item (click)="onStartTraining()">
          <mat-icon>play_arrow</mat-icon>Start
        </button>

        <button mat-menu-item (click)="onSheduleTraining()" *appUserRole="{excludedRoles: [RolesEnum.TRS]}">
          <mat-icon>date_range</mat-icon>Schedule
        </button>

        <button mat-menu-item (click)="onTrainingHistory()">
          <mat-icon>history</mat-icon>History
        </button>
      </mat-menu>

      <button
        *ngIf="false"
        fxFlex="30%"
        mat-button
        class="btn"
        fxLayout="row"
        fxLayoutAlign="center center"
        (click)="onLinkingU()"
      >
        <mat-icon>question_answer</mat-icon>
        <span>Linking U</span>
      </button>

      <button
        *ngIf="!isUkUser() && isAppropiateRole() && hasChatAccess()"
        fxFlex="30%"
        mat-button
        class="btn"
        fxLayout="row"
        fxLayoutAlign="center center"
        (click)="openChat()"
      >
        <mat-icon>chat</mat-icon>
        <span>Open chat</span>
      </button>
    </div>
  </div>
</div>
