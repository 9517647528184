<div 
    fxFlex 
    fxLayout="column"
    mat-dialog-content>

    <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <div fxLayout="row" fxLayoutAlign="end">   
        <mat-icon (click)="closeDialog()" class="close-button">close</mat-icon>
    </div>

    <div *ngIf="isCameraExist; else noCameraExist">

        <!-- camera -->
        <div fxLayout="column" fxLayoutAlign="center center">
            <webcam 
                *ngIf="photoLimit > photos.length"
                [height]="450" 
                [width]="450" 
                [trigger]="triggerObservable" 
                (imageCapture)="handleImage($event)"
                [switchCamera]="nextWebcamObservable">
            </webcam>
            <!-- actions -->
            <div
                *ngIf="photoLimit > photos.length"
                fxLayout="row"
                fxLayout.lt-sm="column"
                fxLayoutAlign="center"
                fxLayoutAlign.lt-sm="start"
                fxLayoutGap="2%">
                <button
                    class="btn btn-submit btn-camera"
                    fxFlex="140px"
                    fxFlex.lt-sm
                    (click)="takeSnapshot()">Take Photo
                </button>
                <button
                    *ngIf="isMultipleCameras"
                    class="btn btn-submit btn-camera"
                    fxFlex="140px"
                    fxFlex.lt-sm
                    (click)="changeWebCame(true)">Switch Camera
                </button>
                <button 
                    *ngIf="photos.length === 0"
                    class="btn btn-cancel btn-camera" 
                    fxFlex="140px"
                    fxFlex.lt-sm
                    (click)="closeDialog()" >Cancel
                </button>
            </div>
        </div>

        <!-- display photos -->
        <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="5%" class="photo-container">
            <div *ngFor="let photo of photos">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5%">
                    <img [src]="photo.imageAsDataUrl"/>
                    <button
                        class="btn-delete"
                        mat-icon-button
                        (click)="deletePhoto(photo)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- actions -->
        <div
            *ngIf="photos.length > 0"
            fxLayout="row"
            fxLayout.lt-sm="column"
            fxLayoutAlign="center"
            fxLayoutAlign.lt-sm="start"
            fxLayoutGap="2%"
            fxLayoutGap.lt-sm="0">
            <button
                class="btn btn-submit"
                fxFlex="140px"
                fxFlex.lt-sm="100%"
                (click)="submitPhotos()">Submit Photos
            </button>
            <button 
                class="btn btn-cancel" 
                fxFlex="140px"
                fxFlex.lt-sm="100%"
                (click)="closeDialog()">Cancel
            </button>
        </div>
    </div>
    
    <ng-template #noCameraExist>
        Camera device not available.
    </ng-template>
        
</div>