import { UserAuthService } from './../../services/user-auth.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ROUTE } from '../../constants/routes';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../core/services/login.service';
import { Country } from '../../enum/country.enum';
import { CountryGlobalVariables } from '../../constants/country-global-variables';
import { ChatService } from '../../../core/services/chat.service';
import { RolesEnum } from '../../enum/role.enum';

declare const $: any;

declare interface ParentRouteInfo {
	title: string;
	path: string;
	icon: string;
	class: string;
	expanded: Boolean;
	excludedRoles: RolesEnum[];
	childs: RouteInfo[];
}

declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	expanded: Boolean;
	childs: RouteInfo[];
	class: string;
}
export const ROUTES: ParentRouteInfo[] = [
	{
		title: ROUTE.MY_EVENTS.NAME,
		icon: 'calendar_today',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [RolesEnum.TRS],
		childs: [
			{
				path: ROUTE.MY_EVENTS.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	},
	{
		title: ROUTE.ASSOCIATES.NAME,
		icon: 'groups',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [],
		childs: [
			{
				path: ROUTE.ASSOCIATES.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	},
	{
		title: ROUTE.SERVICED_STORES.NAME,
		icon: 'store',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [],
		childs: [
			{
				path: ROUTE.SERVICED_STORES.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	},
	{
		title: ROUTE.NON_SERVICED_STORES.NAME,
		icon: 'store',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [],
		childs: [
			{
				path: ROUTE.NON_SERVICED_STORES.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	},
	{
		title: ROUTE.SCHEDULE_EVENT.NAME,
		icon: 'pending_actions',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [RolesEnum.TRS],
		childs: [
			{
				path: ROUTE.SCHEDULE_EVENT.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	},
	{
		title: ROUTE.CHAT.NAME,
		icon: 'forum',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [],
		childs: [
			{
				path: ROUTE.CHAT.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	},
	{
		title: ROUTE.ADMIN.NAME,
		icon: 'admin_panel_settings',
		path: '',
		class: '',
		expanded: false,
		excludedRoles: [],
		childs: [
			{
				path: ROUTE.ADMIN.PATH,
				title: '',
				icon: '',
				class: '',
				expanded: false,
				childs: []
			}
		]
	}
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Input('drawer') drawer;
	readonly RolesEnum = RolesEnum;

	public permissionsReceived = true;
	
	menuItems: ParentRouteInfo[];
	loggedIn = false;
	panelOpenState = false;
	unreadMessages = false;
	intervalId: any;
	intervalDuration = 30000;

	clickEventSubscription: Subscription;

	constructor(
		public userAuthService: UserAuthService,

		private loginSvc: LoginService,
		private chatService: ChatService
	) {
		this.clickEventSubscription = this.userAuthService
			.getClickEvent()
			.subscribe((response) => {
				this.loggedIn = true;
			});
	}

	ngOnInit() {
		if (this.userAuthService.isAuthenticated()) {
			this.loggedIn = true;
		}
		this.menuItems = ROUTES.filter((menuItem) => menuItem);

		this.loginSvc.isChatUserSubject.subscribe(res => {
			if (res === true) {
				this.initUnreadCount();
			}
		});
	}

	initUnreadCount() {
		this.checkUnreadMessages();
		this.intervalId = setInterval(() => {
			if (document.visibilityState === 'visible') {
				this.checkUnreadMessages();
			}
		}, this.intervalDuration);
	}

	ngOnDestroy() {
		clearInterval(this.intervalId);
	}

	isUkUser() {
		return this.loginSvc._country === Country.UK;
	}

	isAllowedSidebarComponent(title: string) {
		switch (title) {
			case ROUTE.ADMIN.NAME: {
				return this.loginSvc._admin === true;
			}
			case ROUTE.NON_SERVICED_STORES.NAME: {
				return this.isUkUser() === false;
			}
			case ROUTE.CHAT.NAME: {
				return this.isUkUser() === false && this.loginSvc._role !== RolesEnum.IS &&
					this.loginSvc._isChatUser;
			}
			default: {
				return true;
			}
		}
	}

	togglePanel() {
		this.panelOpenState = !this.panelOpenState;
	}

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false;
		}
		return true;
	}

	checkOpened() {
		if (this.drawer.opened === true) {
			(document.querySelector('.main-panel') as HTMLElement).style.cssFloat =
				'right';
			(document.querySelector('.main-panel') as HTMLElement).style.width =
				'calc(100% - 280px)';
		} else {
			(document.querySelector('.main-panel') as HTMLElement).style.cssFloat =
				'left';
			(document.querySelector('.main-panel') as HTMLElement).style.width =
				'100%';
		}
	}

	toggle(item) {
		for (let i = 0; i < ROUTES.length; i++) {
			if (ROUTES[i].title === item.title) {
				ROUTES[i].expanded = !ROUTES[i].expanded;
				continue;
			}
			ROUTES[i].expanded = false;
		}
	}

	toggleChild(item) {
		for (let i = 0; i < ROUTES.length; i++) {
			for (let j = 0; j < ROUTES[i].childs.length; j++) {
				if (ROUTES[i].childs[j] === item) {
					ROUTES[i].childs[j].expanded = !ROUTES[i].childs[j].expanded;
					continue;
				}
				ROUTES[i].childs[j].expanded = false;
			}
		}
	}

	logout(popup?: boolean) {
		this.userAuthService.logout(popup);
	}

	goToPowerBI() {
		window.open(environment.powerBiUrl);
	}

	getMyTeamLogo() {
		if (this.isUkUser()) {
			this.menuItems[1].title = CountryGlobalVariables.UK.associates;
			return CountryGlobalVariables.UK.APP_LOGO;
		}
		return CountryGlobalVariables.US.APP_LOGO;
	}

	checkUnreadMessages() {
		this.chatService.countUnreadMessages().subscribe((val) => {
			this.unreadMessages = val.count > 0;
		});
	}

  getAppVersion() {
    return localStorage.getItem('appVersion');
  }
}
