import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScheduleType } from '../../../shared/models/schedule/schedule.model';
import { StoresService } from '../../../core/services/stores.service';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { StoreDetails } from '../../../shared/models/stores/store-details.model';
import {LoginService} from '../../../core/services/login.service';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: [
    './audit-history.component.scss',
    '../../../shared/components/generic-view/generic-view.component.scss',
  ],
})
export class AuditHistoryComponent implements OnInit {
  storeId: number;
  page: number;
  scheduleType = ScheduleType.AUDIT;
  storeDetails: StoreDetails;

  // error list
  messageList = [];
  showNotification = false;

  checkboxValue: number = 2;
  complete = false;
  incomplete = false;

  checkboxOptions = [
    { value: 0, name: 'Incomplete', isChecked: this.incomplete },
    { value: 1, name: 'Complete', isChecked: this.complete },
  ];

  constructor(
    private route: ActivatedRoute,
    private storesService: StoresService,
    private msgBanner: MsgBannerService,
    private loginSvc: LoginService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.storeId = params.storeId;
      this.page = Number(params.page);
    });

    this.getServiceDetails();
  }

  isUkUser(): boolean {
    return this.loginSvc.isUkUser();
  }

  getServiceDetails() {
    this.showNotification = false;
    this.storesService.storeDetails(this.storeId).subscribe(
      (response) => {
        this.storeDetails = response;
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(
            this.messageList,
            'An error has occurred. Please contact your administrator!'
          );
        }
        this.showNotification = true;
      }
    );
  }

  valueChange(checkboxValue, $event) {
    this.complete = (checkboxValue == 0 && $event.checked) ? true : false;
    this.incomplete = (checkboxValue == 1 && $event.checked) ? true : false;

    if (!this.complete && !this.incomplete) {
      this.checkboxValue = 2;
    } else {
      this.checkboxValue = checkboxValue;
    }
  }

  changeCheck(checkboxValue) {
    switch (checkboxValue) {
      case 0:
        return this.complete;
      case 1:
        return this.incomplete;
      default:
        return true;
    }
  }

  displayError(event: string) {
    if(event == null) {
      this.messageList = [];
      this.showNotification = false;
    }
    else {
      this.msgBanner.addMsgWarning(this.messageList, event);
      this.showNotification = true;
    }
  }
}
