import { StoreDetails } from '../stores/store-details.model';
import {PurposeAction} from './purpose.model';

export class ScheduleModel {
    id: string;
    merchandiserId: number;
    areaSupervisorId: number;
    retailerId: number;
    retailer?: StoreDetails;
    scheduledDate: Date;
    scheduledOutlookDate: Date;
    isAllDayEvent: boolean;
    appointmentName?: string;
    appointmentNotes?: string;
    scheduleType: string;
    completedStatus: number;
    territoryNumber: number;
    purposeActions: PurposeAction[];
}

export enum ScheduleType {
    AUDIT = 'Audit',
    TRAINING = 'Training',
    TRAINING_UK = 'WorkWith',
    APPOINTMENT = 'Appointment'
}
