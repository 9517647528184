import { Component, OnInit } from '@angular/core';
import { NotFoundOrAuthorizedService } from '../../../core/services/not-found-or-authorized.service';

@Component({
  selector: 'app-incompatible-browser',
  templateUrl: './incompatible-browser.component.html',
  styleUrls: ['./incompatible-browser.component.scss']
})
export class IncompatibleBrowserComponent implements OnInit {

  constructor(private notFoundOrAuthorizedService: NotFoundOrAuthorizedService) { 
    this.notFoundOrAuthorizedService.hide();
  }

  ngOnInit(): void { }
}
