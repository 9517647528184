import { Component, OnInit } from '@angular/core';
import {QuestionsEnum} from '../../../shared/enum/questions.enum';
import {StoreDetails} from '../../../shared/models/stores/store-details.model';
import {Associate} from '../../../shared/models/associates/associate.model';
import {Observable} from 'rxjs';
import {FormSection} from '../../../shared/models/training-audit/form-section.model';
import {FormControlService} from '../../../core/services/form-control.service';
import {Router} from '@angular/router';
import {TerritoryService} from '../../../core/services/territory-management.service';
import {StoresService} from '../../../core/services/stores.service';
import {PagesEnum} from '../../../shared/models/stores/pages.model';
import {TrainingAudit} from '../../../shared/models/training-audit/training-audit.model';
import {TrainingAuditService} from '../../../core/services/training-audit.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {

  stores: Observable<StoreDetails[]>;
  store: StoreDetails;
  associate: Associate;
  trainingAudit: Observable<TrainingAudit>;

  sections: Observable<FormSection[]>;

  constructor(private terrService: TerritoryService,
              private storesService: StoresService,
              private trainingAuditService: TrainingAuditService,
              private formService: FormControlService,
              private router: Router) { }

  ngOnInit(): void {
    if (!history.state.data) {
      this.goToMyEventsPage();
      return;
    }
    const store = history.state.data;
    this.store = store.store;
    if (store.page === PagesEnum.SERVICED_STORES) {
      this.sections = this.formService.findQuestionsByType(QuestionsEnum.AUDIT);
    } else {
      this.sections = this.formService.findQuestionsByType(QuestionsEnum.AUDIT_CSV);
    }

    this.loadStores(store.page);
    if (this.stateDate.scheduleId) {
      this.findTrainingAuditById(this.stateDate.scheduleId);
    }
  }

  findTrainingAuditById(scheduleId: string) {
    this.trainingAudit = this.trainingAuditService.findTrainingAuditsByScheduleId(scheduleId);
  }

  get stateDate() {
    return history.state.data;
  }

  loadStores(page) {
    const terr = this.terrService.getTerritory();

    if (page === PagesEnum.SERVICED_STORES) {
      this.stores = this.storesService.findServicedStoresByTerritory(terr);
    } else {
      this.stores = this.storesService.findNonServicedStoresByTerritory(terr);
    }
  }

  goToMyEventsPage() {
		this.router.navigate(['/my-events']);
	}
}
