<div>
    <app-msg-banner
      *ngIf="showNotification"
      [msgList]="messageList"
      (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <app-generic-view
      [config]="buildConfig()"
      [sort]="[]"
      [filterForm]="filterForm"
      [sortHeaders]="sortHeaders"
      [parentSubject]="dataSubject"
      [length]="length"
      (filterEmitter)="loadData($event)"
      (viewDetailsEmitter)="viewAssociateDetails($event)"
    ></app-generic-view>
</div>

