import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Associate } from '../../../shared/models/associates/associate.model';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { GENERIC_VIEW_CONTENTS, SortDefinition } from '../../../shared/constants/generic-view.constants';
import { Subject } from 'rxjs';
import { GenericFilterObject, Page } from '../../../shared/models/generic-filter-object.model';
import { PagesEnum } from '../../../shared/models/stores/pages.model';
import { AssociateService } from '../../../core/services/associates.service';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { IPagedResponse } from '../../../shared/models/IPageResponse';
import { MatDialog } from '@angular/material/dialog';
import { AssociateDetailsComponent } from '../associate-details/associate-details.component';
import { AssociateDetailsPageRedirectService } from '../../../core/services/previous-page-redirect.service';
import {LoginService} from '../../../core/services/login.service';
import {TitleService} from '../../../core/services/title.service';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';

@Component({
	selector: 'app-associates',
	templateUrl: './associates.component.html',
	styleUrls: ['./associates.component.scss']
})
export class AssociatesComponent implements OnInit {

	filterForm: UntypedFormGroup;
	sortHeaders: SortDefinition[];

	// error list
	messageList = [];
	showNotification = false;

	genericFilterObj: GenericFilterObject;
	data: Associate[];
	length = 0;

	dataSubject: Subject<Associate[]> = new Subject();

	constructor(private fb: UntypedFormBuilder,
				private msgBanner: MsgBannerService,
				private associateService: AssociateService,
				private terrService: TerritoryService,
				private dialog: MatDialog,
				private loginSvc: LoginService,
				private titleSvc: TitleService,
				private redirectToAssociatesPageService : AssociateDetailsPageRedirectService) {
		this.buildForm();
		this.sortHeaders = JSON.parse(JSON.stringify(GENERIC_VIEW_CONTENTS.ASSOCIATES.SORT));
	}

	ngOnInit(): void {
    if (this.loginSvc.isUkUser()) {
      this.titleSvc.updateTitle(CountryGlobalVariables.UK.associates);

      const idx = this.sortHeaders.findIndex(s => s.colDef === 'lastTrain');
      if (idx !== -1) {
        this.sortHeaders[idx].colName = CountryGlobalVariables.UK.trainingDate;
      }
    } else {
      this.titleSvc.updateTitle(CountryGlobalVariables.US.associates);
    }

		const sort: string[] = this.getSortDetails();
		const genericFilter: GenericFilterObject = {
			form: this.filterForm?.value,
			page: new Page(0, 50, sort)
		  };

		if (this.redirectToAssociatesPageService.isRedirectToAssociateDetails()) {
			this.loadDataAfterRedirect();
		} else {
			this.loadData(genericFilter);
		}
	}

	notifyChild(data: Associate[]) {
		this.dataSubject.next(data);
	}

	buildForm() {
		this.filterForm = this.fb.group({
			name: []
		});
	}

	loadData(genericFilterObj: GenericFilterObject) {
		this.genericFilterObj = genericFilterObj;
		this.showNotification = false;
		this.associateService.getAssociates(genericFilterObj, this.terrService.getTerritory()).subscribe(
			(response: IPagedResponse<Associate>) => {
				this.data = response.content;
				this.length = response.totalElements;
				this.notifyChild(this.data);
			},
			(error) => {
				this.notifyChild([]);
        		this.showNotification = true;
        		this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
			}
		);
	}

	loadDataAfterRedirect() {
		this.redirectToAssociatesPageService.updatePageState();
		this.loadData(this.redirectToAssociatesPageService.getFilterObj());
		this.loadAssociateAndOpenDetailsPopup(this.redirectToAssociatesPageService.associateId);

		this.redirectToAssociatesPageService.resetRedirectToAssociateDetails();
	}

	loadAssociateAndOpenDetailsPopup(associateId: number) {
		if (associateId == null)
			return;

		this.associateService.getAssociateById(associateId).subscribe(
			(response: Associate) => {
				this.viewAssociateDetails(response);
			},
			(error) => {
				return;
			}
		);
	}

	viewAssociateDetails(data: Associate) {
		const dialogRef = this.dialog.open(AssociateDetailsComponent, {
      		panelClass: 'mobile-dialog',
			disableClose: true,
			data: data,
		});

		dialogRef.afterClosed().subscribe((savePageState) => {
			if (savePageState) {
				this.redirectToAssociatesPageService.saveAssociatesPageState(this.genericFilterObj, data.id);
			}
		});
	}

	buildConfig() {
		return {
			page: PagesEnum.ASSOCIATES,
			searchText: this.loginSvc.isUkUser() === true ? 
							CountryGlobalVariables.UK.associatesSearchText :
							CountryGlobalVariables.US.associatesSearchText,
		}
	}

	getSortDetails(): string[] {
		const sort = [];
		this.sortHeaders.forEach((option) => {
		  if (option.sortType !== '-') {
			sort.push(option.colDef + ',' + option.sortType);
		  }
		});

		return sort;
	}
}
