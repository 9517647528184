<mat-card
  class="no-padding end-padding mat-elevation-z0"
  [formGroup]="filterForm"
>
  <mat-form-field
    class="search-padding"
    appearance="standard"
  >
    <mat-label> {{config.searchText}} </mat-label>
    <input
      matInput
      type="text"
      formControlName="name"
      (keyup.enter)="onSearch()"
    >
    <button
      matSuffix
      mat-icon-button
      aria-label="Search"
      (click)="onSearch()"
    >
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="10px"
    >

    <div
      class="search-padding"
      fxLayout="row"
    >
      <div
        fxFlex="50px"
        fxLayout="row"
        fxLayoutAlign="start center"
      >Sort by:
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="row wrap"
        fxLayoutAlign.lt-md="start"
        class="border-holder"
      >
        <button
          mat-button
          (click)="switchSortHeader(sort.colDef)"
          *ngFor="let sort of sortHeaders"
        >
          {{ sortName(sort.colDef) }}

          <mat-icon
            *ngIf="sortHeaderMap.get(sort.colDef).localeCompare('-') === 0"
          >horizontal_rule
          </mat-icon>
          <mat-icon
            *ngIf="sortHeaderMap.get(sort.colDef).localeCompare('asc') === 0"
          >arrow_upward
          </mat-icon>
          <mat-icon
            *ngIf="sortHeaderMap.get(sort.colDef).localeCompare('desc') === 0"
          >arrow_downward
          </mat-icon>
        </button>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign.lt-md="end" >
      <button
        mat-button
        class="btn-cancel btn-clear-sort"
        (click)="onClearSort()"> Clear Sort </button>
    </div >
  </div>
</mat-card>

<app-generic-ghost-view
  *ngIf="reload"
></app-generic-ghost-view>

<mat-card
  [hidden]="reload"
  class="padding-10 mat-elevation-z0"
  fxLayout="row"
  *ngFor="let dat of data"
  [ngSwitch]="config.page"
  (click)="goToDetailView(dat)"
>
  <div
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div
      class="header"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <mat-icon
        class="grey-icon"
      >
        <span *ngSwitchCase="pageType.SERVICED_STORES">store</span>
        <span *ngSwitchCase="pageType.NON_SERVICED_STORES">store</span>
        <div *ngIf="dat.newHire">
          <span
            matBadge="0"
            matBadgePosition="before"
            *ngSwitchCase="pageType.ASSOCIATES"
          >account_circle</span>
        </div>
        <div *ngIf="!dat.newHire">
          <span *ngSwitchCase="pageType.ASSOCIATES">account_circle</span>
        </div>
      </mat-icon>

      <mat-icon
        class="top15"
        *ngIf="dat.displayTop15"
      >arrow_upward</mat-icon>

      <div>
        <span *ngSwitchCase="pageType.SERVICED_STORES">
          {{ dat.name }} - {{ dat.chain }}-{{ dat.store }} 
          <span *ngIf="dat.shipTo">({{ dat.shipTo }})</span>
          <span *ngIf="isUkUser() && dat.address"> - {{dat.address}} </span>
          <span *ngIf="isUkUser() && dat.city">, {{dat.city}} </span>
          <span *ngIf="isUkUser() && dat.postalCode">, {{dat.postalCode}} </span>
        </span>
        <span *ngSwitchCase="pageType.NON_SERVICED_STORES">
          {{ dat.name }} - {{ dat.chain }}-{{ dat.store }} 
          <span *ngIf="dat.shipTo">({{ dat.shipTo }})</span>
          <span *ngIf="isUkUser() && dat.address"> - {{dat.address}} </span>
          <span *ngIf="isUkUser() && dat.city">, {{dat.city}} </span>
          <span *ngIf="isUkUser() && dat.postalCode">, {{dat.postalCode}} </span>
        </span>
        <span *ngSwitchCase="pageType.ASSOCIATES">
          {{ dat.fullName }} </span>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="30px"
    >
      <div *ngIf="dat.jobTitle"> {{ dat.jobTitle }} </div>

      <div>
        <span *ngSwitchCase="pageType.SERVICED_STORES">
          Last audit date: {{ dat.lastCall | customDate: isUkUser() }}</span>
        <span *ngSwitchCase="pageType.NON_SERVICED_STORES">
          Last audit date: {{ dat.lastCall | customDate: isUkUser() }}</span>
        <span *ngSwitchCase="pageType.ASSOCIATES">
          {{ isUkUser() ? CountryGlobalVariables.UK.trainingLastOn :
          CountryGlobalVariables.US.trainingLastOn }}: {{ dat.lastTrain | customDate: isUkUser() }} </span>
      </div>

      <div
        *ngIf="dat.zone != null || dat.zones != null"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <mat-icon *ngIf="!isUkUser()">layers</mat-icon>
        <span *ngIf="isUkUser()">Zone</span>
        <div>
          <span *ngSwitchCase="pageType.SERVICED_STORES">
            &nbsp;{{ dat.zone != null ? dat.zone : '-' }}</span>
          <span *ngSwitchCase="pageType.NON_SERVICED_STORES">
            &nbsp;{{ dat.zone != null ? dat.zone : '-' }}</span>
          <span *ngSwitchCase="pageType.ASSOCIATES">
            &nbsp;{{ dat.zones != null ? dat.zones : '-' }} </span>
        </div>
      </div>

      <div
        *ngIf="dat.route != null"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <mat-icon *ngIf="!isUkUser()">route</mat-icon>
        <span *ngIf="isUkUser()">Route</span>
        <div>
          <span *ngSwitchCase="pageType.SERVICED_STORES">
            &nbsp;{{ dat.route != null ? dat.route : '-' }}</span>
          <span *ngSwitchCase="pageType.NON_SERVICED_STORES">
            &nbsp;{{ dat.route != null ? dat.route : '-' }}</span>
        </div>
      </div>

      <div
        *ngIf="dat.merchandiser"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <mat-icon>account_circle</mat-icon>
        <div>&nbsp;{{ dat.merchandiser }}</div>
      </div>
    </div>
  </div>

  <div
    fxFlex
    fxLayoutAlign="end center"
  >
    <button
      mat-icon-button
    >
      <mat-icon
        class="next-page-icon"
      >navigate_next</mat-icon>
    </button>
  </div>
</mat-card>

<mat-paginator
  [hidden]="data?.length === 0"
  [length]="length"
  [pageSizeOptions]="[ 10, 20, 50, 100, 250 ]"
  [pageSize]="config.page === pageType.ASSOCIATES ? 50 : 10"
  (page)="onPageChange($event)"
></mat-paginator>

