import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return '../../....';
    }

    if (args) {
      return super.transform(value, 'dd/MM/YYYY');
    }

    return super.transform(value, 'MM/dd/YYYY');
  }

}
