<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="20px">
    <app-msg-banner
      *ngIf="showNotification"
      [msgList]="messageList"
      (msgListChange)="messageList = $event"
    >
    </app-msg-banner>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title">{{ action }} User</div>

      <div>
        <mat-icon (click)="closeDialog()" class="close-button">close </mat-icon>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="20px">
      <form
        fxFlex.gt-sm="100%"
        [formGroup]="form"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
      >
        <mat-form-field hintLabel="Max 40 characters" appearance="standard" fxFlex.gt-sm="100%">
          <input
            type="text"
            formControlName="fullNam"
            matInput
            #input
            maxlength="40"
            placeholder="Enter User Name *"
            id="fullNam"
            [matAutocomplete]="auto"
            required
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let user of filteredUsers"
              [value]="user.givenName + ' ' + user.surname"
              (onSelectionChange)="saveFullName(user)"
            >
              {{ user.displayName }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint align="end">{{ input.value?.length || 0 }}/40</mat-hint>
          <mat-error
            *ngIf="
              form.controls.fullNam.errors?.required &&
              form.controls.fullNam.touched
            "
            >User name is required.</mat-error
          >
        </mat-form-field>

        <mat-form-field hintLabel="Max 40 characters" appearance="standard" fxFlex.gt-sm="100%">
          <input
            type="text"
            formControlName="usrNam"
            matInput
            #input
            maxlength="40"
            placeholder="Enter User Id *"
            id="usrNam"
            [matAutocomplete]="auto_eid"
            required
          />
          <mat-autocomplete autoActiveFirstOption #auto_eid="matAutocomplete" fxFlex.gt-sm="100%">
            <mat-option
              *ngFor="let user of filteredUsers"
              [value]="user.onPremisesSamAccountName"
              (onSelectionChange)="saveFullName(user)"
            >
              {{ user.displayName }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint align="end">{{ input.value?.length || 0 }}/40</mat-hint>
          <mat-error
            *ngIf="
              form.controls.usrNam.errors?.required &&
              form.controls.usrNam.touched
            "
            >User ID is required.</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex.gt-sm="100%">
          <mat-label>Select Role</mat-label>
          <mat-select [compareWith]="compareFunction" formControlName="usrRole" required>
            <mat-option *ngFor="let role of roles" [value]="role.value">
              {{ role?.description }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              form.controls.usrRole.errors?.required &&
              form.controls.usrRole.touched
            "
            >Role is required.</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="action === 'Update'" fxFlex.gt-sm="100%">
          <mat-label>Select Territory</mat-label>
          <mat-select
            formControlName="terrs"
            placeholder="Territories"
            [multiple]="true"
            #territoryMultiSelect
          >
            <mat-option>
              <ngx-mat-select-search
                [showToggleAllCheckbox]="true"
                (toggleAll)="toggleTerrsSelectAll($event)"
                [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                [toogleAllCheckboxTooltipPosition]="'above'"
                [formControl]="territoryMultiFilterCtrl"
                placeholderLabel="Find territory..."
                noEntriesFoundLabel="'No matching territory found'"
              >
                <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let territory of filteredTerritoriesMulti | async"
              [value]="territory"
            >
              {{ territory }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!isUkUser()" appearance="standard" fxFlex.gt-sm="100%">
          <mat-label>Select Country</mat-label>
          <mat-select
            formControlName="country"
            placeholder="Countrie"
            [required]="!isUkUser()"
          >
            <mat-option 
              *ngFor="let country of countries" 
              [value]="country"> {{country}} </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
        fxLayoutGap.lt-md="0"
      >
        <button
          fxFlex.gt-sm="25%"
          id="submit"
          class="btn btn-submit"
          (click)="onSubmit()"
          [disabled]="validateForm()"
        >
          {{ action }}
        </button>
        <button id="cancel" class="btn btn-cancel" (click)="onCancel()" fxFlex.gt-sm="25%">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
