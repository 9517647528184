<div *ngIf="permissionsReceived && (this.userAuthService.loginComplete$ | async) as loginComplete">
	<div class="logo">
		<a href="" class="simple-text">
			<div class="logo-img">
				<img [ngClass]="{ 'uk-img' : isUkUser() }" [src]="getMyTeamLogo()" />
			</div>
		</a>
	</div>
	<div class="sidebar-wrapper" fxLayout="column" fxLayoutAlign="space-between">
		<mat-card class="login" *ngIf="!loggedIn">
			<b>Please log in to access the application.</b>
		</mat-card>
		<mat-accordion class="example-headers-align" multi *ngIf="loggedIn">
			<div *ngFor="let menuItem of menuItems">
				<div *appUserRole="{'excludedRoles': menuItem.excludedRoles}">
					<mat-expansion-panel *ngIf="menuItem.childs.length > 1 && isAllowedSidebarComponent(menuItem.title)"
						[expanded]="menuItem.expanded">
						<mat-expansion-panel-header (click)="toggle(menuItem)">
							<mat-panel-title class="mat-panel-title">
								<mat-icon>{{ menuItem.icon }}</mat-icon>
								<p class="mat-panel-title-text">{{ menuItem.title }}</p>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<ul class="nav">
							<div *ngFor="let item of menuItem.childs">
								<mat-expansion-panel *ngIf="item.childs.length > 0" class="{{ item.class }}"
									[expanded]="item.expanded">
									<mat-expansion-panel-header (click)="toggleChild(item)">
										<mat-panel-title class="mat-panel-title">
											<mat-icon>{{ menuItem.icon }}</mat-icon>
											<p class="mat-panel-title-text">{{ item.title }}</p>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<li routerLinkActive="active" *ngFor="let childItem of item.childs"
										class="{{ childItem.class }} nav-item">

										<a class="nav-link" [routerLink]="[childItem.path]" (click)="drawer.toggle()">
											<i class="material-icons">{{ childItem.icon }}</i>
											<p>{{ childItem.title }}</p>
										</a>
									</li>
								</mat-expansion-panel>
								<li *ngIf="item.childs.length == '0'" routerLinkActive="active"
									class="{{ menuItem.class }} nav-item">

									<a class="nav-link" [routerLink]="[item.path]" (click)="drawer.toggle()">
										<i class="material-icons">{{ item.icon }}</i>
										<p class="mat-panel-title-text">{{ item.title }}</p>
									</a>
								</li>
							</div>
						</ul>
					</mat-expansion-panel>
					<ul class="nav" *ngIf="menuItem.childs.length == 1 && isAllowedSidebarComponent(menuItem.title)">
						<li routerLinkActive="active" *ngFor="let item of menuItem.childs"
							class="{{ menuItem.class }} nav-item">
							<a class="nav-link" [routerLink]="[item.path]" (click)="drawer.toggle()">
								<i *ngIf="menuItem.icon === 'forum'" matBadge="1" matBadgeSize="small"
									[matBadgeHidden]="!this.unreadMessages" class="material-icons badge-icon">{{
									menuItem.icon }}</i>
								<i *ngIf="menuItem.icon !== 'forum'" class="material-icons">{{ menuItem.icon }}</i>

								<p>{{ menuItem.title }}</p>
							</a>
						</li>
					</ul>

				</div>
			</div>
		</mat-accordion>

    <div>
      <ul class="nav" *appUserRole="{'excludedRoles': [RolesEnum.TRS]}">
        <li *ngIf="!isUkUser()" class="nav-item">
          <a class="nav-link" style="cursor: pointer;" (click)="goToPowerBI()">
            <i class="material-icons">bar_chart</i>
            <p>Field Management Reporting</p>
          </a>
        </li>
      </ul>
      <ul class="nav">
        <li fxShow.xs fxHide.gt-xs class="nav-item">
          <a class="nav-link" style="cursor: pointer;" (click)="logout()">
            <i class="material-icons">logout</i>
            <p>Logout</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link app-version">
            <p>App Version: {{ getAppVersion() }}</p>
          </a>
        </li>
      </ul>
    </div>
	</div>
</div>
