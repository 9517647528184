import { TopicTypeEnum } from '../enum/topic-type.enum';

export const InfoConstantsUS = [
	{
		NAME: 'Safety',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [
			{
				SUBTITLE: 'Items to review:'
			},
			{
				SUBTITLE: 'Managers should review the following during site visits. The review should be conducted in new stores and, at a minimum, semi-annually in existing stores. Address deficiencies with store management.',
				ARRAY_LIST: [
					{
						Q: 'System for alerting store personnel of potential workplace violence situation.'
					},
					{
						Q: 'History of workplace violence incidents not involving the employee.'
					},
					{
						Q: 'Inadequate exterior and interior lighting, including storage areas.'
					},
					{
						Q: 'Blocked exit routes.'
					},
					{
						Q: 'Escape routes or locations to shelter in place.'
					},
				]
			},
			{
				SUBTITLE: 'Preparation:',
				ARRAY_LIST: [
					{
						Q: 'Are proper shoes and attire being worn?'
					},
					{
						Q: 'Does the associate have and actively use an AG issued box cutter?'
					},
				]
			},
			{
				SUBTITLE: 'Department:',
				ARRAY_LIST: [
					{
						Q: 'Is the work area free of clutter? No trash on the floor and aisle is clear of product and fixtures.'
					},
					{
						Q: 'Are all fixtures properly and safely installed?'
					},
					{
						Q: 'Is under cabinet storage properly organized and maintained? Cabinets should not be overloaded and able to close completely.'
					},
				]
			},
			{
				SUBTITLE: 'Carts:',
				ARRAY_LIST: [
					{
						Q: 'Are carts in working condition, without faulty wheels or broken handles?'
					},
					{
						Q: 'Carts should not be overloaded; associate should always be able to clearly see over the cart.'
					},
					{
						Q: 'If using a Rocket Cart(s), is the upper shelf latched in position?'
					},
				]
			},
			{
				SUBTITLE: 'Stepstools/ladders (US Only):',
				ARRAY_LIST: [
					{
						Q: 'If needed, was a stepstool/ladder available?'
					},
					{
						Q: 'Was a proper stepstool/ladder being used as needed to access product?',
						SUBLIST: [
							'If used, did the associate always have three points of contact? (two hands & one foot or two feet & one hand).'
						]
					},
					{
						Q: 'Ensure stepstool/ladders are no higher than three steps.'
					},
				]
			},
			{
				SUBTITLE: 'Lifting:',
				ARRAY_LIST: [
					{
						Q: 'Did the associate avoid bending at the waist and use their legs to lift items?'
					},
				]
			},
			{
				SUBTITLE: 'Backroom/bin space:',
				ARRAY_LIST: [
					{
						Q: 'Is product in the backroom accessible?',
						SUBLIST: [
							'If not, were the items blocking the space able to be easily moved?'
						]
					},
					{
						Q: 'If product is stored overhead, is it able to be safely accessed?'
					},
					{
						Q: 'Are there any hazardous situations that would cause slips, trips or falls?'
					},
					{
						Q: 'Was the compactor easily accessible and properly maintained for safe use? (US Only)'
					},
					{
						Q: 'If any unsafe scenarios were identified, were they addressed with store management?'
					},
				]
			}]
	},
	{
		NAME: 'Safety',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [
			{
				SUBTITLE: 'US Managers should review the following during store visits. The review should be conducted in new stores and, at a minimum, semi-annually in existing stores. Address deficiencies with store management.',
				ARRAY_LIST: [
					{
						Q: 'System for alerting store personnel of potential workplace violence situation.'
					},
					{
						Q: 'History of workplace violence incidents not involving the employee.'
					},
					{
						Q: 'Inadequate exterior and interior lighting, including storage areas.'
					},
					{
						Q: 'Blocked exit routes.'
					},
					{
						Q: 'Escape routes or locations to shelter in place.'
					},
				]
			},
			{
				SUBTITLE: 'Preparation:',
				ARRAY_LIST: [
					{
						Q: 'Are proper shoes and attire being worn?'
					},
					{
						Q: 'Does the associate have and actively use an AG issued box cutter?'
					},
				]
			},
			{
				SUBTITLE: 'Department:',
				ARRAY_LIST: [
					{
						Q: 'Is the work area free of clutter? No trash on the floor and aisle is clear of product and fixtures.'
					},
					{
						Q: 'Are all fixtures properly and safely installed?'
					},
					{
						Q: 'Is under cabinet storage properly organized and maintained? Cabinets should not be overloaded and able to close completely.'
					},
				]
			},
			{
				SUBTITLE: 'Carts:',
				ARRAY_LIST: [
					{
						Q: 'Are carts in working condition, without faulty wheels or broken handles?'
					},
					{
						Q: 'Carts should not be overloaded; associate should always be able to clearly see over the cart.'
					},
					{
						Q: 'If using a Rocket Cart(s), is the upper shelf latched in position?'
					},
				]
			},
			{
				SUBTITLE: 'Stepstools/ladders (US Only):',
				ARRAY_LIST: [
					{
						Q: 'If needed, was a stepstool/ladder available?'
					},
					{
						Q: 'Was a proper stepstool/ladder being used as needed to access product?',
						SUBLIST: [
							'If used, did the associate always have three points of contact? (two hands & one foot or two feet & one hand).'
						]
					},
					{
						Q: 'Ensure stepstool/ladders are no higher than three steps.'
					},
				]
			},
			{
				SUBTITLE: 'Lifting:',
				ARRAY_LIST: [
					{
						Q: 'Did the associate avoid bending at the waist and use their legs to lift items?'
					},
				]
			},
			{
				SUBTITLE: 'Backroom/bin space:',
				ARRAY_LIST: [
					{
						Q: 'Is product in the backroom accessible?',
						SUBLIST: [
							'If not, were the items blocking the space able to be easily moved?'
						]
					},
					{
						Q: 'If product is stored overhead, is it able to be safely accessed?'
					},
					{
						Q: 'Are there any hazardous situations that would cause slips, trips or falls?'
					},
					{
						Q: 'Was the compactor easily accessible and properly maintained for safe use? (US Only)'
					},
					{
						Q: 'If any unsafe scenarios were identified, were they addressed with store management?'
					},
				]
			}]
	},
	{
		NAME: 'Safety',
		TYPE: TopicTypeEnum.AUDIT_CSV,
		INFO: [
			{
				SUBTITLE: 'Items to review:'
			},
			{
				SUBTITLE: 'Managers should review the following during site visits. The review should be conducted in new stores and, at a minimum, semi-annually in existing stores. Address deficiencies with store management.',
				ARRAY_LIST: [
					{
						Q: 'System for alerting store personnel of potential workplace violence situation.'
					},
					{
						Q: 'History of workplace violence incidents not involving the employee.'
					},
					{
						Q: 'Inadequate exterior and interior lighting, including storage areas.'
					},
					{
						Q: 'Blocked exit routes.'
					},
					{
						Q: 'Escape routes or locations to shelter in place.'
					},
				]
			},
			{
				SUBTITLE: 'Preparation:',
				ARRAY_LIST: [
					{
						Q: 'Are proper shoes and attire being worn?'
					},
					{
						Q: 'Does the associate have and actively use an AG issued box cutter?'
					},
				]
			},
			{
				SUBTITLE: 'Department:',
				ARRAY_LIST: [
					{
						Q: 'Is the work area free of clutter? No trash on the floor and aisle is clear of product and fixtures.'
					},
					{
						Q: 'Are all fixtures properly and safely installed?'
					},
					{
						Q: 'Is under cabinet storage properly organized and maintained? Cabinets should not be overloaded and able to close completely.'
					},
				]
			},
			{
				SUBTITLE: 'Carts:',
				ARRAY_LIST: [
					{
						Q: 'Are carts in working condition, without faulty wheels or broken handles?'
					},
					{
						Q: 'Carts should not be overloaded; associate should always be able to clearly see over the cart.'
					},
					{
						Q: 'If using a Rocket Cart(s), is the upper shelf latched in position?'
					},
				]
			},
			{
				SUBTITLE: 'Stepstools/ladders (US Only):',
				ARRAY_LIST: [
					{
						Q: 'If needed, was a stepstool/ladder available?'
					},
					{
						Q: 'Was a proper stepstool/ladder being used as needed to access product?',
						SUBLIST: [
							'If used, did the associate always have three points of contact? (two hands & one foot or two feet & one hand).'
						]
					},
					{
						Q: 'Ensure stepstool/ladders are no higher than three steps.'
					},
				]
			},
			{
				SUBTITLE: 'Lifting:',
				ARRAY_LIST: [
					{
						Q: 'Did the associate avoid bending at the waist and use their legs to lift items?'
					},
				]
			},
			{
				SUBTITLE: 'Backroom/bin space:',
				ARRAY_LIST: [
					{
						Q: 'Is product in the backroom accessible?',
						SUBLIST: [
							'If not, were the items blocking the space able to be easily moved?'
						]
					},
					{
						Q: 'If product is stored overhead, is it able to be safely accessed?'
					},
					{
						Q: 'Are there any hazardous situations that would cause slips, trips or falls?'
					},
					{
						Q: 'Was the compactor easily accessible and properly maintained for safe use? (US Only)'
					},
					{
						Q: 'If any unsafe scenarios were identified, were they addressed with store management?'
					},
				]
			}]
	},
	{
		NAME: 'Hours',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is the merchandiser staying within assigned hours?',
					SUBLIST: [
						'If the answer is no, what behavior is driving this?',
						'Consider: tenure, time of year, revision activities, additional asks/Activities'
					]
				},
				{
					Q: 'Is the merchandiser entering time correctly?',
					SUBLIST: [
						'Consider: does their % of travel time align with expectations based on their route? Does their % of non-store time seem appropriate?'
					]
				},
				{
					Q: 'Are there any questions or concerns about time entry?'
				}
			]
		}]
	},
	{
		NAME: 'Call Attainment',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is the merchandiser making calls as expected?',
					SUBLIST: [
						'Consider: are they making calls to align with orders, are they over-calling, are they under-calling, are they meeting Call Opportunity?',
						'If the answer is no, what behavior is driving this?'
					]
				}
			]
		}]
	},
	{
		NAME: 'Activity Compliance',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: null,
			ARRAY_LIST: [
				{
					Q: 'Is the merchandiser executing assigned Activities at the store level?'
				},
				{
					Q: 'Is the merchandiser marking Activities appropriately?',
					SUBLIST: [
						'Review pending reasons and expectations for marking Activities pending'
					]
				},
				{
					Q: 'Is the merchandiser marking Activities on time?'
				},
				{
					Q: 'Does the merchandiser follow up as needed when unable to fully complete an Activity?'
				}
			]
		}]
	},
	{
		NAME: 'New Hire Training',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'What is the training focus for today’s work with?'
				},
				{
					Q: 'Who will be responsible for completing the onboarding journey with the merchandiser?'
				},
				{
					Q: 'What are your goals for the merchandiser while onboarding?'
				},
				{
					Q: 'What is the merchandiser’s learning style?',
					SUBLIST: [
						'How can you adjust to accommodate to their learning style?'
					]
				}
			]
		}]
	},
	{
		NAME: 'Revision',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'What type of revision was completed during the call?'
				},
				{
					Q: 'Was merchandiser prepared?'
				},
				{
					Q: 'Were best practices followed?'
				},
				{
					Q: 'Is follow up needed?',
					SUBLIST: [
						'Note: missing signage/fixtures/product'
					]
				}
			]
		}]
	},
	{
		NAME: 'Revision',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'What type of revision was completed during the call?'
				},
				{
					Q: 'Was merchandiser prepared?'
				},
				{
					Q: 'Were best practices followed?'
				},
				{
					Q: 'Is follow up needed?',
					SUBLIST: [
						'Note: missing signage/fixtures/product'
					]
				}
			]
		}]
	},
	{
		NAME: 'Revision',
		TYPE: TopicTypeEnum.AUDIT_CSV,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'What type of revision was completed during the call?'
				},
				{
					Q: 'Was merchandiser prepared?'
				},
				{
					Q: 'Were best practices followed?'
				},
				{
					Q: 'Is follow up needed?',
					SUBLIST: [
						'Note: missing signage/fixtures/product'
					]
				}
			]
		}]
	},
	{
		NAME: 'Follow-Up',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'What follow up is needed overall?'
				},
				{
					Q: 'Is additional training needed?'
				},
				{
					Q: 'When will you see merchandiser next?'
				},
				{
					Q: 'What have you asked merchandiser to follow up with you on?'
				}
			]
		}]
	},
	{
		NAME: 'Overall Performance',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Overall, is the merchandiser meeting expectations with the following:',
			ARRAY_LIST: [
				{
					Q: 'Communication'
				},
				{
					Q: 'Best Practices'
				},
				{
					Q: 'Building relationships in store(s)'
				},
				{
					Q: 'Support in/around Territory'
				}
			]
		},
		{
			SUBTITLE: 'Thank You',
			ARRAY_LIST: [
				{
					Q: 'Note what the merchandiser is doing well and what behaviors you would like them to continue.'
				}
			]
		},
		{
			SUBTITLE: 'Focus On',
			ARRAY_LIST: [
				{
					Q: 'Note what opportunities the merchandiser has or areas to improve upon.'
				}
			]
		}
		]
	},
	{
		NAME: 'Backroom',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Were you able to connect with a receiver? Review any questions or concerns.'
				},
				{
					Q: 'Is there assigned bin space?'
				},
				{
					Q: 'Everyday orders:',
					SUBLIST: [
						'Have orders been worked within 48 hours? Review labels to confirm.',
						'Is there any backstock?',
						'If so, is it organized and labeled appropriately?'
					]
				},
				{
					Q: 'Seasonal orders:',
					SUBLIST: [
						'Are there current seasonal orders in the backroom?',
						'It is organized and ready to set and/or replenish?'
					]
				},
				{
					Q: 'Overall organization'
				}
			]
		}]
	},
	{
		NAME: 'Outposts',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'What outposts are set in the store?'
				},
				{
					Q: 'Are outposts full and shoppable?'
				},
				{
					Q: 'Are there opportunities to maximize outpost locations?'
				}
			]
		}]
	},
	{
		NAME: 'Everyday Department',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Are cabinet drawers organized and maintained to standards?'
				},
				{
					Q: 'Is there any backstock?',
					SUBLIST: [
						'If so, is it organized and being worked in a timely manner?'
					]
				},
				{
					Q: 'Are there any fixtures that are missing and need ordered?'
				},
				{
					Q: 'Is all signage on display?'
				},
				{
					Q: 'Is the department maintained and shoppable?'
				},
				{
					Q: 'Does the floor layout match what is set at the store?',
					SUBLIST: [
						'If a discrepancy is identified, work with the chain AE on the appropriate course of action to correct'
					]
				}
			]
		}]
	},
	{
		NAME: 'Seasonal Run',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is the current season set?'
				},
				{
					Q: 'Is all signage on display?'
				},
				{
					Q: 'Is backstock organized and stored underneath the cabinets for easy replenishment?'
				},
				{
					Q: 'Is unsold product from previous seasons being kept and stored appropriately?'
				}
			]
		}]
	},
	{
		NAME: 'Merchandiser Behavior',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is there a merchandiser currently assigned to the store?',
					SUBLIST: [
						'If not, is there temporary coverage in place?'
					]
				},
				{
					Q: 'Is the merchandiser signing in on the appropriate vendor logs (if applicable) and SA 301 sheets?'
				},
				{
					Q: 'Does it appear the merchandiser is following service best practices?'
				}
			]
		}]
	},
	{
		NAME: 'Store Manager Relationship',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Were you able to meet with any store personnel?',
					SUBLIST: [
						'If so, note their contact information'
					]
				},
				{
					Q: 'Do the store personnel know who their merchandiser is?',
					SUBLIST: [
						'Introduce merchandiser(s) as needed'
					]
				},
				{
					Q: 'Were there any questions or concerns about service or upcoming programs?'
				},
				{
					Q: 'Were you able to share sales numbers with any store personnel?'
				}
			]
		}]
	},
	{
		NAME: 'General Notes',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Provide an overview of the call cycle performed'
				},
				{
					Q: 'Note any follow up action needed'
				}
			]
		}]
	},
	/* ------------------------ New TRAINING topics info text updates ------------------------ */
	{
		NAME: 'Training Type',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'In Person vs. Remote Training',
			ARRAY_LIST: [
				{
					Q: 'In Person training occurs face to face at a retail location and/or alternate location'
				},
				{
					Q: 'Remote Training: If you have a planned discussion with an associate regarding specific training topics and/or objectives, this can be considered a remote training. Remote Training can be completed via phone or facetime, and should follow the outline of an in-person training call. Review all store assignments and any questions or concerns regarding service. Discuss any upcoming program initiatives. Evaluate knowledge of scheduling, time entry, best practices and technology. Set follow up in-person training opportunity. Associate should record time spent completing a remote training as Non-Store time, or Call Code 7.'
				}
			]
		}]
	},
	{
		NAME: 'Training Recap',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Work with and observe merchandiser to ensure the following:',
			ARRAY_LIST: [
				{
					Q: 'Understanding of store assignments and schedules'
				},
				{
					Q: 'Understanding and accurate entry of time'
				},
				{
					Q: 'Can navigate and effectively use AG2Go tablet (if applicable)'
				},
				{
					Q: 'Is working orders in a timely manner and following Best Practices when servicing department'
				},
				{
					Q: 'Understands and follows Seasonal set, storage, maintenance and scan & save Best Practices'
				},
				{
					Q: 'Review Associate Detail page, discuss metric expectations and identify strengths and area(s) of focus'
				}
			]
		}]
	},
	/* ------------------------ New AUDIT topics info text updates ------------------------ */
	{
		NAME: 'Overall Standards',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Backroom - product being worked within 48 hours, backstock organized and labeled'
				},
				{
					Q: 'Outposts - set to intent and in high traffic locations'
				},
				{
					Q: 'Seasonal - set to intent and maintained, backstock organized and readily available'
				},
				{
					Q: 'Everyday Department - acceptable inventory levels, retail ready, maintained'
				},
				{
					Q: 'Fixtures/Signage - in working order, all components set'
				},
				{
					Q: 'Understorage - follows drawer organization standards'
				},
				{
					Q: 'Associate Behavior - store has a primary assigned, following best practices, staying within acceptable metrics'
				}
			]
		}]
	},
	{
		NAME: 'Store Relationships',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Does the store have your contact information'
				},
				{
					Q: 'Do the store personnel know who their merchandiser is? Introduce merchandiser(s) as needed'
				},
				{
					Q: 'Were there any questions or concerns about service or upcoming programs'
				},
				{
					Q: 'Were you able to share sales updates'
				}
			]
		}]
	},
  {
    NAME: 'Reviewed on Call',
    TYPE: TopicTypeEnum.AUDIT,
    INFO: [{
      SUBTITLE: 'Things to review:',
      ARRAY_LIST: [
        {
          Q: 'Backroom - product being worked within 48 hours, backstock organized and labeled'
        },
        {
          Q: 'Outposts - set to intent and in high traffic locations'
        },
        {
          Q: 'Seasonal - set to intent and maintained, backstock organized and readily available'
        },
        {
          Q: 'Everyday Department - acceptable inventory levels, retail ready, maintained'
        },
        {
          Q: 'Fixtures/Signage - in working order, all components set'
        },
        {
          Q: 'Understorage - follows drawer organization standards'
        },
        {
          Q: 'Associate Behavior - store has a primary assigned, following best practices, staying within acceptable metrics'
        },
      ]
    }]
  },
];

export const InfoConstantsUK = [
	{
		NAME: 'Store/ Call Process',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: null,
			ARRAY_LIST: [
				{
					Q: 'Have signing in processes been followed in and out of store?',
					SUBLIST: [
						'(Visitors/Red Book/Signing in Times)'
					]
				},
				{
					Q: 'Has the Tablet Notifications & Activity Compliance been reviewed at start of call?',
				},
				{
					Q: 'Has the Merchandiser checked for Deliveries (Checking outposts on route)?'
				},
				{
					Q: 'Is the Merchandiser working to best practice:',
					SUBLIST: [
						'Service of seasonal & online products first?',
						'Working from first bay & Straightening as stock is put away',
						'Storing excess stock neatly in drawers',
						'Are Empty Pockets being reported in line with store instruction'
					]
				},
			]
		}]
	},
	{
		NAME: 'Call Compliance',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is the journey plan correct and being followed?'
				},
				{
					Q: 'Is the merchandiser meeting Contracted hours?',
				},
				{
					Q: 'Are the days of calling being maintained?',
					SUBLIST: [
						'If the answer is no, what is the reason? What action is needed?',
						'Any follow Up?'
					]
				},
			]
		}]
	},
	{
		NAME: 'Activity Compliance',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Are Activity Compliances being completed correctly?'
				},
				{
					Q: 'Are they completed within given time frames?'
				},
				{
					Q: 'Are images good and information accurate?'
				},
				{
					Q: 'Review current activities for their store (& other contracted stores)'
				},
				{
					Q: 'Are “Pending” compliances being update when they have been unable to complete?'
				},
			]
		}]
	},
	{
		NAME: 'Merchandiser Team/Zone Support',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is help with replans / store cover good?'
				},
				{
					Q: 'Are they supportive to Colleagues?'
				},
				{
					Q: 'Is reliability good?'
				},
				{
					Q: 'Is Flexibility good?'
				},
			]
		}]
	},
	{
		NAME: 'New Starter Training',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: null,
			ARRAY_LIST: [
				{
					Q: 'What is the training focus for todays work with?'
				},
				{
					Q: 'Who will be supporting the Merchandisers Induction Programme?'
				},
				{
					Q: 'Has a follow up training date been set?'
				},
				{
					Q: 'What areas need greater focus?'
				},
			]
		},
		{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Has Health & Safety Training been completed / and being observed?'
				},
				{
					Q: 'Has Accreditation been completed?'
				},
				{
					Q: 'Journey Plan / Call Days / Durations / Frequencies'
				},
				{
					Q: 'Signing in & out processes – Visitors/Red Book'
				},
				{
					Q: 'Communication',
					SUBLIST: [
						'Who to contact in the event of query',
						'Notifications / Activity Compliances',
						'Document Reference Library'
					]
				},
				{
					Q: 'Tablet Training - Navigation & understanding (All Aspects)'
				},
				{
					Q: 'Is Payroll being documented & entered during the call?'
				},
				{
					Q: 'Training on any specific store processes for each account'
				},
				{
					Q: 'Stock Control & Replenishment Process'
				},
				{
					Q: 'Empty Pocket Reporting'
				},
				{
					Q: 'Credits & Stock Adjustments'
				},
				{
					Q: 'Replans & Installations '
				},
				{
					Q: 'Seasonal Installations/Servicing/Overtime'
				},
				{
					Q: 'Understanding of Zoning & Team Support'
				},
				{
					Q: 'Refer to Training Videos & Documents on Tablet'
				},
			]
		},
		{
			SUBTITLE: 'This is not an exhaustive list, refer to Training Documents',
		}]
	},
	{
		NAME: 'Follow Up ',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: null,
			ARRAY_LIST: [
				{
					Q: 'What follow up does the Merchandiser need to follow up or focus on?'
				},
				{
					Q: 'Is additional training needed?',
				},
				{
					Q: 'When will you see merchandiser next?',
				},
				{
					Q: 'Are there other comments or feedback from the Merchandiser?',
				},
			]
		}]
	},
	{
		NAME: 'Overall Performance ',
		TYPE: TopicTypeEnum.TRAINING,
		INFO: [{
			SUBTITLE: 'Overall, is the merchandiser meeting expectations with the following:',
			ARRAY_LIST: [
				{
					Q: 'Working to Journey Plan'
				},
				{
					Q: 'Tablet use & understanding'
				},
				{
					Q: 'Communication'
				},
				{
					Q: 'Display (Best Practices)'
				},
				{
					Q: 'Activity Compliance'
				},
				{
					Q: 'General Admin'
				},
				{
					Q: 'Building relationships in store'
				},
				{
					Q: 'Support in/around Territory'
				},
			]
		},
		{
			SUBTITLE: 'Focus & Thank you',
			ARRAY_LIST: [
				{
					Q: 'Thank You'
				},
				{
					Q: 'Highlights & Achievements'
				},
				{
					Q: 'What areas for focus?'
				},
			]
		}]
	},
	// Audit Topics Info
	{
		NAME: 'Backroom',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is there designated storage space?'
				},
				{
					Q: 'Are there any issues in accessing stock, can these be resolved?'
				},
				{
					Q: 'Everyday Stock',
					SUBLIST: [
						'Is backstock labelled and organised?',
						'Is there any old stock to be scanned out/actioned?'
					]
				},
				{
					Q: 'Seasonal Stock',
					SUBLIST: [
						'Are there current seasonal orders in the backroom?',
						'Is stock organised and ready to replenish?'
					]
				},
			]
		}]
	},
	{
		NAME: 'Online',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: null,
			ARRAY_LIST: [
				{
					Q: 'Is the Online to plan / Numbered Correctly?'
				},
				{
					Q: 'Is the Online Empty Pocket Process being completed & for Duplicate Pockets?'
				},
				{
					Q: 'Is Seasonal Set up correctly (When applicable)'
				},
				{
					Q: 'Any concerns?'
				},
			]
		}]
	},
	{
		NAME: 'Seasonal Aisle / Outposts',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Is there Seasonal Space allocated?'
				},
				{
					Q: 'Is POS sited?'
				},
				{
					Q: 'Are shippers correctly sited?'
				},
				{
					Q: 'Is Seasonal Space being serviced first?'
				},
				{
					Q: 'Is back stock in the drawers, where possible for easy replenishment? (Where possible)'
				},
				{
					Q: 'Is Seasonal Overtime being completed?'
				},
				{
					Q: 'Is Seasonal Activity Compliance Up Actioned/Up to Date'
				},
			]
		}]
	},
	{
		NAME: 'Everyday Shipper/ Outposts',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Everyday',
			ARRAY_LIST: [
				{
					Q: 'How many shippers should be displayed? Is this correct?'
				},
				{
					Q: 'Are shipper locations correct?'
				},
				{
					Q: 'Are shippers/outposts/Gondola Ends etc full & to plan?'
				},
				{
					Q: 'Are there any opportunities around shippers/Outposts? Eg: Locations?'
				},
				{
					Q: 'Are shippers/outposts being serviced first?'
				},
			]
		}]
	},
	{
		NAME: 'Everyday Card Aisle',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'First impressions: Is the overall display maintained to a good standard?'
				},
				{
					Q: 'Are drawers organised and Red Book sited, first rack in flow?'
				},
				{
					Q: 'Are pockets filled correctly? (No overfills / Envelopes faced)'
				},
				{
					Q: 'Is the display to plan – Fixtures & Flow?  (If not follow up with ops or merchandiser)'
				},
				{
					Q: 'Are fixture shout outs/parasite units correctly located?'
				},
				{
					Q: 'Do the fixtures have rack IDs, correctly numbered?'
				},
				{
					Q: 'Are Duplicate Pockets identified?'
				},
				{
					Q: 'Are fixtures clean?'
				},
				{
					Q: 'Are there any damaged fixtures? Have issues been reported to the store?'
				},
				{
					Q: 'Is POS correctly sited, any missing components? Have missing items been given to the store management to order, where applicable?  (Tesco, Merchandisers can order on Tablet)'
				},
			]
		}]
	},
	{
		NAME: 'Store Relationship ',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Were you able to meet with any store management?',
					SUBLIST: [
						'If so, note their contact information'
					]
				},
				{
					Q: 'Do the store Management/Colleagues know who their merchandiser is?',
					SUBLIST: [
						'Introduce merchandiser where possible / Follow up with merchandiser'
					]
				},
				{
					Q: 'Were there any questions or concerns about service or forthcoming activities?'
				},
			]
		}]
	},
	{
		NAME: 'General Notes',
		TYPE: TopicTypeEnum.AUDIT,
		INFO: [{
			SUBTITLE: 'Things to review:',
			ARRAY_LIST: [
				{
					Q: 'Note any follow up action needed'
				},
			]
		}]
	},
];
