import { UK_SUPERVISOR_ROLES, US_SUPERVISOR_ROLES } from "../models/admin/area-supervisor.model";
import { InfoConstantsUK, InfoConstantsUS } from "./info.constants";
import { SCHEDULE_CONTENTS } from "./schedule.constants";

export const CountryGlobalVariables = {
  US: {
    associates: 'Associates',
    associatesSearchText: 'Search Associate',
    filterMerch: 'Assigned Merchandiser',
    trainingHistory: 'Training History',
    trainingStart: 'Start Training',
    trainingButton: 'Training',
    training: 'Training',
    trainingSchedule: 'Schedule Training',
    trainingLastOn: 'Last Training On',
    trainingDate: 'Training Date',
    trainingCalls: 'Training calls',
    hireDate: 'Hire Date',
    zoneMerch: 'Zones',
    assignedStores: 'Assigned Stores',
    dateFormat: 'MM/DD/YYYY',
    datePipeFormat: 'MM/dd/YYYY',
    INFO_CONSTANTS: InfoConstantsUS,
    SUPERVISOR_ROLES: US_SUPERVISOR_ROLES,
    APP_LOGO: 'assets/img/ag_myteam_us_logo.png'
  },
  UK: {
    associates: 'Merchandisers',
    associatesSearchText: 'Search Merchandiser',
    filterMerch: 'Merchandiser',
    filterChain: 'Act on',
    trainingHistory: 'WorkWith History',
    trainingStart: 'Start WorkWith',
    trainingButton: 'WorkWith',
    training: 'WorkWith',
    trainingSchedule: 'Schedule WorkWith',
    trainingLastOn: 'Last WorkWith On',
    trainingDate: 'WorkWith Date',
    trainingCalls: 'WorkWith calls',
    hireDate: 'Start Date',
    zoneMerch: 'Zone',
    assignedStores: 'Contracted Stores',
    dateFormat: 'DD/MM/YYYY',
    datePipeFormat: 'dd/MM/YYYY',
    INFO_CONSTANTS: InfoConstantsUK,
    SUPERVISOR_ROLES: UK_SUPERVISOR_ROLES,
    APP_LOGO: 'assets/img/ag_myteam_uk_logo.png'
  }
};
