import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../shared/models/user';
import { default as config } from '../../modules/app-config.json';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class GraphService {

	user: User = {
		displayName: '',
		profilePhotoUrl: null,
		groupIDs: []
	};

	private subject = new Subject<User>();
	graphUri = config.resources.graphApi.resourceUri;

	sendClickEvent(user: User) {
		this.subject.next(user);
	}

	getClickEvent(): Observable<User> {
		return this.subject.asObservable();
	}

	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer
	) { }

	getGroups() {
		return this.http.get(this.graphUri);
	}

	getNextPage(nextPage) {
		return this.http.get(nextPage);
	}

	public loadProfilePhoto() {
		const photo_graph_endpoint = 'https://graph.microsoft.com/v1.0/users/' + this.user.displayName + '/photo/$value';
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'image/jpg');

		this.http.get(photo_graph_endpoint, { headers, responseType: 'blob' })
			.subscribe(
				(response: Blob) => {
					var urlCreator = window.URL;
					this.user.profilePhotoUrl = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
				},
				(error) => {
					this.user.profilePhotoUrl = null;
				}
			);
	}
}
