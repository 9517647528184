<div
  fxLayout="column"
  fxLayoutGap="20px"
>
    <app-msg-banner
        *ngIf="showNotification" [msgList]="messageList"
        (msgListChange)="messageList = $event"
    ></app-msg-banner>

    <app-generic-schedule-event
        [storeList]="stores"
        [associate]="associate"
        [hasAssociateNotFound]="hasAssociateNotFound"
        [isEditFromUnscheduled]="isEditFromUnscheduled"
        [storeDataFrom]="storeDataFrom"
        [purposesOfCall]="purposes"
        (storeDropdownEmitter)="onChangeStoreSelection($event)"
        (onSaveEmitter)="onSaveEmitter($event)"
    ></app-generic-schedule-event>
</div>
