import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenericFilterObject} from '../../shared/models/generic-filter-object.model';
import {Observable} from 'rxjs';
import {Store} from '../../shared/models/stores/store.model';
import {IPagedResponse} from '../../shared/models/IPageResponse';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {StoreDetails} from '../../shared/models/stores/store-details.model';
import { TerritoryService } from './territory-management.service';
import { AssignedStoresDetails } from '../../shared/models/associates/assigned-stores-details.model';
import { UtilsService } from './utils.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private http: HttpClient,
              private terrService: TerritoryService,
              private loginService: LoginService) { }

  public getStoresByApi(genericFilterObj: GenericFilterObject, api: string, terr: number): Observable<IPagedResponse<Store>> {
    let params = null;
    const country = this.loginService._country;

    params = new HttpParams();
    if (genericFilterObj.form != null) {
      Object.keys(genericFilterObj.form).map(function (key) {
        if (genericFilterObj.form[key] !== null && genericFilterObj.form[key] !== undefined && genericFilterObj.form[key] !== '') {
          params = params.append(key, genericFilterObj.form[key]);
        }
      });
    }

    if (!genericFilterObj.page || typeof genericFilterObj.page === 'object') {
      params = params.append('size', genericFilterObj.page.size.toString());
      params = params.append('page', genericFilterObj.page.page.toString());

      if (genericFilterObj.page.sort && genericFilterObj.page.sort.length > 0) {
        genericFilterObj.page.sort.forEach(s => {
          params = params.append('sort', s);
        });
      }
    } else {
      params = params.append('size', '10');
      params = params.append('page', '0');
    }

    return this.http.get<IPagedResponse<Store>>(`${environment.apiResourceUri}/retailer/country/${country}/${api}/${terr}`, {params: params})
      .pipe(map((res: IPagedResponse<Store>) => {
        if (res?.size > 0) {
          res?.content?.forEach(store => {
            if (store.lastCall) {
              store.lastCall = UtilsService.dateToServerDate(store.lastCall);
            }
          });
        }
        return res;
      }));
  }

  public getServicedStoresByMerchandiserId(terr: number, merchandiserId: number): Observable<StoreDetails[]> {
    return this.http.get<StoreDetails[]>(
        `${environment.apiResourceUri}/retailer/serviced/merchandiser/${merchandiserId}/territory/${terr}`)
      .pipe(map((res: StoreDetails[]) => {
        return res;
      }));
  }

  public getStoresByMerchandiserId(terr: number, merchandiserId: number): Observable<Store[]> {
    return this.http.get<Store[]>(
        `${environment.apiResourceUri}/retailer/serviced/${terr}/${merchandiserId}`)
      .pipe(map((res: Store[]) => {
        return res;
      }));
  }

  public storeDetails(id: number): Observable<StoreDetails> {
    return this.http.get<StoreDetails>(`${environment.apiResourceUri}/retailer/${id}`)
      .pipe(map((response: StoreDetails) => {
        if (response.lastTraining) {
          response.lastTraining = UtilsService.dateToServerDate(response.lastTraining);
        }
        if (response.lastCall) {
          response.lastCall = UtilsService.dateToServerDate(response.lastCall);
        }
        return response;
      }));
  }

  public findAllStoresByMerchandiserIdAndTerritory(merchId: number, terr: number): Observable<StoreDetails[]> {
    const country = this.loginService._country;
    return this.http.get<StoreDetails[]>(`${environment.apiResourceUri}/retailer/country/${country}/merchandiser/${merchId}/territory/${terr}`);
  }

  public getAllStoresByTerritory(terr: number): Observable<StoreDetails[]> {
    const country = this.loginService._country;
    return this.http.get<StoreDetails[]>(`${environment.apiResourceUri}/retailer/country/${country}/territory/${terr}`);
  }

  public findServicedStoresByTerritory(terr: number): Observable<StoreDetails[]> {
    const country = this.loginService._country;
    return this.http.get<StoreDetails[]>(`${environment.apiResourceUri}/retailer/country/${country}/serviced/territory/${terr}`);
  }

  public findNonServicedStoresByTerritory(terr: number): Observable<StoreDetails[]> {
    const country = this.loginService._country;
    return this.http.get<StoreDetails[]>(`${environment.apiResourceUri}/retailer/country/${country}/non-serviced/territory/${terr}`);
  }

  public getStoreDetailsByScheduleId(scheduleId: string): Observable<StoreDetails> {
    return this.http.get<StoreDetails>(`${environment.apiResourceUri}/retailer/schedule/${scheduleId}`);
  }

  public getAssignedStoresDetailsByMerchandiserId(merchandiserId: number): Observable<AssignedStoresDetails> {
    const terr = this.terrService.getTerritory();
    return this.http.get<AssignedStoresDetails>(
        `${environment.apiResourceUri}/retailer/merchandiser/${merchandiserId}/territory/${terr}/stores-details`);
  }
}
