import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConstants {

  // Application Insights
  public APP_INSIGHTS_USAGE_TYPE_KEY = 'usage-type';
  public APP_INSIGHTS_USAGE_TYPE_NAME = 'usage-type-name';
  public APP_INSIGHTS_USAGE_USER = 'user-email';
}
