<div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="wrapper"
    >
    <mat-card
        *ngIf="!isUpdateSuccess"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="15px"
        class="mat-elevation-z2 parent-card"
        [formGroup]="formData">

        <app-msg-banner
            *ngIf="showNotification"
            [msgList]="messageList"
            (msgListChange)="messageList = $event">
        </app-msg-banner>

        <div class="title">Update Zone</div>

        <mat-form-field class="option">
            <mat-label> Territory </mat-label>
            <mat-select
                formControlName="terr"
                (selectionChange)="onTerritoryChange()"
                required>
                <mat-option
                    *ngFor="let terr of territoryList"
                    [value]="terr"> {{terr}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="option">
            <mat-label> Route </mat-label>
            <mat-select
                formControlName="p_rte"
                (selectionChange)="onPRouteChange()"
                #singleSelect
                required>

                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="pRouteFilterCtrl"
                        placeholderLabel="Find Route ..."
                        noEntriesFoundLabel="'No matching Route found'">
                    </ngx-mat-select-search>
                </mat-option>


                <mat-option
                    *ngFor="let p_rte of filteredPRouteList | async"
                    [value]="p_rte"> {{p_rte.pRoute}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field class="option">
            <mat-label>Zone </mat-label>
            <input
              type="text"
              formControlName="zone"
              matInput
              [matAutocomplete]="auto"
              required/>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let zone of zoneList"
                [value]="zone"> {{ zone }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
                *ngIf="formData.controls.zone.errors?.zoneLength"> Zone maximum length is 4.
            </mat-error>
            <mat-error
                *ngIf="formData.controls.zone.errors?.zoneFormat"> Zone must contain digits only.
            </mat-error>
        </mat-form-field>

        <!-- action buttons -->
        <div
            fxFlex.lt-md="100%"
            fxLayoutAlign="center"
        >
            <button
                fxFlex.lt-md="100%"
                mat-raised-button
                class="btn btn-search"
                (click)="onSave()"
                [disabled]="formData.invalid"
            >Save
            </button>
        </div>
    </mat-card>

    <!-- success message -->
    <mat-card
        *ngIf="isUpdateSuccess"
        fxLayout="column"
        fxLayoutAlign="center center"
        class="mat-elevation-z2 success-msg">
        <div>Zone was successfully updated and will be available to preview in PowerBI in 24hrs.
          <br>You can now close this window or tap <a routerLink="" (click)="refresh()">here</a> to submit another zone change.</div>
    </mat-card>
</div>
