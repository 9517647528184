/**
 *    Copyright 2016 Sven Loesekann
   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at
	   http://www.apache.org/licenses/LICENSE-2.0
   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';


// Microsoft
import {
	MsalModule,
	MsalService,
	MsalInterceptorConfiguration,
	MsalGuardConfiguration,
	MsalRedirectComponent,
	MsalBroadcastService,
	MSAL_INTERCEPTOR_CONFIG, MSAL_GUARD_CONFIG, MSAL_INSTANCE,
} from '@azure/msal-angular';
import { default as config } from './app-config.json';

// external angular components,modules,directives
import { ModuleHomeModule } from './module-home/module-home.module';
import { AppComponent } from './app.component';

// core Module
import { CoreModule } from '../core/core.module';

// shared Module
import { SharedModule } from '../shared/shared.module';

// routes
import { AppRoutingModule } from './app-routing.module';
import { LoadingService } from '../core/interceptors/loading-service';
import { LoadingInterceptor } from '../core/interceptors/loading-interceptor';

// Material
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DOCUMENT } from '@angular/common';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { PagesModule } from './pages/pages.module';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { ChatModule } from './chat/chat.module';
import { MsalLdapGuard } from '../core/guards/msal-ldap.guard';
import { MsalLdapInterceptor } from '../core/interceptors/msal-ldap.interceptor';

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log('client logging' + message);
}

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.clientId,
			authority: config.auth.authority,
			redirectUri: window.location.origin + environment.baseHref,
			postLogoutRedirectUri: window.location.origin + environment.baseHref,
			navigateToLoginRequestUrl: false,
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: true,
			},
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(environment.apiResourceUri, [environment.backendApiScope]);
	protectedResourceMap.set(config.resources.graphApi.resourceUri, [environment.backendApiScope]);
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', ['user.ReadBasic.all']);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read', 'user.ReadBasic.all']
		}
	};
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		HttpClientModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		ModuleHomeModule,
		PagesModule,
		ChatModule,
		MatProgressSpinnerModule,
		MatToolbarModule,
		MsalModule,
		BrowserAnimationsModule,
		AgmCoreModule.forRoot({
			apiKey: environment.googleMapsApi
		}),
		AgmSnazzyInfoWindowModule
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
	providers: [
		[LoadingService],
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalLdapInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingInterceptor, multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},
		MsalService,
		MsalLdapGuard,
		MsalBroadcastService,
		{
			provide: DOCUMENT,
			useValue: document
		}
	]
})
export class AppModule { }
