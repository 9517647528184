import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-one',
  templateUrl: './module-home.component.html',
  styleUrls: ['./module-home.component.scss']
})
export class ModuleHomeComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
