import { Component, OnDestroy, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { StoreDetails } from '../../../shared/models/stores/store-details.model';
import { ScheduleService } from '../../../core/services/schedule.service';
import { ScheduleDetails } from '../../../shared/models/schedule/schedule-details.model';
import { StoresService } from '../../../core/services/stores.service';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { SCHEDULE_CONTENTS, SCHEDULE_TYPE } from '../../../shared/constants/schedule.constants';
import { ScheduleModel, ScheduleType } from '../../../shared/models/schedule/schedule.model';
import { UtilsService } from '../../../core/services/utils.service';
import { ScheduleResponse } from '../../../shared/models/schedule/schedule-response.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {LoginService} from '../../../core/services/login.service';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: CountryGlobalVariables.US.dateFormat,
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};


@Component({
	selector: 'app-schedule-appointment',
	templateUrl: './schedule-appointment.component.html',
	styleUrls: ['./schedule-appointment.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ScheduleAppointmentComponent implements OnInit, OnDestroy {

	stores: StoreDetails[] = [];
	schedule: ScheduleDetails;
	isEditFromUnscheduled: boolean = false;
	formData: UntypedFormGroup;

	protected _onDestroy = new Subject<void>();
	storeFilterCtrl: UntypedFormControl = new UntypedFormControl();
  	filteredStores: ReplaySubject<StoreDetails[]> = new ReplaySubject<StoreDetails[]>();

	min = new Date();

	// error list
	messageList = [];
	showNotification = false;

	constructor(private fb: UntypedFormBuilder,
							private scheduleService: ScheduleService,
							private storesService: StoresService,
							private terrService: TerritoryService,
							private router: Router,
							private msgBanner: MsgBannerService,
							private dialog: MatDialog,
              				private loginSvc: LoginService) {
		this.initFormGroup();

    if (this.loginSvc.isUkUser()) {
      MY_FORMATS.display.dateInput = CountryGlobalVariables.UK.dateFormat;
    }
	}

	ngOnInit() {
		this.loadData();
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	initFormGroup() {
		this.formData = this.fb.group({
			store: [null, [Validators.required], []],
			date: [ null, [], []],
			startTime: [ null, [], []],
			appointmentName: [null, [Validators.required], []],
			notes: [null, [], []]
		});
	}

	loadData() {
		const terr = this.terrService.getTerritory();
		this.storesService.getAllStoresByTerritory(terr).subscribe(
			(response: any) => {
				this.sortStoresByName(response);
				this.stores = response;
				this.processSelectedStoreIsDisabled();
				if (this.stores.length === 1 && !this.formData.controls.store.value) {
					this.formData.controls.store.setValue(this.stores[0].id);
				}
				this.initStoreSearchFilter();
			},
			(error) => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
				}
				this.showNotification = true;
			});

		// edit scheduled appointment
		if (history.state.data && history.state.data.scheduleId) {
			var scheduleId = history.state.data.scheduleId;
			this.getSchedule(scheduleId);
			this.processIsUnscheduledEdit(history.state.data.isEditFromUnscheduled);
			return;
		}
	}

	getSchedule(scheduleId: string) {
		this.scheduleService.getSchedule(scheduleId).subscribe(
			(response: ScheduleDetails) => {
				this.schedule = response;
				this.fillFormWithScheduleValues(this.schedule);
			},
			(error) => {
				if (error.status === 404)
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				else
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');

				this.showNotification = true;
			}
		);
	}

	fillFormWithScheduleValues(schedule: ScheduleDetails) {
		this.formData.controls.appointmentName.setValue(schedule.appointmentName);
		this.formData.controls.notes.setValue(schedule.appointmentNotes);
		this.formData.controls.store.setValue(schedule.retailerId);

		if (schedule.scheduledDate) {
			var scheduledDate = new Date(schedule.scheduledDate);
			this.formData.controls.date.setValue(scheduledDate);
			var time = this.getFormattedTime(scheduledDate.getHours(), scheduledDate.getMinutes());
			this.formData.controls.startTime.setValue(time);
		}

		this.processSelectedStoreIsDisabled();
	}

	onSave() {
		if (this.formData.invalid)
			return

		this.showNotification = false;
		this.messageList = [];

		let data = this.buildScheduleObject();
		let scheduleId = this.schedule ? this.schedule.id : null;
		let date = data.scheduledDate;
		let isAllDayEvent = data.isAllDayEvent;

		// check schedule conflict for date and time
		if (date != null && !isAllDayEvent) {
			this.scheduleService.checkScheduledDateTimeConflict(scheduleId, date).subscribe(
				(response: number) => {
					if (response > 0) {
						this.displayDateTimeConflictDialog(data);
						return;
					}
					// no conflict
					if(scheduleId == null) {
						this.createSchedule(data);
					} else {
						data.id = scheduleId;
						this.updateSchedule(data);
					}
				},

				(error) => {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
					this.showNotification = true;
				}
			);
			return;
		}

		if(scheduleId == null) {
			this.createSchedule(data);
		} else {
			data.id = scheduleId;
			this.updateSchedule(data);
		}
	}

	createSchedule(data: ScheduleModel) {
		this.scheduleService.createSchedule(data).subscribe(
			(response: ScheduleResponse) => {
				const scheduledEvent = data.scheduledDate != null;
				this.displayConfirmationMessage(response.outlookEventSuccess, scheduledEvent);
			},
			(error) => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
			  		this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
				}
			  	this.showNotification = true;
			}
		  );
	}

	updateSchedule(schedule: ScheduleModel) {
		this.scheduleService.updateSchedule(schedule).subscribe(
			(response: ScheduleResponse) => {
				const scheduledEvent = schedule.scheduledDate != null;
				this.displayConfirmationMessage(response.outlookEventSuccess, scheduledEvent);
			},
			(error) => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
			  		this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
				}
			  	this.showNotification = true;
			}
		);
	}

	buildScheduleObject(): ScheduleModel {
		var schedule: ScheduleModel = {
			id: null,
			merchandiserId: null,
			areaSupervisorId: null,
			retailerId: this.formData.controls.store.value,
			scheduledDate: null,
			scheduledOutlookDate: null,
			isAllDayEvent: this.isAllDayEvent(),
			scheduleType: ScheduleType.APPOINTMENT,
			completedStatus: null,
			territoryNumber: null,
			appointmentName:  this.formData.controls.appointmentName.value,
			appointmentNotes:  this.formData.controls.notes.value,
      purposeActions: null
		}
		this.setScheduledDates(schedule);

		return schedule;
	}

	onCancel() {
		// confirmation dialog
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				title: null,
				message:
					'Are you sure you want to discard all changes?',
				confirmationRequired: true,
				yesButtonShow: true,
				noButtonShow: true
			},
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult)
				this.goToMyEventsPage(!this.isEditFromUnscheduled);
		});
	}

	getFormattedTime(hrs: number, mins: number) {
		if (hrs == 0 && mins == 0)
			return null;

		let time: string = "";
		time += hrs < 10 ? ("0" + hrs) : hrs.toString();
		time += ":";
		time += mins < 10 ? ("0" + mins) : mins;

		return time;
	}

	goToMyEventsPage(scheduledEvent: boolean) {
		const data = scheduledEvent ? null : { scheduleType: SCHEDULE_TYPE.UNSCHEDULED }
		this.router.navigate(['/my-events'], { state: { data } });
	}

	get timeSlots() {
		return SCHEDULE_CONTENTS.TIME_SLOTS.filter(t => t.country.includes(this.loginSvc._country));
	}

	sortStoresByName(stores: StoreDetails[]) {
		// alphabetically
		stores.sort((s1, s2) => {
			if (s1.name < s2.name) return -1;
			if (s1.name > s2.name) return 1;
			return 0;
		});
	}

	setScheduledDates(schedule: ScheduleModel) {
		if (!this.formData.controls.date.value) {
			return null;
		}

		var scheduledDate = new Date(this.formData.controls.date.value);
		scheduledDate.setHours(0);
		scheduledDate.setMinutes(0);
		scheduledDate.setSeconds(0);
		scheduledDate.setMilliseconds(0);

		if (this.formData.controls.startTime.value) {
			let scheduledTime: string = this.formData.controls.startTime.value;
			const hourMinutes: string[] = scheduledTime.split(':');
			scheduledDate.setHours(Number(hourMinutes[0]));
			scheduledDate.setMinutes(Number(hourMinutes[1]));
		}

		if (this.isAllDayEvent()) {
			schedule.scheduledDate = UtilsService.dateToServerDate(scheduledDate);
			schedule.scheduledOutlookDate = UtilsService.dateToServerDate(scheduledDate);
		} else {
			schedule.scheduledDate = scheduledDate;
			schedule.scheduledOutlookDate = scheduledDate;
		}
	}

	isAllDayEvent() {
		if (this.formData.controls.date.value &&
			this.formData.controls.startTime.value == null)
			return true;

		return false;
	}

	displayDateTimeConflictDialog(data: ScheduleModel) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: 'An event already exists at that time. Do you want to continue?',
				confirmationRequired: true,
				yesButtonShow: true,
				cancelButtonShow: true
			},
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				let scheduleId = this.schedule ? this.schedule.id : null;
				if(scheduleId == null) {
					this.createSchedule(data);
				} else {
					data.id = scheduleId;
					this.updateSchedule(data);
				}
			}
		});
	}

	displayConfirmationMessage(isOutlookSuccess: boolean, scheduledEvent: boolean) {
		let message = isOutlookSuccess ? 'Submitted successfully!' :
			'Schedule submitted successfully, but the Outlook Event failed to be created!';

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
		  maxWidth: '400px',
		  disableClose: true,
		  data: {
			message: message,
			confirmationRequired: false
		  },
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			this.goToMyEventsPage(scheduledEvent);
		});
	}

	processSelectedStoreIsDisabled() {
		let selectedStoreId = this.formData.controls.store.value;
		if (selectedStoreId == null || this.stores.length == 0)
		  return;

		let store = this.stores.find(s => s.id === selectedStoreId);
		if (store === undefined)
		  this.formData.controls.store.setValue(null);
	}

	processIsUnscheduledEdit(isEditFromUnscheduled: boolean) {
		// if event is edited from the unscheduled page
		if (isEditFromUnscheduled == null)
		  return;

		this.isEditFromUnscheduled = isEditFromUnscheduled;
	}

	initStoreSearchFilter() {
		this.filteredStores.next(this.stores);
		this.storeFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterStores();
        });
	}

	protected filterStores() {
		if (!this.stores) {
		  return;
		}

		// get the search keyword
		let search = this.storeFilterCtrl.value;
		if (!search) {
		  this.filteredStores.next(this.stores);
		  return;
		} else {
		  search = search.toLowerCase();
		}

		// filter the stores
		this.filteredStores.next(
		  this.stores?.filter(
			(store: StoreDetails) =>
				store.chain?.toLowerCase().indexOf(search) > -1 ||
				store.store?.toLowerCase().indexOf(search) > -1
		  )
		);
	  }
}
