import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CloudFront} from '../../shared/models/cloudfront.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CloudfrontService {

  constructor(private http: HttpClient) { }

  static checkIfExistsOrExpired() {
    const cloudFront = localStorage.getItem(environment.cloudfrontKey);
    if (!cloudFront) {
      return false;
    }
    const cloudFrontObject: CloudFront = JSON.parse(cloudFront);
    if ((cloudFrontObject.expireDate - 300) < (new Date().getTime() / 1000)) {
      return false;
    }
    return true;
  }

  private getSignedUrl(): Observable<CloudFront> {
    return this.http.get<CloudFront>(`${environment.apiResourceUri}/cloudfront`)
      .pipe(
        map(response => {
          this.saveUrl(response);
          return response;
        })
      );
  }

  getSignedUrlAsPromise(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];

    if (!CloudfrontService.checkIfExistsOrExpired()) {
      promiseList.push(
        new Promise((release, reject) => {
          this.getSignedUrl().subscribe(
            result => {
              release(true);
            }, error => {
              reject(error);
            }
          );
        })
      );
    }

    return promiseList;
  }

  private saveUrl(response: CloudFront) {
    localStorage.setItem(environment.cloudfrontKey, JSON.stringify(response));
  }

  getUrlFromStorage() {
    return JSON.parse(localStorage.getItem(environment.cloudfrontKey)).url;
  }
}
