import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {GENERIC_VIEW_CONTENTS, SortDefinition} from '../../../shared/constants/generic-view.constants';
import {Store} from '../../../shared/models/stores/store.model';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';
import {StoresService} from '../../../core/services/stores.service';
import {GenericFilterObject, Page} from '../../../shared/models/generic-filter-object.model';
import {PagesEnum} from '../../../shared/models/stores/pages.model';
import {TerritoryService} from '../../../core/services/territory-management.service';
import {Router} from '@angular/router';
import {GenericFilterHistoryService} from '../../../core/services/generic-filter-history.service';
import {Country} from '../../../shared/enum/country.enum';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';
import {LoginService} from '../../../core/services/login.service';

@Component({
  selector: 'app-non-serviced-stores',
  templateUrl: './non-serviced-stores.component.html',
  styleUrls: ['./non-serviced-stores.component.scss']
})
export class NonServicedStoresComponent implements OnInit {

  form: UntypedFormGroup;
  sortHeaders: SortDefinition[] = JSON.parse(JSON.stringify(GENERIC_VIEW_CONTENTS.NON_SERVICED_STORES.SORT));
  sortOrders: string[] = [];

  data: Store[] = [];
  length = 0;

  dataSubject: Subject<Store[]> = new Subject();
  genericFilter: GenericFilterObject;

  // error list
  messageList = [];
  showNotification = false;

  constructor(private fb: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private msgBanner: MsgBannerService,
              private terrService: TerritoryService,
              private router: Router,
              private loginSvc: LoginService,
              private genericFilterSvc: GenericFilterHistoryService,
              private storesService: StoresService) {
    this.constructForm();
  }

  ngOnInit(): void {
    if (this.loginSvc.isUkUser()) {
      const idx = this.sortHeaders.findIndex(s => s.colDef === 'merchandiser.fullName');
      if (idx !== -1) {
        this.sortHeaders[idx].colName = CountryGlobalVariables.UK.filterMerch;
      }
    }

    if (this.genericFilterSvc.isBackOperationOccurred) {
      this.genericFilter = this.genericFilterSvc.getState();
      this.genericFilterSvc.isBackOperationOccurred = false;

      this.form.controls.name.setValue(this.genericFilter.form['name']);
      this.sortOrders = this.genericFilter.page.sort ? this.genericFilter.page.sort : [];
    } else {
      const sort: string[] = this.getSortDetails();
      this.genericFilter = {
        form: this.form?.value,
        page: new Page(0, 10, sort)
      };
    }
    this.getData(this.genericFilter);
  }

  constructForm() {
    this.form = this.fb.group({
      name: []
    });
  }

  refreshData(event: GenericFilterObject) {
    if (event != null) {
      this.genericFilter = event;
      this.getData(event);
    }
  }

  private getData(genericFilterObj: GenericFilterObject) {
    this.showNotification = false;
    const terr = this.terrService.getTerritory();
    this.storesService.getStoresByApi(genericFilterObj, 'non-serviced', terr).subscribe(
      response => {
        this.data = response.content;
        this.length = response.totalElements;
        this.notifyChild(this.data);
      }, error => {
        this.notifyChild([]);
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  notifyChild(data: Store[]) {
    this.dataSubject.next(data);
  }

  openSnackBar() {
    this.snackBar.open('Please filter your results.', 'Close', {
      duration: 10 * 1000,
    });
  }

  buildConfig() {
    return {
      page: PagesEnum.NON_SERVICED_STORES,
      searchText: 'Search Stores'
    };
  }

  goToStoreDetails(event: any) {
    const data = {
      id: event.id,
      page: PagesEnum.NON_SERVICED_STORES
    };
    this.genericFilterSvc.saveState(this.genericFilter);
    this.router.navigate(['non-serviced-details'], {state: {data}});
  }

  getSortDetails(): string[] {
    const sort = [];
    this.sortHeaders.forEach((option) => {
      if (option.sortType !== '-') {
        sort.push(option.colDef + ',' + option.sortType);
      }
    });

    return sort;
  }
}
