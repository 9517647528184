import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TerritoryService} from '../../../core/services/territory-management.service';
import {MsgBannerService} from '../msg-banner/msg-banner.service';

@Component({
  selector: 'app-change-territory-dialog',
  templateUrl: './change-territory-dialog.component.html',
  styleUrls: ['./change-territory-dialog.component.scss']
})
export class ChangeTerritoryDialogComponent implements OnInit {
  territories = [];
  form: UntypedFormGroup;

  // error list
  messageList = [];
  showNotification = false;

  constructor(public dialogRef: MatDialogRef<ChangeTerritoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder,
              public terrService: TerritoryService,
              private msgBanner: MsgBannerService) {
    var terr = this.terrService.getTerritory();
    this.form = this.fb.group({
      terr: [terr ? terr : null, [], []]
    });
  }

  ngOnInit(): void {
    this.loadTerritories();
  }

  loadTerritories() {
    this.terrService.getUserAssignedTerritories().subscribe(
      (response) => {
        this.territories = response;
      },
      (error) => {
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.form.controls.terr.value) {
      this.terrService.updateSelectedTerritory(this.form.controls.terr.value).subscribe(
        response => {
          this.terrService.saveTerritoryNumber(this.form.controls.terr.value);
          this.dialogRef.close();
          this.refreshPage();
        }, error => {
          if (error.status === 404) {
            this.msgBanner.addMsgError(this.messageList, error.error.message);
          } else {
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
          }
          this.showNotification = true;
        }
      );
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  hasTerritoryChanged() {
    return this.data.terr === this.form.controls.terr.value;
  }

  refreshPage() {
    window.location.reload();
  }
}
