import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { Associate } from '../../../shared/models/associates/associate.model';
import { AssociateService } from '../../../core/services/associates.service';
import { ScheduleType } from '../../../shared/models/schedule/schedule.model';
import { AssociateDetailsPageRedirectService } from '../../../core/services/previous-page-redirect.service';
import {LoginService} from '../../../core/services/login.service';
import {TitleService} from '../../../core/services/title.service';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';

@Component({
  selector: 'app-training-history',
  templateUrl: './training-history.component.html',
  styleUrls: [
    './training-history.component.scss',
    '../associate-details/associate-details.component.scss',
  ],
})
export class TrainingHistoryComponent implements OnInit {
  merchandiserId: number;
  page: number;
  scheduleType = ScheduleType.TRAINING;
  associate: Associate;
  isRedirectFromAssociateDetails: boolean;

  // error list
  messageList = [];
  showNotification = false;

  checkboxValue: number = 2;
  complete = false;
  incomplete = false;

  checkboxOptions = [
    { value: 0, name: 'Incomplete', isChecked: this.incomplete },
    { value: 1, name: 'Complete', isChecked: this.complete },
  ];

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.isRedirectFromAssociateDetails) {
      this.redirectToAssociatesPageService.setRedirectToAssociateDetails();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private associateService: AssociateService,
    private msgBanner: MsgBannerService,
    private loginSvc: LoginService,
    private titleSvc: TitleService,
    private redirectToAssociatesPageService: AssociateDetailsPageRedirectService
  ) {}

  ngOnInit(): void {
    if (this.loginSvc.isUkUser()) {
      this.titleSvc.updateTitle(CountryGlobalVariables.UK.trainingHistory);
    } else {
      this.titleSvc.updateTitle(CountryGlobalVariables.US.trainingHistory);
    }

    this.route.queryParams.subscribe((params) => {
      this.merchandiserId = params.merchandiserId;
      this.page = Number(params.page);
      this.isRedirectFromAssociateDetails = params.isAssociatesRedirect ? params.isAssociatesRedirect : false;
    });

    this.getAssociateData();
  }

  getAssociateData() {
    this.associateService.getAssociateById(this.merchandiserId).subscribe(
      (response) => {
        this.associate = response;
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(
            this.messageList,
            'An error has occurred. Please contact your administrator!'
          );
        }
        this.showNotification = true;
      }
    );
  }

  valueChange(checkboxValue, $event) {
    this.complete = (checkboxValue == 0 && $event.checked) ? true : false;
    this.incomplete = (checkboxValue == 1 && $event.checked) ? true : false;

    if (!this.complete && !this.incomplete) {
      this.checkboxValue = 2;
    } else {
      this.checkboxValue = checkboxValue;
    }
  }

  changeCheck(checkboxValue) {
    switch (checkboxValue) {
      case 0:
        return this.complete;
      case 1:
        return this.incomplete;
      default:
        return true;
    }
  }

  displayError(event: string) {
    if(event == null) {
      this.messageList = [];
      this.showNotification = false;
    }
    else {
      this.msgBanner.addMsgWarning(this.messageList, event);
      this.showNotification = true;
    }
  }
}
