export class AreaSupervisor {
  id: number;
  areaSupervisorId: number;
  areaSupervisorName: string;
  areaSupervisorUsername: string;
  isManual: number;
  isDisabled: string;
  role: string;
  country: string;
  territories: AreaSupervisorTerritory[];
  creatBy: string;
  creatDt: Date;
  updtBy: string;
  updtDt: Date;
}

export class AreaSupervisorTerritory {
  id: number;
  territoryNumber: number;
}

export const US_SUPERVISOR_ROLES = [
  { value: 'DM', description: 'DM'},
  { value: 'FM', description: 'FM'},
  { value: 'IS', description: 'IS'},
  { value: 'TRS', description: 'TRS'}
];

export const UK_SUPERVISOR_ROLES = [
  { value: 'MM', description: 'MM'},
  { value: 'RMM', description: 'RMM'},
  { value: 'TC', description: 'TC'}
];

