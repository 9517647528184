import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

import { UserAuthService } from './../../shared/services/user-auth.service';
import { RolesEnum } from './../../shared/enum/role.enum';

export interface DirectiveParam {
	excludedRoles: RolesEnum[];
}

@Directive({ selector: '[appUserRole]' })
export class UserRoleDirective implements OnInit {
	constructor(
		private templateRef: TemplateRef<any>,
		private userAuthService: UserAuthService,
		private viewContainer: ViewContainerRef
	) { }

	params = {
		excludedRoles: [],
	};

	@Input('appUserRole')
	set appUserRole(params: DirectiveParam) {
		if (params) {
			this.params.excludedRoles = params.excludedRoles ? params.excludedRoles : [];
		}
	}

	ngOnInit() {
		let roleAccess = true;

		if (this.params.excludedRoles.length > 0 &&
			this.params.excludedRoles.some(excludedRole => this.userAuthService.getRoles().includes(excludedRole))) {
			roleAccess = false;
		}

		let hasAccess = this.userAuthService.isAuthenticated() && roleAccess;

		if (hasAccess) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}