import { MSALInterceptorConfigFactory } from '../../modules/app.module';
import { RefreshTokenResponse } from '../../shared/models/ldap/refresh-token-response.model';
import { UserAuthService } from '../../shared/services/user-auth.service';
import { RefreshTokenRequest } from '../../shared/models/ldap/refresh-token-request.model';
import { LdapAuthService } from '../services/ldap-auth.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { MsalBroadcastService, MsalInterceptor, MsalService } from "@azure/msal-angular";
import { catchError, Observable, switchMap, throwError } from "rxjs";
import { DOCUMENT, Location } from '@angular/common';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class MsalLdapInterceptor implements HttpInterceptor {
	private msalInterceptor: MsalInterceptor;
	private isRefreshing = false;

	constructor(
		@Inject(DOCUMENT) private document: any,
		private userAuthService: UserAuthService,
		private ldapAuthService: LdapAuthService,
		private injector: Injector
	) {
		const authService = this.injector.get(MsalService);
		const location = this.injector.get(Location);
		const msalBroadcastService = this.injector.get(MsalBroadcastService);

		this.msalInterceptor = new MsalInterceptor(MSALInterceptorConfigFactory(), authService, location, msalBroadcastService, document);
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /* Bypass the app-versional request */
    if (request.url.includes('/app-version/ui')) {
      return next.handle(request.clone());
    }

		/* Trigger custom authorization if it's an LDAP user */
		if (this.userAuthService.hasLdapCredentials()) {
			const accessToken = this.userAuthService.getLdapToken();

			const headers = request.headers.set(
				TOKEN_HEADER_KEY,
				`Bearer ${accessToken}`
			);
			const requestClone = request.clone({ headers });

			return next.handle(requestClone)
				.pipe(
					catchError((error: HttpErrorResponse) => {
						/* If the call fails due to an expired token try to refresh it and resume the initial call */
						if (error.status === 401 && this.userAuthService.hasLdapCredentials()) {
							if (!this.isRefreshing) {
								this.isRefreshing = true;

								let refreshRequest = { refreshToken: this.userAuthService.getLdapRefreshToken() } as RefreshTokenRequest;

								return this.ldapAuthService.refreshToken(refreshRequest).pipe(
									switchMap((refreshResponse: RefreshTokenResponse) => {
										this.isRefreshing = false;

										/* Set the new access token on the request and also into the local storage */
										this.userAuthService.setLdapToken(refreshResponse.accessToken)

										let authRequest = request.clone({
											headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${refreshResponse.accessToken}`)
										});
										return next.handle(authRequest);
									}),
									catchError((error: HttpErrorResponse) => {
										return this.logout(error);
									})
								);
							} else {
								return this.logout(error);
							}
						} else {
							return throwError(() => error);
						}
					})
				);
		}

		/* Delegate to MSAL Interceptor if not using LDAP credentials */
		return this.msalInterceptor.intercept(request, next);
	}

	logout(error) {
		this.isRefreshing = false;

		/* If the refresh token fails, log the user out */
		console.log('Failed to refresh the token:', error);
		this.userAuthService.logout();

		return throwError(() => error);
	}
}