<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="event"
>
  <div
    fxLayout="row"
    class="event-row"
    fxLayoutGap="10px"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="icon-square"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start"
      >
        <mat-icon *ngIf="event.completedStatus === 0" class="completedStatus incomplete">timelapse</mat-icon>
        <mat-icon *ngIf="event.completedStatus === 1" fontSet="material-icons-outlined" class="completedStatus completed">check_circle</mat-icon>

        <mat-icon *ngIf="event.type === myEventType.TRAINING" class="icon">people_outline</mat-icon>
        <mat-icon *ngIf="event.type === myEventType.AUDIT" class="icon">store</mat-icon>
        <mat-icon *ngIf="event.type === myEventType.APPOINTMENT" class="icon">event</mat-icon>
        <span *ngIf="event.type === myEventType.OUTLOOK && scheduled" class="icon icon-outlook"></span>

        <mat-icon *ngIf="scheduled && (conflict || isAllDayEvent)" class="conflict">priority_high</mat-icon>
      </div>
      <div *ngIf="scheduled && !isAllDayEvent" class="time">{{ event?.time }}</div>
      <div *ngIf="scheduled && isAllDayEvent" class="time">All day</div>
    </div>

    <mat-divider [vertical]="true"></mat-divider>

    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      class="details"
    >
      <div
        *ngIf="event.type === myEventType.TRAINING"
      >
        <div class="title">{{ event.merchandiserName }}</div>
        <div> {{ event.accountName }}
              <span *ngIf="event.chain && event.str">- #{{ event.chain }}-{{ event.str }}</span>
              <span *ngIf="event.shipTo">({{ event.shipTo }})</span></div>
        <div> {{ event.address }}
              <span *ngIf="event.address && (event.zone || event.route)">,</span>
              <span *ngIf="event.zone"> Zone {{ event.zone }}</span>
              <span *ngIf="event.zone && event.route">,</span>
              <span *ngIf="event.route"> Route {{ event.route }}</span>
        </div>
      </div>

      <div
        *ngIf="event.type === myEventType.AUDIT"
      >
        <div class="title">{{ event.accountName }}</div>
        <div>#{{ event.chain }}-{{ event.str }} <span *ngIf="event.shipTo">({{ event.shipTo }})</span></div>
        <div>{{ event.address }}
          <span *ngIf="event.address && (event.zone || event.route)">,</span>
          <span *ngIf="event.zone"> Zone {{ event.zone }}</span>
          <span *ngIf="event.zone && event.route">,</span>
          <span *ngIf="event.route"> Route {{ event.route }}</span>
        </div>
      </div>

      <div
        *ngIf="event.type === myEventType.APPOINTMENT"
      >
        <div class="title">{{ event.appointmentName }}</div>
        <div>{{ event.accountName }} - #{{ event.chain }}-{{ event.str }} <span *ngIf="event.shipTo">({{ event.shipTo }})</span></div>
        <div>{{ event.address }}
          <span *ngIf="event.address && (event.zone || event.route)">,</span>
          <span *ngIf="event.zone"> Zone {{ event.zone }}</span>
          <span *ngIf="event.zone && event.route">,</span>
          <span *ngIf="event.route"> Route {{ event.route }}</span>
        </div>
      </div>

      <div
        *ngIf="event.type === myEventType.OUTLOOK && scheduled"
      >
        <div class="title">{{ event.subject }}</div>
        <div>{{ event.address }}</div>
      </div>

      <div *ngIf="!ownedByMeFilter">Owned by: {{ event.ownedByName }}</div>
    </div>
  </div>

  <div>
    <button
      *ngIf="event.type !== myEventType.OUTLOOK"
      mat-icon-button
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="!event.isDisabled && event.completedStatus != 1" (click)="editSchedule()">
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
      <button mat-menu-item *ngIf="event.completedStatus != 1" (click)="onRemoveSchedule()">
        <mat-icon>close</mat-icon>
        <span>Remove</span>
      </button>
      <button
        *ngIf="event.type === myEventType.TRAINING && !event.isDisabled"
        mat-menu-item
        (click)="onStartTrainingAudit()"
      >
        <mat-icon>play_arrow</mat-icon>
        <span> {{ getTrainingText() }} </span>
      </button>
      <button
        *ngIf="event.type === myEventType.AUDIT && !event.isDisabled"
        mat-menu-item
        (click)="onStartTrainingAudit()"
      >
        <mat-icon>play_arrow</mat-icon>
        <span>Audit</span>
      </button>
      <button mat-menu-item *ngIf="event.lat && event.lon" (click)="doShowDirections()">
        <mat-icon>directions_alt</mat-icon>
        <span>Directions</span>
      </button>
      <button
        *ngIf="event.type === myEventType.TRAINING && !event.isDisabled"
        mat-menu-item
        (click)="goToAssociateDetails()"
      >
        <mat-icon>supervisor_account</mat-icon>
        <span
          *ngIf="this.isUkUser() === true"> Merchandiser details </span>
        <span
          *ngIf="this.isUkUser() === false">Associate details</span>
      </button>
      <button
        *ngIf="(event.type === myEventType.AUDIT || event.type === myEventType.APPOINTMENT )
        && !event.isDisabled"
        mat-menu-item
        (click)="viewStoreDetails()"
      >
        <mat-icon>store</mat-icon>
        <span>Store details</span>
      </button>
    </mat-menu>
  </div>
</div>
