import { ActuatorService } from './../services/actuator.service';
import { Login } from './../../shared/models/login.model';
import { UserAuthService } from './../../shared/services/user-auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { LoginService } from '../services/login.service';
import { TerritoryService } from '../services/territory-management.service';
import { NotFoundOrAuthorizedService } from '../services/not-found-or-authorized.service';
import { ChatClientService } from 'stream-chat-angular';
import { RolesEnum } from '../../shared/enum/role.enum';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {
	constructor(
		private router: Router,
		private terrSvc: TerritoryService,
		private notFoundOrAuthorizedService: NotFoundOrAuthorizedService,
		private loginSvc: LoginService,
		private userAuthService: UserAuthService,
		private actuatorService: ActuatorService,
		public getStreamSvc: ChatClientService,
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.userAuthService.isAuthenticated()) {
			const loggedIn = this.loginSvc._loggedIn;
			if (loggedIn === true) {
				return this.handleNavigation(state);
			} else {
				this.notFoundOrAuthorizedService.show();
				return this.isLoggedIn(state);
			}
		} else {
			this.userAuthService.logout();
			return false;
		}
	}

	isLoggedIn(state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.loginSvc.login()
			.pipe(
				map((res: Login) => {
					this.loginSvc._loggedIn = true;
					this.loginSvc._admin = res.isAdmin;
					this.loginSvc._country = res.country;
					this.loginSvc._eid = res.eid;
					this.loginSvc._name = res.name;
					this.loginSvc._role = res.role;
					this.loginSvc.setIsChatUser(res.isChatUser);

					if (res.territory) {
						this.terrSvc.saveTerritoryNumber(res.territory);
					} else {
						this.terrSvc.deleteTerritoryNumber();
					}
	
					this.terrSvc.loadUserTerritory();
					this.notFoundOrAuthorizedService.hide();

					this.userAuthService.setLoginComplete(true);
					
					return this.handleNavigation(state);
				}),
         		catchError((error: any) => {
					this.loginSvc._loggedIn = false;
					let errorMsg = '';

					if (error.status === 401 || error.status === 403) {
						errorMsg = error.error.message;
					} else {
						errorMsg = null;
					}

					this.userAuthService.setLoginComplete(true);
					
					/** Verify if the backend services are UP */
					if ([401, 403].indexOf(error?.status) == -1)
						this.actuatorService.checkServiceAvailability();
					else
						this.router.navigate(['not-authorized'], { state: { data: errorMsg } });
					return of(false);
				})
			)
	}

	handleNavigation(state: RouterStateSnapshot): boolean | UrlTree {
		if (state?.url === '/admin' && !this.loginSvc._admin) {
			this.router.navigate(['not-authorized']);
			return false;
		}

		if ((state?.url === '/non-serviced' || state?.url === '/chat') && this.loginSvc.isUkUser()) {
			this.router.navigate(['not-authorized']);
			return false;
		}

		if (state?.url === '/chat' && (this.loginSvc._role === RolesEnum.IS || !this.loginSvc._isChatUser)) {
			this.router.navigate(['not-authorized']);
			return false;
		}

		return true;
	}
}
