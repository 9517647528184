import {Country} from '../enum/country.enum';

export const SCHEDULE_CONTENTS = {
  TIME_SLOTS: [
    {
      text: '12:30 AM',
      value: '00:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '01:00 AM',
      value: '01:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '01:30 AM',
      value: '01:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '02:00 AM',
      value: '02:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '02:30 AM',
      value: '02:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '3:00 AM',
      value: '03:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '3:30 AM',
      value: '03:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '4:00 AM',
      value: '04:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '4:30 AM',
      value: '04:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '05:00 AM',
      value: '05:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '05:30 AM',
      value: '05:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '06:00 AM',
      value: '06:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '06:30 AM',
      value: '06:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '07:00 AM',
      value: '07:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '07:30 AM',
      value: '07:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '08:00 AM',
      value: '08:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '08:30 AM',
      value: '08:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '09:00 AM',
      value: '09:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '09:30 AM',
      value: '09:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '10:00 AM',
      value: '10:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '10:30 AM',
      value: '10:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '11:00 AM',
      value: '11:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '11:30 AM',
      value: '11:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '12:00 PM',
      value: '12:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '12:30 PM',
      value: '12:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '01:00 PM',
      value: '13:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '01:30 PM',
      value: '13:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '02:00 PM',
      value: '14:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '02:30 PM',
      value: '14:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '03:00 PM',
      value: '15:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '03:30 PM',
      value: '15:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '04:00 PM',
      value: '16:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '04:30 PM',
      value: '16:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '05:00 PM',
      value: '17:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '05:30 PM',
      value: '17:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '06:00 PM',
      value: '18:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '06:30 PM',
      value: '18:30',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '07:00 PM',
      value: '19:00',
      country: [ Country.US.valueOf(), Country.UK.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '07:30 PM',
      value: '19:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '08:00 PM',
      value: '20:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '08:30 PM',
      value: '20:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '09:00 PM',
      value: '21:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '09:30 PM',
      value: '21:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '10:00 PM',
      value: '22:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '10:30 PM',
      value: '22:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '11:00 PM',
      value: '23:00',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    },
    {
      text: '11:30 PM',
      value: '23:30',
      country: [ Country.US.valueOf(), Country.CA.valueOf() ]
    }
  ],
  MAX_PURPOSES_OF_CALL: 3,
};

export const SCHEDULE_TYPE = {
  SCHEDULED: 'scheduled',
  UNSCHEDULED: 'unscheduled'
};
