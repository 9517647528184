import { RouterModule, Routes } from '@angular/router';

import { ServicedStoresComponent } from './serviced-stores/serviced-stores.component';
import { NonServicedStoresComponent } from './non-serviced-stores/non-serviced-stores.component';
import { AssociatesComponent } from './associates/associates.component';
import { StoreDetailsComponent } from './store-details/store-details.component';
import { ScheduleAuditComponent } from './schedule-audit/schedule-audit.component';
import { ScheduleTrainingComponent } from './schedule-training/schedule-training.component';
import { TrainingComponent } from './training/training.component';
import { AuditComponent } from './audit/audit.component';
import { AuditTrainingHistoryComponent } from './audit-training-history/audit-training-history.component';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { TrainingHistoryComponent } from './training-history/training-history.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { AdminComponent } from './admin/admin.component';
import { ScheduleAppointmentComponent } from './schedule-appointment/schedule-appointment.component';
import { ZoneUpdateComponent } from './zone-update/zone-update.component';

import { LoginGuard } from '../../core/guards/login.guard';
import { FeatureFlagGuard } from '../../core/guards/feature-flag.guard';
import { MsalLdapGuard } from './../../core/guards/msal-ldap.guard';
import { RoleGuard } from './../../core/guards/role.guard';
import { AuthGuard } from '../../core/guards/auth.guard';

import { RolesEnum } from './../../shared/enum/role.enum';


const moduleRoutes: Routes = [
	{
		path: 'serviced',
		component: ServicedStoresComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'non-serviced',
		component: NonServicedStoresComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'serviced-details',
		component: StoreDetailsComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'non-serviced-details',
		component: StoreDetailsComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'associates',
		component: AssociatesComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'schedule-audit',
		component: ScheduleAuditComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard],
		data: {
			excludedRoles: [RolesEnum.TRS]
		}
	},
	{
		path: 'training',
		component: TrainingComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'schedule-training',
		component: ScheduleTrainingComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard],
		data: {
			excludedRoles: [RolesEnum.TRS]
		}
	},
	{
		path: 'audit',
		component: AuditComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'audit-training-history',
		component: AuditTrainingHistoryComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'audit-history',
		component: AuditHistoryComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'training-history',
		component: TrainingHistoryComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'my-events',
		component: MyEventsComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard],
		data: {
			excludedRoles: [RolesEnum.TRS]
		}
	},
	{
		path: 'schedule-appointment',
		component: ScheduleAppointmentComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard],
		data: {
			excludedRoles: [RolesEnum.TRS]
		}
	},
	{
		path: 'admin',
		component: AdminComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard]
	},
	{
		path: 'update-zone',
		component: ZoneUpdateComponent,
		canActivate: [AuthGuard, MsalLdapGuard, LoginGuard, FeatureFlagGuard, RoleGuard],
		data: {
			excludedRoles: [RolesEnum.TRS]
		}
	}
];

export const PagesRouting = RouterModule.forChild(moduleRoutes);
