import { UserAuthService } from './../../services/user-auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-not-available',
	templateUrl: './not-available.component.html',
	styleUrls: ['./not-available.component.scss']
})
export class NotAvailableComponent implements OnInit {

	constructor(
		private router: Router,
		private userService: UserAuthService
	) { }

	ngOnInit(): void {
	}

	public goHome(): void {
		let homeRoute
		let returnUrl = history.state?.returnUrl

		if (returnUrl)
			homeRoute = returnUrl;
		else
			homeRoute = this.userService.hasLdapCredentials() ? '/ldap-login' : '/'	

		this.router.navigate([homeRoute]);
	}
}
