<app-msg-banner
  *ngIf="showNotification"
  [msgList]="messageList"
  (msgListChange)="messageList = $event"
></app-msg-banner>

<mat-card
  fxLayout="row"
  fxLayoutAlign="start"
  fxLayoutGap="20px"
  fxLayout.lt-md="column"
  fxLayoutGap.lt-md="0px"
>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="30px"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
    fxLayoutGap.lt-md="0px"
  >
    <div
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="center center"
      *ngIf="associate"
    >
      <mat-icon class="header-icon">account_circle</mat-icon>
      <div
        fxLayout="column"
      >
        <div>{{ associate.fullName }}</div>
        <div>{{ associate.userName }}</div>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="start center"
      class="width-100"
    >
      <mat-icon class="header-icon">storefront</mat-icon>
      <mat-form-field fxFlex class="store-dropdown">
        <mat-label>Select a store</mat-label>
        <mat-select
          [formControl]="storeControl"
          (selectionChange)="onStoreChange($event)"
          required
        >
          <mat-option class="default-height">
            <ngx-mat-select-search
              [formControl]="storeFilterCtrl"
              placeholderLabel="Find store ..."
              noEntriesFoundLabel="No matching store found"
            >
            <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let store of filteredStores | async"
            [value]="store"
            class="multiline-mat-option"
          >
            <div>{{ store.name }} {{ store.chain }}-{{ store.store }} <span *ngIf="store.shipTo">({{ store.shipTo }})</span> </div>
            <div> {{ store.address }} </div>
            <div *ngIf="store.isMerchAssigned" class="assigned-msg"> ASSIGNED </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>

<h2
  class="sub-title"
>
  <div
    *ngIf="purposes && purposes.length > 0"
  >
    <div class="purpose-title">Purposes: </div>
    <div class="purpose-desc">{{ buildPurposes() }}</div>
  </div>
</h2>

<mat-accordion
  multi
  *ngIf="sectionArray?.length > 0 && form"
>
  <mat-expansion-panel
    *ngFor="let section of sectionArray"
    [expanded]="section.expanded"
  >
    <mat-expansion-panel-header
      fxLayout="row"
      fxLayoutAlign="space-between center"
      #toggle
    >
      <mat-panel-title>
        <div>{{ section.section }} {{ displayRequired(section.questions) ? '*' : '' }}</div>
      </mat-panel-title>
      <mat-icon
        *ngIf="displayInfo(section.section)"
        class="info-button"
        (click)="toggle._toggle(); openInfo(section.section)"
      >info_outline</mat-icon>
    </mat-expansion-panel-header>

    <div>
      <form [formGroup]="form">
        <div *ngFor="let formField of section.questions" class="form-group">
          <app-dynamic-form-input
            [input]="formField"
            [form]="form"
            [readOnly]="readOnly"
          ></app-dynamic-form-input>
        </div>
      </form>
    </div>

  </mat-expansion-panel>
</mat-accordion>

<div class="main-wrapper">
  <div class="floating-snap-btn-wrapper disable-select">
    <div class="fab-btn">
       <mat-icon>grid_view</mat-icon>
    </div>
    <ul>
      <li
      title = "Cancel"
      [ngClass]="{'disable-click': readOnly}"
      (click)="cancel()">
        <mat-icon >close</mat-icon>
      </li>
      <li
      title = "Save"
      [ngClass]="{'disable-click': readOnly || this.storeControl.invalid || associate == null}"
      (click)="onSave(false)">
        <mat-icon>save</mat-icon>
      </li>
      <li
      title = "Submit"
      [ngClass]="{'disable-click': readOnly || !validForms() || associate == null}"
      (click)="onSave(true)">
        <mat-icon>check</mat-icon>
      </li>
      <li
	  *ngIf="readOnly"
      title = "Email"
      (click)="onSendEmail()">
	  	<mat-icon>email</mat-icon>
      </li>
      <li
      title = "PDF"
      *ngIf="readOnly"
      (click)="onPdfExport()">
        <mat-icon>download</mat-icon>
      </li>
      <li
      title = "PDF"
      *ngIf="readOnly"
      (click)="onPdfExport()">
        <mat-icon>download</mat-icon>
      </li>
    </ul>
  </div>
</div>

<mat-card
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayoutGap="20px"
  fxLayout.lt-md="column"
  fxLayoutGap.lt-md="0px"
  class="mat-floaters"
>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start"
  >
    <button
      mat-raised-button
      class="btn btn-red"
      fxFlex="130px"
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      [disabled]="readOnly"
      (click)="cancel()"
    >Cancel</button>
    <button
      mat-raised-button
      class="btn btn-blue"
      fxFlex="130px"
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="onSave(false)"
      [disabled]="readOnly || this.storeControl.invalid || associate == null"
    >Save</button>
    <button
      mat-raised-button
      class="btn btn-blue"
      fxFlex="130px"
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="onSave(true)"
      [disabled]="readOnly || !validForms() || associate == null"
    >Submit</button>
    <button
      *ngIf="readOnly"
      mat-raised-button
      class="btn btn-blue"
      fxFlex="130px"
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="onSendEmail()"
    >Email</button>
    <button
      *ngIf="readOnly"
      mat-raised-button
      class="btn btn-blue"
      fxFlex="130px"
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="onPdfExport()"
    >Export PDF</button>
  </div>
</mat-card>
