import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Page } from '../../../shared/models/generic-filter-object.model';
import { IPagedResponse } from '../../../shared/models/IPageResponse';
import { AreaSupervisorService } from '../../../core/services/area-supervisor.service';
import { AreaSupervisor } from '../../../shared/models/admin/area-supervisor.model';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { Dialog } from '../../../shared/models/dialog';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { LoginService } from '../../../core/services/login.service';

@Component({ 
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    'employeeName',
    'employeeId',
    'territories',
    'role',
    'country',
    'manualEntry',
    'actions',
  ];

  pageEvent: PageEvent;
  totalRows = 0;

  showNotification = false;
  messageList = [];
  searchValue: string = null;
  checkboxValue: string = '';

  enabled = false;
  disabled = false;

  users: AreaSupervisor[] = [];
  dataSource = new MatTableDataSource<AreaSupervisor>();

  checkboxOptions = [
    { value: 'N', name: 'Enabled', isChecked: this.enabled },
    { value: 'Y', name: 'Disabled', isChecked: this.disabled },
  ];

  constructor(
    private dialog: MatDialog,
    private service: AreaSupervisorService,
    private msgBanner: MsgBannerService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.setDisplayedColumns();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getPortalUsers();
  }

  ngOnDestroy(): void {}

  getPortalUsers() {
    this.showNotification = false;
    
    let pageIndex = this.paginator.pageIndex ? this.paginator.pageIndex : 0;
    let pageSize = this.paginator.pageSize ? this.paginator.pageSize : 10;
    let pageable: Page = new Page(pageIndex, pageSize, [
      'areaSupervisorName,asc',
    ]);

    this.service
      .getSupervisors(pageable, this.searchValue, this.checkboxValue)
      .subscribe(
        (response: IPagedResponse<AreaSupervisor>) => {
          this.totalRows = response.totalElements;
          this.dataSource = new MatTableDataSource<AreaSupervisor>(
            response.content
          );
        },
        (error: any) => {
          if (error.status === 404) {
            this.msgBanner.addMsgError(this.messageList, error.error.message);
          } else {
            this.msgBanner.addMsgError(
              this.messageList,
              'An error has occurred. Please contact your administrator.'
            );
          }
          this.showNotification = true;
          this.dataSource = new MatTableDataSource<AreaSupervisor>();
          this.totalRows = 0;
        }
      );
  }

  openAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'user-dialog-class'
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'Add')
        this.displayConfirmationMessage('User was successfully added!');
    });
  }

  openEdit(userData: AreaSupervisor) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = userData;
    dialogConfig.panelClass = 'user-dialog-class'
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'Update')
        this.displayConfirmationMessage('User was successfully edited!');
    });
  }

  applyFilter(clearKeyUp?: boolean) {
    if (
      clearKeyUp &&
      this.searchValue != null &&
      this.searchValue.length == 0
    ) {
      this.paginator.pageIndex = 0;
      this.getPortalUsers();
    } else if (clearKeyUp == undefined) {
      this.paginator.pageIndex = 0;
      this.getPortalUsers();
    }
  }

  public getServerData(event?: PageEvent) {
    this.getPortalUsers();
    return event;
  }

  territoryString(territories: string[]) {
    if (territories) {
      return territories.join(', ');
    }
    return '';
  }

  valueChange(checkboxValue, $event) {
    this.enabled = (checkboxValue === 'N' && $event.checked) ? true : false;
    this.disabled = (checkboxValue === 'Y' && $event.checked) ? true : false;
    
    if (!this.enabled && !this.disabled) {
      this.checkboxValue = '';
    } else {
      this.checkboxValue = checkboxValue;
    }

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;

    this.getPortalUsers();
  }

  changeCheck(checkboxValue) {
    switch (checkboxValue) {
      case 'N':
        return this.enabled;
      case 'Y':
        return this.disabled;
      default:
        return true;
    }
  }

  toggleSupervisorStatus(supervisor: AreaSupervisor) {
    const action = supervisor?.isDisabled === 'N' ? 'Y' : 'N';
    const message =
      'Are you sure you want to ' +
      (action === 'N' ? 'enable' : 'disable') +
      ' this user?';

    this.dialog
      .open(ConfirmDialogComponent, {
        data: new Dialog(message, true, false, true),
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const updateSupervisor = supervisor;
          updateSupervisor.isDisabled = action;

          this.service.updateSupervisor(updateSupervisor).subscribe(
            (res) => {
              this.displayConfirmationMessage(
                'User was successfully ' +
                  (action === 'N' ? 'enable' : 'disable') +
                  'd!'
              );
            },
            (error) => {
              this.showNotification = true;
              this.msgBanner.addMsgError(
                this.messageList,
                'An error has occurred. Please contact your administrator.'
              );
            }
          );
        }
      });
  }

  displayConfirmationMessage(message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: message,
        confirmationRequired: false,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.getPortalUsers();
      return;
    });
  }

  setDisplayedColumns() {
    if (this.loginService.isUkUser()) {
      this.displayedColumns = [
        'employeeName', 'employeeId', 'territories',
        'role', 'manualEntry', 'actions',
      ]
    }
  }
}
