import { HttpBackend, HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from "@angular/router";

export interface HealthActuatorModel {
	status: ServiceStatus,
	groups: string[]
}

export enum ServiceStatus {
	UP = 'UP',
	DOWN = 'DOWN',
	OUT_OF_SERVICE = 'OUT_OF_SERVICE',
	UNKNOWN = 'UNKNOWN'
}

@Injectable({
	providedIn: 'root'
})
export class ActuatorService {
	private url = environment.apiResourceUri.replace('/api', '') + '/actuator';

	constructor(
		private http: HttpClient, 
		private httpBackend: HttpBackend, 
		private router: Router
	) { }

	checkServiceAvailability(returnUrl?: string) {
		/** In this way all the backend calls performed through this client will not be intercepted */
		this.httpBackend
			.handle(new HttpRequest('GET', this.url + '/health'))
			.pipe(
				map(
					(event: HttpEvent<HealthActuatorModel>) => {
						if (event?.type == HttpEventType.Sent) {
							return true;
						}
						if (event instanceof HttpResponse) {
							const healthActuatorModel: HealthActuatorModel = event.body;
							return healthActuatorModel.status === ServiceStatus.UP.valueOf();
						} else {
							return false;
						}
					}
				))
			.subscribe({
				next: (isUp) => {
					if (!isUp) {
						this.gotToNotAvailable(returnUrl);
					}
				},
				error: () => {
					this.gotToNotAvailable(returnUrl);
				}
			})
	}

	private gotToNotAvailable(returnUrl?: string) {
		if (returnUrl)
			this.router.navigate(["/not-available"], { state: { returnUrl: 'ldap-login' } });
		else
			this.router.navigate(['/not-available']);
	}
}