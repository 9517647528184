<mat-dialog-content>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="title">{{ data.topic }}</div>
    <button
      mat-icon-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    *ngFor="let inf of info?.INFO"
  >
    <div>{{ inf?.SUBTITLE }}</div>
    <ul>
      <li
        *ngFor="let item of inf?.ARRAY_LIST"
      >
        {{ item?.Q }}
        <div
          *ngIf="item?.SUBLIST"
        >
          <ul>
            <li
              *ngFor="let subItem of item?.SUBLIST"
            >{{ subItem }}</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</mat-dialog-content>
