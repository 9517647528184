export class ColumnDefinition {
    colDef: string;
    colHeader: string;
    colType: ColumnType;
    colWidthClass: string;
}

export enum ColumnType {
    STRING = 1,
    NUMBER = 2,
    DATE = 3,
    FULL_PERCENTAGE = 4,
    PARTIAL_PERCENTAGE = 5,
    RATIO = 6,
  }

export const UK_METRICS_TABLE_CONTENTS = {
    STORE_DISPLYED_COLUMNS: [
        { colDef: 'salesVsTarget', colHeader: 'Sales Performance', colType: ColumnType.FULL_PERCENTAGE, colWidthClass: 'w-120' },
        { colDef: 'latePayrollEntries', colHeader: 'Late Payroll Entries', colType: ColumnType.NUMBER, colWidthClass: 'w-120' },
        { colDef: 'timeCompliance', colHeader: 'Time Compliance', colType: ColumnType.RATIO, colWidthClass: 'w-120' },
        { colDef: 'visitCompliance', colHeader: 'Visit Compliance', colType: ColumnType.RATIO, colWidthClass: 'w-120' },
        { colDef: 'overdueCalls', colHeader: 'Overdue Calls', colType: ColumnType.NUMBER, colWidthClass: 'w-120' },
        { colDef: 'lastScanDate', colHeader: 'Last Empty Pocket Scan Date', colType: ColumnType.DATE, colWidthClass: 'w-120' },
        { colDef: 'missedScan', colHeader: 'Missed Scan', colType: ColumnType.STRING, colWidthClass: 'w-120' },
    ],
    MERCHANDISER_DISPLYED_COLUMNS: [
        { colDef: 'latePayrollEntries', colHeader: 'Late Payroll Entries', colType: ColumnType.NUMBER, colWidthClass: 'w-120' },
        { colDef: 'timeCompliance', colHeader: 'Time Compliance', colType: ColumnType.RATIO, colWidthClass: 'w-120' },
        { colDef: 'visitCompliance', colHeader: 'Visit Compliance', colType: ColumnType.RATIO, colWidthClass: 'w-120' },
        { colDef: 'overdueCalls', colHeader: 'Overdue Calls', colType: ColumnType.NUMBER, colWidthClass: 'w-120' },
        { colDef: 'lastScanDate', colHeader: 'Last Empty Pocket Scan Date', colType: ColumnType.DATE, colWidthClass: 'w-120' },
        { colDef: 'missedScan', colHeader: 'Missed Scan', colType: ColumnType.STRING, colWidthClass: 'w-120' },
    ],
}
