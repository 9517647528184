import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormControlService } from '../../../core/services/form-control.service';
import { FormSection } from '../../models/training-audit/form-section.model';
import { StoreDetails } from '../../models/stores/store-details.model';
import {Associate, isTheDefaultAssociate} from '../../models/associates/associate.model';
import { AssociateService } from '../../../core/services/associates.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { TrainingAuditService } from '../../../core/services/training-audit.service';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { PagesEnum } from '../../models/stores/pages.model';
import { ScheduleService } from '../../../core/services/schedule.service';
import { FormField } from '../../models/training-audit/form-field.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Dialog } from '../../models/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsgBannerService } from '../msg-banner/msg-banner.service';
import { ImageFile } from '../../models/training-audit/image-file.model';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { TrainingAudit, TrainingAuditData } from '../../models/training-audit/training-audit.model';
import { QuestionTypeEnum } from '../../enum/question-type.enum';
import { CloudfrontService } from '../../../core/services/cloudfront.service';
import { ScheduleResponse } from '../../models/schedule/schedule-response.model';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { TopicTypeEnum } from '../../enum/topic-type.enum';
import { Email, QuestionData, TopicData } from '../../models/training-audit/history-email.model';
import { EmailRecipientsComponent } from '../email-recipients/email-recipients.component';
import { AssociateDetailsPageRedirectService } from '../../../core/services/previous-page-redirect.service';
import { LoginService } from '../../../core/services/login.service';
import { CountryGlobalVariables } from '../../constants/country-global-variables';
import { audit, takeUntil } from 'rxjs/operators';
import { EmailGeneratorComponent } from './email-generator/email-generator.component';
import { ExportSchedulePdf } from '../../../core/services/export-pdf.service';
import { RolesEnum } from '../../enum/role.enum';
import { Router } from '@angular/router';

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
	providers: [DatePipe]
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
	readonly RolesEnum = RolesEnum;

	@Input() store: StoreDetails;
	@Input() associate: Associate;
	@Input() sections: Observable<FormSection[]>;
	@Input() scheduleId: string;
	@Input() page: PagesEnum;
	@Input() trainingAudit: Observable<TrainingAudit>;
	@Input() isRedirectFromAssociateDetails: Observable<boolean>;

	@Input() stores: Observable<StoreDetails[]>;

	protected _onDestroy = new Subject<void>();

	storeFilterCtrl: UntypedFormControl = new UntypedFormControl();
	filteredStores: ReplaySubject<StoreDetails[]> = new ReplaySubject<StoreDetails[]>();

	sectionArray: FormSection[] = [];
	storesArray: StoreDetails[] = [];
	form: UntypedFormGroup;
	data: TrainingAudit;
	storeControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
	purposes: string[] = [];

	readOnly = false;

	// error list
	messageList = [];
	showNotification = false;

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		if (this.isRedirectFromAssociateDetails) {
			this.redirectToAssociatesPageService.setRedirectToAssociateDetails();
		}
	}

	constructor(private formControlService: FormControlService,
		private trainingAuditService: TrainingAuditService,
		private terrService: TerritoryService,
		private scheduleService: ScheduleService,
		private associateService: AssociateService,
		private cloudfrontService: CloudfrontService,
		private msgBanner: MsgBannerService,
		private _location: Location,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		public datePipe: DatePipe,
		private loginSvc: LoginService,
		private redirectToAssociatesPageService: AssociateDetailsPageRedirectService,
		private exportPdf: ExportSchedulePdf,
		private router: Router
	) { }

	ngOnInit(): void {
		this.initFloaterButton();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['sections']?.currentValue) {
			this.loadSections();
		}

		if (changes['stores']?.currentValue) {
			this.loadStores();
		}

		if (changes['scheduleId']) {
			this.loadPurposes();
		}
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	loadPurposes() {
		if (this.scheduleId == null)
			return;

		this.scheduleService.findPurposes(this.scheduleId).subscribe(
			response => {
				this.purposes = response;
			});
	}

	buildPurposes() {
		return this.purposes?.join(', ');
	}

	loadData() {
		this.showNotification = false;
		Promise.all(this.cloudfrontService.getSignedUrlAsPromise()).then(r => {
			this.initUi();
		}).catch(err => {
			if (err.status === 500) {
				this.msgBanner.addMsgError(this.messageList, err.error.message);
			} else {
				this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
			}
			this.showNotification = true;
		});
	}

	initUi() {
		this.setUpStore(this.data.retailerId);

		this.readOnly = this.data.isReadOnly;
		this.disableForms();

		this.initForm();
		this.collapseNotCompletedTopics();
	}

	loadTrainingAudits() {
		this.showNotification = false;
		this.trainingAudit.subscribe(response => {
			this.data = response;
			if (this.page === this.pagesEnum.ASSOCIATES) {
				this.getAssociateDetailsById();
			}

			if (this.hasPhotos) {
				this.loadData();
			} else {
				this.initUi();
			}
		}, error => {
			if (error.status === 404) {
				this.msgBanner.addMsgError(this.messageList, error.error.message);
			} else {
				this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
			}
			this.showNotification = true;
		});
	}

	get hasPhotos(): boolean {
		let hasPhotos = false;
		this.data.data.forEach(d => {
			if (d.photos && d.photos.length > 0) {
				hasPhotos = true;
			}
		});

		return hasPhotos;
	}

	collapseNotCompletedTopics() {
		this.sectionArray.forEach(s => {
			let count = 0;
			s.questions.forEach(q => {
				const idx = this.data.data.findIndex(d => d.questionId === q.id);
				if (idx !== -1) {
					count++;
				}
			});

			s.expanded = count > 0;
		});
	}

	initForm() {
		this.data.data.forEach((d: TrainingAuditData) => {
			if (!this.form.controls.hasOwnProperty(d.questionId))
				return;
			if (d.value !== null) {
				const isCheckbox = this.checkIfCheckbox(d);
				if (isCheckbox) {
					this.form.controls[d.questionId].setValue(true);
				} else if (QuestionTypeEnum.MULTISELECT == this.getQuestionElementType(d.questionId)) {
					this.form.controls[d.questionId].setValue(d.value.split(','))
				} else {
					this.form.controls[d.questionId].setValue(d.value);
				}
			} else {
				const dat: ImageFile[] = [];
				d.photos.forEach(p => {
					dat.push({
						id: p.id,
						file: null,
						res: null,
						name: p.photoName,
						success: false,
						progress: 0,
						deleted: false
					});
				});

				this.form.controls[d.questionId].setValue(dat);
			}
		});
	}

	checkIfCheckbox(d) {
		let isCheckbox = false;
		this.sectionArray.forEach(s => {
			const idx = s.questions.findIndex(q => q.id === d.questionId);
			if (idx !== -1 && s.questions[idx].questionElementType === QuestionTypeEnum.CHECKBOX) {
				isCheckbox = true;
			}
		});
		return isCheckbox;
	}

	setUpStore(retailerId: number) {
		const idx = this.storesArray.findIndex(s => s.id === retailerId);
		if (idx !== -1) {
			this.initStoreControl(idx);
		}
	}

	initStoreControl(idx: number) {
		this.storeControl = new UntypedFormControl(this.storesArray[idx], Validators.required);
		if (this.page !== this.pagesEnum.ASSOCIATES) {
			this.getAssociateDetails({
				value: {
					id: this.storesArray[idx].id
				}
			});
		}
	}

	loadSections() {
		this.sections.subscribe(response => {
			this.sectionArray = response;
			this.form = this.formControlService.toFormGroup(this.sectionArray);
		});
	}

	loadStores() {
		this.stores.subscribe(response => {
			this.storesArray = response;
			this.initStoreSearchFilter();

			if (this.store) {
				const idx = this.storesArray.findIndex(s => s.id === this.store.id);
				if (idx !== -1) {
					this.initStoreControl(idx);
				}
			}

			if (this.trainingAudit) {
				this.loadTrainingAudits();
			} else {
				this.data = new TrainingAudit();
			}
		}, error => {
			if (error.status === 404 || error.status === 400) {
				this.msgBanner.addMsgError(this.messageList, error.error.message);
			} else {
				this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
			}
			this.showNotification = true;
		});
	}

	get pagesEnum() {
		return PagesEnum;
	}

	onStoreChange(event: any) {
		if (this.page === this.pagesEnum.ASSOCIATES) {
			return;
		}

		// for serviced / non-serviced stores reload associate details
		this.getAssociateDetails(event);
	}

	getAssociateDetails(event: any) {
		this.showNotification = false;
		this.messageList = [];
		this.associate = null;
		this.associateService.getAssociateByRetailerId(event.value.id).subscribe(
			response => {
				this.associate = response;
			}, error => {
				if (error.status === 404) {
					this.msgBanner.addMsgWarning(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
				}
				this.showNotification = true;
			}
		);
	}

	getAssociateDetailsById() {
		this.showNotification = false;
		this.associateService.getAssociateById(this.data.merchandiserId).subscribe(
			response => {
				this.associate = response;
			}, error => {
				if (error.status === 404) {
					this.msgBanner.addMsgWarning(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
				}
				this.showNotification = true;
			}
		);
	}

	getFiles(): ImageFile[] {
		const files: ImageFile[] = [];

		const values = this.form.value;
		Object.keys(values).map(function (key) {
			if (values[key] && values[key] !== null && values[key] !== undefined && values[key] !== '' && values[key] instanceof Array) {
				values[key].forEach(v => {
					files.push(v);
				});
			}
		});

		return files;
	}

	submit() {
		const isAudit = this.page !== PagesEnum.ASSOCIATES;
		const instance = this;

		this.scheduleService.submit(this.scheduleId).subscribe({
			next(response: ScheduleResponse) {
				instance.readOnly = response.readOnly === 1;
				const confirmationMessage = (isAudit ? 'Audit' : instance.training) + ' saved successfully!';

				instance.disableForms();

				instance.displayConfirmationMessage(confirmationMessage, true).afterClosed().subscribe(() => {
					if (isAudit) {
						instance.displayStartTraining();
					}
				});
			},
			error(error: any) {
				instance.msgBanner.addMsgError(instance.messageList, 'An error has occurred. Please contact your administrator!');
				instance.showNotification = true;
			},
		});
	}

	get training() {
		return this.loginSvc.isUkUser() ? CountryGlobalVariables.UK.training : CountryGlobalVariables.US.training;
	}

	disableForms() {
		if (this.readOnly) {
			this.storeControl.disable();
			this.form.disable();
		}
	}

	onSave(isSubmit: boolean) {
		this.showNotification = false;
		if ((!isSubmit && this.storeControl.invalid) || (isSubmit && !this.validForms())) {
			this.msgBanner.addMsgError(this.messageList, 'You must complete all required fields!');
			this.showNotification = true;
			return;
		}

		const data = this.buildData();
		data.forEach(d => {
			if (d.value !== null) {
				this.editValue(d);
			} else if (d.photos && d.photos.length > 0) {
				this.editPhotos(d);
			}
		});
		this.markAsDeleted(data);

		const terr = this.terrService.getTerritory();
		this.save(this.scheduleId, this.associate.id, this.storeControl.value.id, terr, isSubmit);
	}

	editPhotos(dat: any) {
		const idx = this.data.data.findIndex(d => d.questionId === dat.id);
		if (idx !== -1) {
			dat.photos.forEach(p => {
				if (p.id === null && p.deleted === false) {
					const idxPhoto = this.data.data[idx].photos.findIndex(ph => ph.photoName === p.name && ph.id === p.id);
					if (idxPhoto === -1) {
						this.addNewPhoto(idx, p);
					}
				} else if (p.id === null && p.deleted === true) {
					const idxPhoto = this.data.data[idx].photos.findIndex(ph => ph.photoName === p.name && ph.id === p.id);
					if (idxPhoto !== -1) {
						this.data.data[idx].photos[idxPhoto].deleted = p.deleted;
					}
				} else if (p.id !== null && p.deleted === false) {
					const idxPhoto = this.data.data[idx].photos.findIndex(ph => ph.id === p.id);
					if (idxPhoto !== -1) {
						this.data.data[idx].photos[idxPhoto].deleted = p.deleted;
						this.data.data[idx].photos[idxPhoto].photoName = p.name;
					}
				} else if (p.id !== null && p.deleted === true) {
					const idxPhoto = this.data.data[idx].photos.findIndex(ph => ph.id === p.id);
					if (idxPhoto !== -1) {
						this.data.data[idx].photos[idxPhoto].deleted = true;
					}
				}
			});
		} else {
			this.data.data.push({
				id: null,
				questionId: dat.id,
				value: null,
				photos: [],
				deleted: false
			});

			dat.photos.filter(p => p.deleted === false).forEach(p => {
				this.addNewPhoto(this.data.data.length - 1, p);
			});
		}
	}

	addNewPhoto(idx, p) {
		this.data.data[idx].photos.push({
			id: null,
			photoName: p.name,
			deleted: false
		});
	}

	editValue(dat: any) {
		const idx = this.data?.data?.findIndex(d => d.questionId === dat.id);
		if (idx !== -1) {
			this.data.data[idx].value = dat.value;
			this.data.data[idx].deleted = false;
		} else {
			this.data.data.push({
				id: null,
				questionId: dat.id,
				value: dat.value,
				photos: null,
				deleted: false
			});
		}
	}

	markAsDeleted(data: any[]) {
		const ids = data.map(d => d.id);
		this.data.data.forEach(d => {
			if (!ids.includes(d.questionId)) {
				d.deleted = true;
			}
		});
	}

	save(scheduleId: string, merchandiserId: number, retailerId: number, terr: number, isSubmit: boolean) {
		const isAudit = this.page !== PagesEnum.ASSOCIATES;

		this.trainingAuditService.saveResponses(this.scheduleId, merchandiserId, retailerId, terr, this.data, isAudit).subscribe({
			next: (response: ScheduleResponse) => {
				this.updateScheduleId(response.scheduleId);
				this.uploadPhoto(isSubmit);

				if (!isSubmit && response.outlookEventSuccess) {
					const confirmationMessage = (isAudit ? 'Audit' : this.training) + ' saved successfully!';
					this.displayConfirmationMessage(confirmationMessage, false).afterClosed().subscribe(() => {
						if (isAudit && isSubmit === true) { this.displayStartTraining(); }
					});
				}

				if (!isSubmit && response.outlookEventSuccess === false) {
					this.displayOutlookError();
				}
			},
			error: (error: any) => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
				}
				this.showNotification = true;
			}
		});
	}

	updateScheduleId(scheduleId: string) {
		this.scheduleId = scheduleId;
		this.trainingAudit = this.trainingAuditService.findTrainingAuditsByScheduleId(this.scheduleId);
	}

	uploadPhoto(isSubmit: boolean) {
		const files: ImageFile[] = this.getFiles();
		const idx = files.findIndex(f => f.success === false && f.id == null && f.deleted === false);
		if (files.length === 0 || idx === -1) {
			if (isSubmit === true) {
				this.submit();
			} else {
				this.loadTrainingAudits();
			}
			return;
		}

		this.dialog.open(FileUploadDialogComponent, {
			data: files.filter(f => f.success === false && f.id == null && f.deleted === false),
			disableClose: true
		}).afterClosed().subscribe(result => {
			if (result) {
				this.displaySnackBar('Photos uploaded successfully!');
			}
			if (result && isSubmit === true) {
				this.submit();
			} else if (isSubmit === false) {
				this.loadTrainingAudits();
			}
		});
	}

	validForms(): boolean {
		if (this.storeControl.invalid) {
			this.storeControl.markAllAsTouched();
			return false;
		}

		if (this.form?.invalid) {
			this.form.markAllAsTouched();
			return false;
		}

		return true;
	}

	buildData() {
		const data = [];
		const values = this.form.value;
		const _this = this;
		Object.keys(values).map(function (key) {
			if (values[key] && values[key] !== null && values[key] !== undefined && values[key] !== '') {
				data.push(_this.handleInput(values[key], Number(key)));
			}
		});
		return data;
	}

	handleInput(input, key: number): any {
		let value = null;
		if (typeof input === 'boolean' && input === true) {
			let checkboxValue = null;
			this.sectionArray.forEach(s => {
				s.questions.forEach(q => {
					if (q.id === key) {
						checkboxValue = q.question;
					}
				});
			});

			if (checkboxValue) {
				value = {
					id: key,
					value: checkboxValue
				};
			}
		} else if (input instanceof Array) {
			if (QuestionTypeEnum.MULTISELECT == this.getQuestionElementType(key)) {
				value = {
					id: key,
					value: input.join(',')
				};
			} else {
				value = {
					id: key,
					value: null,
					photos: input
				};
			}
		} else {
			value = {
				id: key,
				value: input
			};
		}

		return value;
	}

	getQuestionElementType(key: number) {
		const questions: FormField<any>[] = this.sectionArray.reduce((acc: FormField<any>[], section: FormSection) => {
			return acc.concat(section.questions);
		}, []);

		const question: FormField<any> | undefined = questions.find((q: FormField<any>) => q.id === key);

		return question ? question.questionElementType : undefined;
	}

	cancel() {
		this.dialog.open(ConfirmDialogComponent, {
			data: new Dialog('Are you sure you want to discard all changes?', true, false, true),
			disableClose: true
		}).afterClosed().subscribe(result => {
			if (result === true) {
				this._location.back();
			}
		});
	}

	displayRequired(questions: FormField<any>[]) {
		let value = false;
		questions.forEach(q => {
			if (q.isRequired === 1) {
				value = true;
			}
		});
		return value;
	}

	private displaySnackBar(msg: string) {
		this.snackBar.open(msg, 'Close', {
			duration: 10 * 1000,
			horizontalPosition: 'center',
			verticalPosition: 'top'
		});
	}

	private goToTraining() {

		const data = {
			associate: this.associate,
			store: this.store,
			page: PagesEnum.ASSOCIATES,
			isRedirectFromAssociateDetails: false
		};
		this.router.navigate(['training'], { state: { data } });
	}

	displayStartTraining() {
    if (isTheDefaultAssociate(this.associate)) { return; }

		const trainingDialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: 'Do you want to start a training with the assigned merchandiser?',
				confirmationRequired: false,
				noButtonShow: true,
				yesButtonShow: true
			},
		});

		trainingDialogRef.afterClosed().subscribe(
			(acceptTraining: boolean) => {
				if (acceptTraining) {
					this.goToTraining();
				}
			});
	}

	displayConfirmationMessage(message: string, loadTrainingAudits: boolean) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: message,
				confirmationRequired: false
			},
		});

		if (loadTrainingAudits) {
			dialogRef.afterClosed().subscribe(
				(dialogResult) => {
					this.loadTrainingAudits();
				});
		}

		return dialogRef;
	}

	displayOutlookError() {
		let message = (this.page === this.pagesEnum.ASSOCIATES ? this.training : 'Audit') +
			' saved successfully, but the outlook event failed to be updated.';

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: message,
				confirmationRequired: false
			},
		});
	}

	displayInfo(section: string): boolean {
		const type: TopicTypeEnum = this.page === this.pagesEnum.ASSOCIATES ? TopicTypeEnum.TRAINING :
			(this.page === this.pagesEnum.SERVICED_STORES ? TopicTypeEnum.AUDIT : TopicTypeEnum.AUDIT_CSV);
		const idx = this.infoConstants.findIndex(i =>
			i.NAME.toLocaleLowerCase() === section.toLowerCase()
			&& i.TYPE === type
		);

		if (idx === -1) {
			return false;
		}

		return true;
	}

	openInfo(section: string) {
		this.dialog.open(InfoDialogComponent, {
			data: {
				topic: section,
				type: this.page === this.pagesEnum.ASSOCIATES ? TopicTypeEnum.TRAINING :
					(this.page === this.pagesEnum.SERVICED_STORES ? TopicTypeEnum.AUDIT : TopicTypeEnum.AUDIT_CSV)
			},
			disableClose: true
		});
	}

	isUkUser(): boolean {
		return this.loginSvc.isUkUser();
	}

	onSendEmail() {
		this.dialog.open(EmailRecipientsComponent, {
			data: {
				scheduleType: this.data?.scheduleType
			}
		}).afterClosed().subscribe(result => {
			if (result) {
				this.previewEmail(result);
			}
		});
	}

	displayEmailConfirmationMessage() {
		let message = 'Email sent successfully!';

		this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: message,
				confirmationRequired: false
			},
		});
	}

	get infoConstants() {
		if (this.loginSvc.isUkUser()) {
			return CountryGlobalVariables.UK.INFO_CONSTANTS;
		} else {
			return CountryGlobalVariables.US.INFO_CONSTANTS;
		}
	}

	initStoreSearchFilter() {
		this.filteredStores.next(this.storesArray);
		this.storeFilterCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(() => {
				this.filterStores();
			});
	}

	protected filterStores() {
		if (!this.storesArray) {
			return;
		}

		// get the search keyword
		let search = this.storeFilterCtrl.value;
		if (!search) {
			this.filteredStores.next(this.storesArray);
			return;
		} else {
			search = search.toLowerCase();
		}

		// filter the stores
		this.filteredStores.next(
			this.storesArray?.filter(
				(store: StoreDetails) =>
					store.chain?.toLowerCase().indexOf(search) > -1 ||
					store.store?.toLowerCase().indexOf(search) > -1
			)
		);
	}

	generateEmailData(recipients: string): Email {
		const dat: TopicData[] = [];
		this.sectionArray?.filter(section => section.expanded)?.forEach(s => {
			const topicData: QuestionData[] = [];
			s?.questions.forEach(q => {
				const questionData = this.data?.data?.find(ques => ques.questionId === q.id);
				if (questionData?.value || questionData?.photos?.length > 0) {
					topicData.push({
						questionId: q.id,
						questionName: q.question,
						questionValue: questionData?.value !== undefined ? questionData?.value : null,
						photos: questionData?.photos?.length > 0 ? questionData?.photos : null,
					} as QuestionData);
				}
			});

			dat.push({
				topicName: s.section,
				topicQuestions: topicData
			} as TopicData);
		});

		const dateFormat = this.loginSvc.isUkUser() ? CountryGlobalVariables.UK.datePipeFormat : CountryGlobalVariables.US.datePipeFormat;
		return {
			recipients: recipients,
			scheduleType: this.data?.scheduleType,
			chain: this.store?.chain,
			store: this.store?.store,
			retailerName: this.store?.name,
			city: this.store?.city,
			address: this.store?.address,
			associateName: this.associate?.fullName,
			completedDate: this.data?.completedDate
				? this.datePipe.transform(this.data?.completedDate, dateFormat)
				: this.datePipe.transform(new Date(), dateFormat),
			topicsData: dat
		} as Email;
	}

	previewEmail(recipients: string) {
		this.dialog.open(EmailGeneratorComponent, {
			disableClose: true,
			data: this.generateEmailData(recipients)
		}).afterClosed().subscribe(res => {
			if (res) {
				this.displayEmailConfirmationMessage();
			}
		});
	}

	initFloaterButton() {
		const fabElement = document.getElementsByClassName("floating-snap-btn-wrapper").item(0) as HTMLElement;
		let oldPositionX, oldPositionY;

		const move = (e) => {
			if (!fabElement.classList.contains("fab-active")) {
				let sidebarWidth = document.getElementsByClassName("page-view").item(0).getBoundingClientRect().left;
				if (e.type === "touchmove") {
					let leftPos = e.touches[0].clientX - sidebarWidth;
					fabElement.style.top = e.touches[0].clientY + "px";
					fabElement.style.left = leftPos + "px";
				} else {
					let leftPos = e.clientX - sidebarWidth;
					fabElement.style.top = e.clientY + "px";
					fabElement.style.left = leftPos + "px";
				}
			}
		};

		const mouseDown = (e) => {
			oldPositionY = fabElement.style.top;
			oldPositionX = fabElement.style.left;
			if (e.type === "mousedown") {
				window.addEventListener("mousemove", move);
			} else {
				window.addEventListener("touchmove", move);
			}
			fabElement.style.transition = "none";
		};

		const mouseUp = (e) => {
			if (e.type === "mouseup") {
				window.removeEventListener("mousemove", move);
			} else {
				window.removeEventListener("touchmove", move);
			}
			if (!fabElement.classList.contains("fab-active")) {
				snapToSide(e);
			}
			fabElement.style.transition = "0.3s ease-in-out left";
		};

		const snapToSide = (e) => {
			const pageViewWidth = document.getElementsByClassName("page-view").item(0).clientWidth;
			let currPositionX, currPositionY;
			let rect = document.getElementsByClassName("page-view").item(0).getBoundingClientRect();
			if (e.type === "touchend") {
				currPositionX = e.changedTouches[0].clientX - rect.left;
				currPositionY = e.changedTouches[0].clientY;
			} else {
				currPositionX = e.clientX - rect.left;
				currPositionY = e.clientY;
			}
			if (currPositionY < 100) {
				fabElement.style.top = 100 + "px";
			}
			if (currPositionX < pageViewWidth / 2) {
				window.screen.width < 959 ? fabElement.style.left = 7 + "%" : fabElement.style.left = 3 + "%";
				fabElement.classList.remove('right');
				fabElement.classList.add('left');
			} else {
				window.screen.width < 959 ? fabElement.style.left = 93 + "%" : fabElement.style.left = 97 + "%";
				fabElement.classList.remove('left');
				fabElement.classList.add('right');
			}
		};

		const click = (e) => {
			if (e.type === "click") {
				if ((oldPositionY === fabElement.style.top &&
					oldPositionX === fabElement.style.left) ||
					(oldPositionY === "" && oldPositionX === "")) {
					fabElement.classList.toggle("fab-active");
				}
			}
		};

		fabElement.addEventListener("mousedown", mouseDown);
		fabElement.addEventListener("mouseup", mouseUp);
		fabElement.addEventListener("touchstart", mouseDown);
		fabElement.addEventListener("touchend", mouseUp);
		fabElement.addEventListener("click", click);
	}

	onPdfExport() {
		let data: Email = this.generateEmailData(null);
		this.exportPdf.exportScheduleAsPdf(data);
	}
}
