import { Injectable } from '@angular/core';
import {GenericFilterObject} from '../../shared/models/generic-filter-object.model';

@Injectable({
  providedIn: 'root'
})
export class GenericFilterHistoryService {

  isBackOperationOccurred = false;

  constructor() { }

  saveState(genericFilter: GenericFilterObject) {
    localStorage.setItem('serviced-stores', JSON.stringify(genericFilter));
  }

  getState(): GenericFilterObject {
    return JSON.parse(localStorage.getItem('serviced-stores'));
  }
}
