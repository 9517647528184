<body fxLayout="column">

	<ng-container *ngIf="data.loading">
		<mat-option id="visibleSpinner" class="spinner-auto-complete">
			<mat-spinner class="tableSpinner" style="margin: auto" color="accent" [diameter]="50"></mat-spinner>
		</mat-option>
	</ng-container>
	<ng-container *ngIf="!data.loading" class="modalSearch">
		<div mat-dialog-content fxLayout="row" fxFlex="100%" fxLayoutAlign="space-around center"
			[innerHtml]="data.message" style="text-align: center;">
		</div>
		<div mat-dialog-actions fxLayout="row" fxFlex="100%" fxLayoutAlign="space-around center">
			<button (click)="dialogRef.close(false)" fxLayout="column" mat-raised-button type="button"
				class="btn-action btn-cancel">
				<span *ngIf="data.noButtonShow">NO</span>
				<span *ngIf="!data.noButtonShow && !data.cancelButtonShow">CLOSE</span>
				<span *ngIf="data.cancelButtonShow">CANCEL</span>
			</button>
			<button *ngIf="data.yesButtonShow" (click)="dialogRef.close(true)" [mat-dialog-close]="true"
				fxLayout="column" fxLayoutAlign="center center" mat-raised-button type="button"
				class="btn-action btn-primary">
				YES
			</button>
		</div>
	</ng-container>
</body>