import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StringResponse} from '../../shared/models/string-response';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private url = environment.apiResourceUri.replace('/api', '') + '/app-version';

  constructor(private http: HttpClient) {}

  public checkAppVersionChange() {
    this.http.get<StringResponse>(this.url + `/ui`).subscribe(
      (response: StringResponse) => {
        const currentVersion = response.response;
        if (currentVersion == null) { return; }

        const cachedVersion = localStorage.getItem('appVersion');
        if (currentVersion === cachedVersion) { return; }

        localStorage.setItem('appVersion', currentVersion.toString());
        document.location.reload();
      }
    );
  }
}
