<app-msg-banner
  *ngIf="showNotification"
  [msgList]="messageList"
  (msgListChange)="messageList = $event"
></app-msg-banner>

<div class="wrapper">
    <mat-card
        class="mat-elevation-z2"
        fxLayout="column">
        <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="40px"
            fxLayoutGap.lt-md="20px">

            <div
                fxFlex="100%"
                fxLayout="column"
                fxLayout.lt-md="column"
                fxLayoutGap="50px"
                fxLayoutGap.lt-md="20px"
                [formGroup]="storeDataFrom">

                <!-- store, date, time -->
                <div
                    fxLayout="column"
                    fxLayoutGap="20px"
                    fxLayoutGap.lt-md="0px">

                    <!--  store  -->
                    <div
                        fxFlex="100%"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                        class="store-column">
                        <mat-icon class="grey-icon margin-10">store</mat-icon>
                        <mat-form-field
                            class="white-background remove-default-styling"
                            fxFlex="100%;"
                            fxFlex.lt-md="100%">
                            <mat-label>Select Store</mat-label>
                            <mat-select formControlName="store" required>
                                <mat-option>
                                    <ngx-mat-select-search
                                      [formControl]="storeFilterCtrl"
                                      placeholderLabel="Find store ..."
                                      noEntriesFoundLabel="No matching store found"
                                    >
                                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                    *ngFor="let store of filteredStores | async"
                                    [value]="store.id"
                                    class="multiline-mat-option">
                                    <div> {{store.name}} {{ store.chain }} - {{store.store}} <span *ngIf="store.shipTo">({{store.shipTo}})</span></div>
                                    <div> {{ store.address}} </div>
                                    <div *ngIf="store.isMerchAssigned" class="assigned-msg"> ASSIGNED </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- date and time picker -->
                    <div
                        fxFlex="100%"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px">
                        <mat-icon class="grey-icon margin-10">schedule</mat-icon>

                        <div
                            fxFlex="100%"
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap="10px"
                            fxLayoutGap.lt-md="10px">
                            <mat-form-field
                                class="white-background remove-default-styling"
                                fxFlex="50%"
                                fxFlex.lt-md="100%">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="date" [min]="min">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field
                                class="white-background remove-default-styling"
                                fxFlex="50%"
                                fxFlex.lt-md="100%">
                                <mat-label>Select Time</mat-label>
                                <mat-select
                                    formControlName="startTime"
                                    [disabled]="!storeDataFrom.controls.date.value">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let slot of timeSlots" [value]="slot.value" class="multiline-mat-option">
                                        {{ slot.text }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- associate's details -->
                <div class="mobile-divider"><mat-divider></mat-divider></div>
                <div
                    fxFlex="100%"
                    fxLayout="row"
                    fxLayout.lt-lg="column"
                    fxLayoutAlign="space-between start"
                    fxLayoutGap="5px"
                    fxLayoutGap.lt-lg="10px"
                    fxLayoutGap.lt-md="10px"
                    *ngIf="associate">

                    <div fxLayout="column" fxLayoutGap="5px">
                        <div
                            fxFlex="100%"
                            fxLayout="row"
                            fxLayoutGap="10px">
                            <div fxLayoutAlign="start">
                                <mat-icon class="grey-icon">account_circle</mat-icon>
                            </div>

                            <div
                                fxLayout="column"
                                fxLayoutGap="5px">
                                <div fxLayoutAlign="start" class="title"> {{ associate.fullName }} </div>
                                <div fxLayoutAlign="start"> {{ associate.userName }} </div>
                                <div fxLayoutAlign="start" *ngIf="associate.jobTitle"> {{ associate.jobTitle }} </div>
                            </div>
                        </div>

                        <div
                            fxLayout="row"
                            fxLayoutGap="10px"
                            *ngIf="associate.zones">
                            <mat-icon
                              class="grey-icon"
                              [ngStyle]="{ 'color': isUkUser() ? 'white' : '' }"
                            >layers</mat-icon>
                            <span>Zones: </span>
                            <span>&nbsp;{{ associate.zones }}</span>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayoutGap="5px">
                        <span *ngIf="associate.lastTrain">{{ isUkUser() ? CountryGlobalVariables.UK.trainingLastOn :
                          CountryGlobalVariables.US.trainingLastOn }}: {{ associate.lastTrain | customDate: isUkUser() }}</span>
                        <span *ngIf="associate.hireDate">{{ isUkUser() ? CountryGlobalVariables.UK.hireDate :
                          CountryGlobalVariables.US.hireDate }}: {{ associate.hireDate | customDate: isUkUser() }}</span>
                    </div>
                </div>
            </div>
            <div class="mobile-divider">
                <mat-divider></mat-divider></div>

            <!-- purposes -->
            <div
                fxFlex.gt-sm="100%"
                fxLayout="column"
                fxLayoutGap="20px">
                <p class="card-title">Purpose of call</p>
                <mat-form-field
                    class="example-chip-list"
                    appearance="fill"
                    fxFlex.gt-sm="100%">
                    <mat-chip-list
                        #chipList
                        multiple>
                        <mat-chip
                            *ngFor="let purpose of purposesOfCall"
                            [value]="purpose"
                            [selected]="purpose.selected"
                            (click)="purpose.selected=!purpose.selected">
                        {{ purpose.desc }}
                        </mat-chip>
                    </mat-chip-list>
                    <div *ngIf="!validatePurposes()" class="invalid-message"> {{ purposesErr }} </div>
                </mat-form-field>
            </div>
        </div>

        <!-- action buttons -->
        <br>
        <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxFlex="23%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="center"
            fxLayoutAlign.lt-md="start"
            fxLayoutGap="20px"
            fxLayoutGap.lt-md="5px"
        >
            <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                mat-raised-button
                class="btn btn-clear"
                color="primary"
                type="button"
                (click)="onCancel()"
            >Cancel
            </button>
            <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                mat-raised-button
                class="btn btn-search"
                color="primary"
                (click)="onSave()"
                [disabled]="storeDataFrom.invalid || !validatePurposes() || associate == null"
            >Save
            </button>
        </div>
    </mat-card>
</div>
