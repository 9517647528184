import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PlatformLocation} from '@angular/common';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TerritoryService {

  constructor(private http: HttpClient) { }

  getUserAssignedTerritories(): Observable<Number[]> {
    return this.http.get<Number[]>(
        environment.apiResourceUri + "/area-supervisor-territory/user-terrs");
  }

  updateSelectedTerritory(territory: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiResourceUri}/supervisors`, {
        territory: territory
      }
    );
  }

  saveTerritoryNumber(territory: number) {
    localStorage.setItem("territory", territory.toString());
  }

  deleteTerritoryNumber() {
    localStorage.removeItem("territory")
  }

  loadUserTerritory() {
    if (localStorage.getItem("territory") != null)
      return

    this.getUserAssignedTerritories().subscribe(
      (response: number[]) => {
        if (response.length > 0) {
          this.updateSelectedTerritory(response[0]).subscribe();
          localStorage.setItem("territory", response[0].toString());
        }
      }
    );
  }

  getTerritory(): number {
    var territory = localStorage.getItem("territory");
    if (territory != null)
      return Number(territory);

    return 0;
  }
}
