import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {ImageUploadService} from '../../../core/services/image-upload.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {Dialog} from '../../models/dialog';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit, OnDestroy {

  files: any[] = [];

  // error list
  messageList = [];
  showNotification = false;

  loadingFile: { [key: number]: boolean } = {};
  failMessage: { [key: number]: string } = {};

  constructor(private msgBanner: MsgBannerService,
              private imageUploadService: ImageUploadService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<FileUploadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.files = this.data;
    this.uploadFilesInBatch(0);
  }

  ngOnDestroy() {
    this.files = [];
  }

  uploadFilesInBatch(index: number) {
    if (this.files[index]?.progress === 100) {
      this.uploadFilesInBatch(index + 1);
    } else if (this.files[index]) {
      this.upload(index);
    }
  }

  upload(index: number) {
    this.files[index]['success'] = null;
    this.loadingFile[index] = true;
    this.uploadFile(index);
  }

  uploadFile(index: number) {
    this.imageUploadService.uploadPhoto(this.files[index].file, this.files[index].name).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (this.files[index]) {
            this.files[index].progress = Math.round(100 * event.loaded / event.total);
          }
        } else if (event instanceof HttpResponse) {
          if (this.files[index]) {
            this.files[index]['success'] = true;
          }
          this.loadingFile[index] = false;
          this.uploadFilesInBatch(index + 1);

          const failed = this.files.findIndex(f => f.success === false);
          if (failed === -1) {
            this.closeDialog();
          }
        }
      }, error => {
        if (error.status === 417) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else if (error.status === 400) {
          this.failMessage[index] = error.error.message;
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;

        this.files[index]['success'] = false;
        this.loadingFile[index] = false;
        this.uploadFilesInBatch(index + 1);
      });
  }


  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  close() {
    const idx = this.files.findIndex(f => f.success === false);
    if (idx === -1) {
      this.closeDialog();
      return;
    }

    this.dialog.open(ConfirmDialogComponent, {
      data: new Dialog('You have unsaved photos. Are you sure you want to close this dialog?', true, false, true),
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result === true) {
        this.closeDialog();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(this.files);
    this.ngOnDestroy();
  }
}
