import { RefreshTokenResponse } from './../../shared/models/ldap/refresh-token-response.model';
import { RefreshTokenRequest } from './../../shared/models/ldap/refresh-token-request.model';
import { AuthResponse } from './../../shared/models/ldap/auth-response.model';
import { AuthRequest } from './../../shared/models/ldap/auth-request.model';
import { environment } from './../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class LdapAuthService {
	private url = environment.apiResourceUri.replace('/api', '') + '/auth';

	constructor(private http: HttpClient) { }

	signIn(authRequest: AuthRequest): Observable<AuthResponse> {
		return this.http.post<AuthResponse>(this.url + '/signIn', authRequest);
	}

	refreshToken(refreshTokenRequest: RefreshTokenRequest): Observable<RefreshTokenResponse> {
		return this.http.post<RefreshTokenResponse>(this.url + '/refreshToken', refreshTokenRequest);
	}
}