import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, HostListener, OnInit } from '@angular/core';

import { RolesEnum } from './../../../shared/enum/role.enum';
import { StoresService } from '../../../core/services/stores.service';
import { StoreDetails, StoreMetrics } from '../../../shared/models/stores/store-details.model';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { PagesEnum } from '../../../shared/models/stores/pages.model';
import { GenericFilterHistoryService } from '../../../core/services/generic-filter-history.service';
import { AssociateDetailsPageRedirectService } from '../../../core/services/previous-page-redirect.service';
import { LoginService } from '../../../core/services/login.service';
import { CountryGlobalVariables } from '../../../shared/constants/country-global-variables';
import { UK_METRICS_TABLE_CONTENTS } from '../../../shared/constants/metrics.constants';
import { UserAuthService } from './../../../shared/services/user-auth.service';

@Component({
	selector: 'app-store-details',
	templateUrl: './store-details.component.html',
	styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit {
	readonly RolesEnum = RolesEnum;

	error = false;
	storeDetails: StoreDetails;
	data: any;
	isRedirectFromAssociateDetails: boolean;

	showNotification = false;
	messageList = [];

	isLdapUser: boolean = false;

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		this.genericFilterSvc.isBackOperationOccurred = true;

		if (this.isRedirectFromAssociateDetails) {
			this.redirectToAssociatesPageService.setRedirectToAssociateDetails();
		}
	}

	constructor(private storesService: StoresService,
		private clipboard: Clipboard,
		private snackBar: MatSnackBar,
		private msgBanner: MsgBannerService,
		public router: Router,
		private loginSvc: LoginService,
		private genericFilterSvc: GenericFilterHistoryService,
		private redirectToAssociatesPageService: AssociateDetailsPageRedirectService,
		private userAuthService: UserAuthService) {
			this.isLdapUser = this.userAuthService.hasLdapCredentials();
	}

	ngOnInit(): void {
		this.genericFilterSvc.isBackOperationOccurred = false;
		if (!history.state.data) {
			this.data = history.state.data;
			this.goToMyEventsPage();
			return;
		}

		this.isRedirectFromAssociateDetails = history.state.data.isRedirectFromAssociateDetails;
		this.getServiceDetails();
	}

	isUkUser(): boolean {
		return this.loginSvc.isUkUser();
	}

	get CountryGlobalVariables() {
		return CountryGlobalVariables;
	}

	get pagesEnum() {
		return PagesEnum;
	}

	get stateData() {
		return history.state.data;
	}

	goToMyEventsPage() {
		this.router.navigate(['/my-events']);
	}

	getServiceDetails() {
		this.showNotification = false;
		this.storesService.storeDetails(history.state.data.id).subscribe(
			response => {
				this.storeDetails = response;
			}, error => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
				}
				this.showNotification = true;
			}
		);
	}

	doShowDirections() {
		const url = `https://www.google.com/maps/dir/?api=1&destination=${this.storeDetails.lat},${this.storeDetails.lon}`;
		window.open(url);
	}

	copy(value: string) {
		if (value) {
			this.clipboard.copy(value);

			this.snackBar.open('Value copied successfully to clipboard.', 'Close', {
				duration: 5 * 1000,
			});

			return;
		}

		this.snackBar.open('Could not copy the value to clipboard.', 'Close', {
			duration: 5 * 1000,
		});
	}

	onScheduleAudit() {
		const data = {
			storeId: this.storeDetails.id
		};
		this.router.navigate(['schedule-audit'], { state: { data } });
	}

	startAudit() {
		this.goToAudit();
	}

	goToAudit() {
		const data = {
			store: this.storeDetails,
			page: history.state.data.page,
		};
		this.router.navigate(['audit'], { state: { data } });
	}

	onAuditHistory(storeId: string) {
		this.router.navigate(['/audit-history'], {
			queryParams: {
				storeId: storeId,
				page: history.state.data.page
			}
		});
	}

	onTrainingHistory(merchandiserId) {
		this.router.navigate(['/training-history'], {
			queryParams: {
				merchandiserId: merchandiserId,
				page: history.state.data.page
			}
		});
	}

	getStoreAddress(storeDetails: StoreDetails) {
		if (storeDetails == null)
			return "";

		return storeDetails.address +
			(storeDetails.city != null ? ", " + storeDetails.city : "") +
			(storeDetails.state != null ? ", " + storeDetails.state : "") +
			(storeDetails.postalCode != null ? ", " + storeDetails.postalCode : "");
	}

	get ukStoreMetricsColumns() {
		return UK_METRICS_TABLE_CONTENTS.STORE_DISPLYED_COLUMNS;
	}

	get ukStoreMetrics() {
		let metrics: StoreMetrics = this.storeDetails.metrics;
		let data = {
			salesVsTarget: {
				target: metrics.salesTarget,
				actual: metrics.salesActual,
				highlightActual: metrics.salesActual <= 90
			},
			latePayrollEntries: {
				value: metrics.latePayrollEntriesActual != null ? metrics.latePayrollEntriesActual : null,
				highlightValue: metrics.latePayrollEntriesActual > 0
			},
			timeCompliance: {
				target: metrics.timeComplianceTarget,
				actual: metrics.timeComplianceActual,
				highlightActual: metrics.timeComplianceTarget != metrics.timeComplianceActual
			},
			visitCompliance: {
				target: metrics.visitComplianceTarget,
				actual: metrics.visitComplianceActual,
				highlightActual: metrics.visitComplianceTarget != metrics.visitComplianceActual
			},
			overdueCalls: {
				value: metrics.overdueCalls != null ? metrics.overdueCalls : null,
				highlightValue: metrics.overdueCalls != null ? true : false
			},
			lastScanDate: {
				value: metrics.lastEmptyPocketScanDate != null ? metrics.lastEmptyPocketScanDate : null,
				highlightValue: metrics.missedScan === 'Y'
			},
			missedScan: {
				value: metrics.missedScan,
				highlightValue: metrics.missedScan === 'Y'
			}
		}

		return data;
	}
}
