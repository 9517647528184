import { UserAuthService } from '../../shared/services/user-auth.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { Observable } from "rxjs";


@Injectable({
	providedIn: 'root'
})
export class MsalLdapGuard implements CanActivate {

	constructor(
		private msalGuard: MsalGuard,
		private userAuthService: UserAuthService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		/* Check if the LDAP token exists in your application */
		const hasLdapToken = this.userAuthService.hasLdapCredentials();

		if (hasLdapToken) {
			/* Bypass the guard if the LDAP token exists */
			return true;
		}

		/* Use the MSAL guard logic if the LDAP token does not exist */
		return this.msalGuard.canActivate(route, state);
	}
}