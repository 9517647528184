import {ErrorHandler, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {GlobalErrorHandlerService} from './global-error-handler.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    GlobalErrorHandlerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ]
})
export class ErrorHandlerModule { }
