import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoresService } from '../../../core/services/stores.service';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { Associate } from '../../../shared/models/associates/associate.model';
import { Store } from '../../../shared/models/stores/store.model';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { PagesEnum } from '../../../shared/models/stores/pages.model';
import { Router } from '@angular/router';
import { StoreDetails, StoreMetrics } from '../../../shared/models/stores/store-details.model';
import { AssignedStoresDetails } from '../../../shared/models/associates/assigned-stores-details.model';
import {LoginService} from '../../../core/services/login.service';
import {CountryGlobalVariables} from '../../../shared/constants/country-global-variables';
import { MetricsDialogComponent } from './metrics-dialog/metrics-dialog.component';
import {ChatService} from '../../../core/services/chat.service';
import { RolesEnum } from '../../../shared/enum/role.enum';


@Component({
  selector: 'app-associate-details',
  templateUrl: './associate-details.component.html',
  styleUrls: ['./associate-details.component.scss']
})
export class AssociateDetailsComponent implements OnInit {
  readonly RolesEnum = RolesEnum;

  // error list
  messageList = [];
  showNotification = false;

  stores: StoreDetails[];
  assignedStoresMessage: string;

  constructor(
    private dialogRef: MatDialogRef<AssociateDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Associate,
    private msgBanner: MsgBannerService,
    private storeService: StoresService,
    private terrService: TerritoryService,
    private router: Router,
    private loginSvc: LoginService,
    private dialog: MatDialog,
    private chatService: ChatService,
  ) { }

  ngOnInit(): void {
    this.loadStores();
    this.loadAssignedStoresDetails();
  }

  isUkUser(): boolean {
    return this.loginSvc.isUkUser();
  }

  hasChatAccess(): boolean {
    return this.loginSvc._isChatUser && this.associate.isChatActive;
  }

  isAppropiateRole(): boolean {
    return this.loginSvc._role !== RolesEnum.IS;
  }

  get CountryGlobalVariables() {
    return CountryGlobalVariables;
  }

  get associate() {
    return this.data;
  }

  loadStores() {
    var terr = this.terrService.getTerritory();
    this.storeService.getServicedStoresByMerchandiserId(terr, this.associate.id).subscribe(
      (response: StoreDetails[]) => {
          this.stores = response;
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        }
        this.showNotification = true;
      }
    );
  }

  loadAssignedStoresDetails() {
    this.storeService.getAssignedStoresDetailsByMerchandiserId(this.associate.id).subscribe(
      (response: AssignedStoresDetails) => {
        this.assignedStoresMessage = this.buildAssignedStoresMessage(response);
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        }
        this.showNotification = true;
      }
    );
  }

  closeDialogBeforeRedirect() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onViewStoreDetails(store: Store) {
    this.closeDialogBeforeRedirect();

    const data = {
      id: store.id,
      page: PagesEnum.SERVICED_STORES,
      isRedirectFromAssociateDetails: true
    };
    this.router.navigate(['serviced-details'], { state: { data } });
  }

  onStartTraining() {
    this.goToTraining();
  }

  goToTraining() {
    this.closeDialogBeforeRedirect();

    const data = {
      associate: this.associate,
      page: PagesEnum.ASSOCIATES,
      isRedirectFromAssociateDetails: true
    };
    this.router.navigate(['training'], { state: { data } });
  }

  onSheduleTraining() {
    const data = {
      associateId: this.associate.id,
      isRedirectFromAssociateDetails: true
    };
    this.closeDialogBeforeRedirect();

    this.router.navigate(['schedule-training'], { state: { data } });
  }

  onTrainingHistory() {
    this.closeDialogBeforeRedirect();
    this.router.navigate(['/training-history'], {queryParams: {
      merchandiserId: this.associate.id,
      page: PagesEnum.ASSOCIATES,
      isAssociatesRedirect: true
    }});
  }

  onLinkingU() { }

  openChat() {
    this.chatService.getOrCreateMerchandiserChannel(this.associate.id).subscribe(res => {
      this.closeDialogBeforeRedirect();
      this.router.navigate(['/chat'], {state: {data: res.channelId}});
    }, error => {
      this.showNotification = true;
      if (error.status === 404) {
        this.msgBanner.addMsgError(this.messageList, error.error.message);
      } else {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    });
  }

  buildAssignedStoresMessage (data: AssignedStoresDetails): string {
    if (data.totalStores === data.totalStoresInCurrentTerritory) {
      switch (data.totalStores) {
        case 0:
          return null;
        case 1:
          return 'Currently showing all stores assigned.';
        default:
          return 'Currently showing all ' + data.totalStores + ' stores assigned.';
      }

    } else {
      return "Currently showing " + data.totalStoresInCurrentTerritory +
              " from a total of " + data.totalStores + " stores assigned. This merchandiser has " +
              " additional stores assigned on territories " + data.outsideTerritories.join(', ') + ".";
    }
  }

  onViewStoreMetrics(store: StoreDetails) {
    let metrics: StoreMetrics = store.metrics;
    let data = {
      latePayrollEntries: {
        value: this.associate.latePayrollEntriesActual,
        highlightValue: this.associate.latePayrollEntriesActual > 0
      },
      timeCompliance: {
        target: metrics.timeComplianceTarget,
        actual: metrics.timeComplianceActual,
        highlightActual: metrics.timeComplianceTarget != metrics.timeComplianceActual
      },
      visitCompliance: {
        target: metrics.visitComplianceTarget,
        actual: metrics.visitComplianceActual,
        highlightActual: metrics.visitComplianceTarget != metrics.visitComplianceActual
      },
      overdueCalls: {
        value: metrics.overdueCalls != null ? metrics.overdueCalls : null,
        highlightValue: metrics.overdueCalls != null ? true : false
      },
      lastScanDate: {
        value: metrics.lastEmptyPocketScanDate != null ? metrics.lastEmptyPocketScanDate : null,
        highlightValue: metrics.missedScan === 'Y'
      },
      missedScan: {
        value: metrics.missedScan,
        highlightValue: metrics.missedScan === 'Y'
      }
    };

    this.dialog.open(MetricsDialogComponent, {
      panelClass: 'mobile-dialog',
      disableClose: true,
      data: data,
    });
  }
}
