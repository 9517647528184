<div id="root">
  <stream-channel-list
    (click)="isMenuOpen = false"
    class="channel-list menu-{{ isMenuOpen ? 'open' : 'close' }}"
  >
    <div
      channel-list-top
      fxLayout="column"
      fxLayoutAlign="start"
      (click)="$event.stopPropagation()"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="my-team-logo"
      >
        <div>
          <button
            mat-icon-button
            class="icon-btn"
            (click)="goBack()"
          >
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
        </div>

        <div class="logo">
          <a class="simple-text" (click)="redirectToMainPage()">
            <div>
              <img src="assets/img/ag_myteam_logo.png"/>
            </div>
          </a>
        </div>
      </div>

      <div
        fxLayout="row"
      >
        <div
          class="str-chat__message-input str-chat__message-input-inner str-chat__message-textarea-container"
        >
          <input
            matInput
            [formControl]="searchControl"
            placeholder=" Search"
            class="str-chat__message-input str-chat__message-input-inner str-chat__message-textarea-container"
          />
        </div>

        <div>
          <button
            mat-icon-button
            (click)="createChannel()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </stream-channel-list>
  <stream-channel class="channel" (click)="closeMenu()">
    <stream-channel-header>
      <button
        class="menu-button"
        (click)="isMenuOpen = !isMenuOpen; $event.stopPropagation()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
            [attr.fill]="(theme$ | async) === 'light' ? 'black' : 'white'"
          />
        </svg>
      </button>
    </stream-channel-header>
    <stream-message-list
      [hideJumpToLatestButtonDuringScroll]="hideJumpToLatestButtonDuringScroll"
    ></stream-message-list>
    <stream-notification-list></stream-notification-list>
    <div
      style="padding: 8px; background: #e1f5fe"
      fxLayout="row"
      [fxLayoutAlign]="message.text.length > 0 ? 'space-between center' : 'end center'"
      *ngFor="
        let message of channelService.activeChannelPinnedMessages$ | async
      "
      (click)="jumpToMessage(message)"
    >
      {{ message.text }}
      <button
        style="border: none; background: none; padding: 0; margin: 0"
        (click)="$event.stopPropagation(); unpinMessage(message)"
      >
        <strong>Unpin</strong>
      </button>
    </div>
    <stream-message-input></stream-message-input>
  </stream-channel>
</div>
