import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TrainingAudit} from '../../shared/models/training-audit/training-audit.model';
import { ScheduleResponse } from '../../shared/models/schedule/schedule-response.model';
import { LoginService } from './login.service';
import {UtilsService} from './utils.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainingAuditService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService
    ) { }

  public saveResponses(scheduleId: string, merchandiserId: number, retailerId: number, terr: number,
                       data: TrainingAudit, isAudit: boolean): Observable<ScheduleResponse> {
    let today = this.getDateForStartEvents();
    today = UtilsService.dateToServerDate(today);
    const date: string = today.toISOString();

    return this.http.post<ScheduleResponse>(`${environment.apiResourceUri}/responses/date/${date}`, {
      scheduleId,
      retailerId,
      merchandiserId,
      isAudit,
      terr,
      data: data.data
    });
  }

  private getDateForStartEvents(): Date {
    let today = new Date();
    today.setSeconds(0);
    today.setMilliseconds(0);
    if (today.getMinutes() > 30) {
      today.setMinutes(0);
      today.setHours(today.getHours() + 1);
    } else {
      today.setMinutes(30);
    }

    return UtilsService.dateFromServer(today);
  }

  findTrainingAuditsByScheduleId(scheduleId: string): Observable<TrainingAudit> {
    return this.http.get<TrainingAudit>(`${environment.apiResourceUri}/schedules/${scheduleId}`)
      .pipe(map((response: TrainingAudit) => {
        if (response.completedDate) {
          response.completedDate = UtilsService.dateToServerDate(response.completedDate);
        }
        return response;
      }));
  }

  public deleteTrainingAudit(scheduleId: string): Observable<ScheduleResponse> {
    return this.http.delete<ScheduleResponse>(`${environment.apiResourceUri}/responses/schedule/${scheduleId}`);
  }

  public deleteBulkTrainingAudit(scheduleIdList: string[]): Observable<ScheduleResponse[]> {
    return this.http.delete<ScheduleResponse[]>(`${environment.apiResourceUri}/responses/schedule/bulk`, {body: scheduleIdList});
  }

  public getEmailsForSendingHistory(fullName: string): Observable<any> {
    const country = this.loginService._country;
    return this.http
      .get(`${environment.apiResourceUri}/responses/country/${country}/fullName/${fullName}`);
  }
}
