import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleService } from '../../../core/services/schedule.service';
import { UtilsService } from '../../../core/services/utils.service';
import { SCHEDULE_CONTENTS } from '../../constants/schedule.constants';
import { RescheduleModel } from '../../models/schedule/reschedule.model';
import { ScheduleResponse } from '../../models/schedule/schedule-response.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MsgBannerService } from '../msg-banner/msg-banner.service';
import {LoginService} from '../../../core/services/login.service';

@Component({
  selector: 'app-reschedule-dialog',
  templateUrl: './reschedule-dialog.component.html',
  styleUrls: ['./reschedule-dialog.component.scss']
})
export class RescheduleDialogComponent implements OnInit {

  title = "Schedule Event";
  scheduleId: string;
  formData: UntypedFormGroup;

  min = new Date();

  // error
  messageList = [];
  showNotification = false;

  constructor(public dialogRef: MatDialogRef<RescheduleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private loginSvc: LoginService,
              private scheduleService: ScheduleService,
              private msgBanner: MsgBannerService)
  {
    this.formData = this.fb.group({
      date: [ null, [Validators.required], []],
      startTime: [ null, [], []],
    });
  }

  ngOnInit(): void {
    if (this.data.scheduleId == null)
      this.dialogRef.close();

    this.scheduleId = this.data.scheduleId;
    this.fillFormValues();
  }

  onClose() {
    // confirmation dialog
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: null,
        message:
          'Are you sure you want to discard all changes?',
        confirmationRequired: true,
        yesButtonShow: true,
        noButtonShow: true
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.dialogRef.close();
      }
    });
  }

  getTimeSlotValue(slotText: string) {
    if (slotText.length < 8)
      slotText = "0" + slotText;
    var res = this.timeSlots.find(s => s.text === slotText);
    return res != undefined ? res.value : null;
  }

  get timeSlots() {
    return SCHEDULE_CONTENTS.TIME_SLOTS.filter(t => t.country.includes(this.loginSvc._country));
  }

  fillFormValues() {
    let date = this.data.date;
    if (date == null)
      return;

    this.title = "Reschedule Event";
    this.formData.controls.date.setValue(this.data.date);
    this.formData.controls.startTime.setValue(this.getTimeSlotValue(this.data.time));
  }

  onSave() {
    // schedule/reschedule event
    let details = this.buildRescheduleObject();
    let date = details.scheduledDate;
		let isAllDayEvent = details.isAllDayEvent;

    // check schedule conflict for date and time
		if (date != null && !isAllDayEvent) {
			this.scheduleService.checkScheduledDateTimeConflict(this.scheduleId, date).subscribe(
				(response: number) => {
					if (response > 0) {
						this.displayDateTimeConflictDialog(details);
						return;
					}
					// no conflict
					this.rescheduleEvent(details);
				},

				(error) => {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
					this.showNotification = true;
				}
			);
			return;
		}

    this.rescheduleEvent(details);
  }

  rescheduleEvent(details: RescheduleModel) {
    this.scheduleService.rescheduleEvent(this.scheduleId, details).subscribe(
      (response: ScheduleResponse) => {
        this.displayConfirmationMessage(response.outlookEventSuccess);
      },
      (error) => {
        if (error.status === 404)
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        else
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');

        this.showNotification = true;
      }
    );
  }

  buildRescheduleObject(): RescheduleModel {
    var rescheduleObject: RescheduleModel = new RescheduleModel();
    if (!this.formData.controls.date.value) {
      return null;
    }

    var scheduledDate = new Date(this.formData.controls.date.value);
    scheduledDate.setHours(0);
    scheduledDate.setMinutes(0);
    scheduledDate.setSeconds(0);
    scheduledDate.setMilliseconds(0);

    if (this.formData.controls.startTime.value) {
      let scheduledTime: string = this.formData.controls.startTime.value;
      const hourMinutes: string[] = scheduledTime.split(':');
      scheduledDate.setHours(Number(hourMinutes[0]));
      scheduledDate.setMinutes(Number(hourMinutes[1]));
    }

    if (this.isAllDayEvent()) {
      rescheduleObject.scheduledDate = UtilsService.dateToServerDate(scheduledDate);
      rescheduleObject.scheduledOutlookDate = UtilsService.dateToServerDate(scheduledDate);
    } else {
      rescheduleObject.scheduledDate = scheduledDate;
      rescheduleObject.scheduledOutlookDate = scheduledDate;
    }

    rescheduleObject.isAllDayEvent = this.isAllDayEvent();

    return rescheduleObject;
  }

  isAllDayEvent() {
    if (this.formData.controls.date.value &&
        this.formData.controls.startTime.value == null)
        return true;

    return false;
  }

  displayConfirmationMessage(isOutlookSuccess: boolean) {
    let message = isOutlookSuccess ? 'Event successfully updated!' :
        'Event successfully updated, but the Outlook Event failed to be updated!';

    const confirmationDialogref = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: {
        message: message,
        confirmationRequired: false
      },
    });

    confirmationDialogref.afterClosed().subscribe((dialogResult) => {
      this.dialogRef.close(true);
    });
  }

  displayDateTimeConflictDialog(data: RescheduleModel) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: 'An event already exists at that time. Do you want to continue?',
				confirmationRequired: true,
				yesButtonShow: true,
				cancelButtonShow: true
			},
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				this.rescheduleEvent(data);
			}
		});
	}
}
