<app-msg-banner
  *ngIf="showNotification"
  [msgList]="messageList"
  (msgListChange)="messageList = $event"
></app-msg-banner>

<mat-card
  class="padding-20 mat-elevation-z0"
  fxLayout="row"
  fxLayoutAlign="space-between start"
  *ngFor="let dat of data"
>
    <div
      class="header desc"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="5px"
      style="width: 100%;"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="width: 100%;">
        <div *ngIf="dat.schedule.completedStatus == 1" class="complete">
          Completed
        </div>
        <div *ngIf="dat.schedule.completedStatus == 0" class="incomplete">
          Incomplete
        </div>

        <div
          fxFlex="40px">
          <div *ngIf="dat.schedule.completedStatus == 0" fxLayoutGap="10px">
            <button
              mat-icon-button
              class="delete-icon"
              (click)="onDelete(dat.schedule.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div
        class="clickable-container"
        (click)="goToTrainingAudit(dat.schedule)">
        <div class="desc">
          Start date: {{ dat.schedule.scheduledDate | customDate: isUkUser() }}
        </div>
        <div class="desc">
          Last update date: {{ dat.lastUpdateDate | customDate: isUkUser() }}
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="10px"
          *ngIf="scheduleType === trainingScheduleType"
        >
          <div fxLayoutAlign="start">
            <mat-icon class="desc-icon grey-icon">store</mat-icon>
          </div>

          <div
            fxLayout="column"
            fxLayoutAlign="center"
            class="desc">
            <div>
              {{ dat.schedule.retailer?.name }}
              {{ dat.schedule.retailer?.chain }}-{{ dat.schedule.retailer?.store}}
              <span *ngIf="dat.schedule.retailer?.shipTo"> ({{ dat.schedule.retailer?.shipTo }})</span>
            </div>
            <div>
              <span>{{ dat.schedule.retailer?.address }}</span>
              <span *ngIf="dat.schedule.retailer?.zone">, Zone {{ dat.schedule.retailer?.zone }}</span>
            </div>
          </div>
        </div>

        <div class="desc" *ngIf="dat.assignedPurposes?.length > 0">Purpose: {{ dat.assignedPurposes?.join(', ') }}</div>
        <div class="desc">
          Completed by:
          <span>
            {{ dat?.schedule?.areaSupervisor
              ? dat.schedule.areaSupervisor.areaSupervisorName
              : displayCompletedByName(dat)
            }}
          </span>
        </div>
      </div>
    </div>
</mat-card>

<mat-paginator
  [hidden]="data?.length === 0"
  [length]="length"
  [pageSizeOptions]="[10, 20, 50, 100, 250]"
  [pageSize]="10"
  (page)="onPageChange($event)"
></mat-paginator>
