<div class="error-centered" *ngIf="showNotification">
	<app-msg-banner [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>

	<button mat-button class="btn btn-blue" (click)="goToMyEventsPage()">{{isLdapUser ? 'Go To Main Page' : 'Go To My Events'}}</button>
</div>

<mat-card *ngIf="!showNotification && storeDetails" fxLayout="column" fxLayoutGap="20px">
	<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="50px" fxLayout.lt-md="column"
		fxLayoutAlign.lt-md="space-around center" fxLayoutGap.lt-md="0">
		<mat-card class="mat-elevation-z0" fxLayout="column" fxLayoutGap="12px">
			<div fxLayoutAlign="start center" fxLayoutGap="10px">
				<mat-icon class="desc-icon">store</mat-icon>

				<mat-icon class="top15" *ngIf="storeDetails.displayTop15">arrow_upward</mat-icon>

				<div class="desc" fxLayout="column">
					<div>{{ storeDetails?.name }}</div>
					<div>{{ storeDetails?.chain }}-{{ storeDetails?.store }} <span *ngIf="storeDetails.shipTo">({{
							storeDetails.shipTo }})</span></div>
				</div>
			</div>

			<div fxLayoutAlign="start center" fxLayoutGap="10px">
				<mat-icon class="desc-icon">location_on</mat-icon>
				<div class="desc" fxLayout="column">
					<div class="w-250">{{ getStoreAddress(storeDetails) }}</div>
				</div>
				<button fxLayoutAlign="center center" mat-icon-button style="color: grey"
					(click)="copy(getStoreAddress(storeDetails))">
					<mat-icon>content_copy</mat-icon>
				</button>
			</div>

			<div *ngIf="storeDetails.zone != null" fxLayoutAlign="start center" fxLayoutGap="10px">
				<mat-icon *ngIf="!isUkUser()" class="desc-icon">layers</mat-icon>
				<div class="desc" fxLayout="column">
					<div>Zone {{ storeDetails.zone != null ? storeDetails.zone : '-' }}</div>
				</div>
			</div>

			<div *ngIf="storeDetails.route != null" fxLayoutAlign="start center" fxLayoutGap="10px">
				<mat-icon *ngIf="!isUkUser()" class="desc-icon">route</mat-icon>
				<div class="desc" fxLayout="column">
					<div>Route {{ storeDetails.route != null ? storeDetails.route : '-' }}</div>
				</div>
			</div>

			<div fxLayoutAlign="start center" fxLayoutGap="10px" class="mt-1">
				<div class="desc">Last audit on {{ storeDetails?.lastCall | customDate: isUkUser() }}</div>
			</div>

			<div>
				<mat-divider></mat-divider>
			</div>

			<button mat-button class="btn btn-blue" (click)="doShowDirections()">Show Directions</button>

			<div *ngIf="stateData?.page === pagesEnum.SERVICED_STORES">
				<mat-divider></mat-divider>
			</div>

			<div>
				<div *ngIf="storeDetails?.merchandiser" fxLayoutAlign="start center" fxLayoutGap="10px" class="mb-1">
					<mat-icon class="desc-icon">person</mat-icon>
					<div class="desc" fxLayout="column">
						<div>{{ storeDetails.merchandiser }}</div>
					</div>
				</div>
				<div *ngIf="storeDetails?.phoneNumber" fxLayoutAlign="start center" fxLayoutGap="10px" class="mb-1">
					<mat-icon class="desc-icon">phone</mat-icon>
					<div class="desc" fxLayout="column">
						<div>{{ storeDetails.phoneNumber }}</div>
					</div>
				</div>
				<div *ngIf="storeDetails?.emailAddress" fxLayoutGap="10px" fxLayoutAlign="start center">
					<mat-icon class="desc-icon">email</mat-icon>
					<div class="desc" fxLayout="column">
						<div>{{ storeDetails.emailAddress }}</div>
					</div>
				</div>
			</div>

			<div fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="storeDetails.lastTraining" class="mt-1">
				<div class="desc">{{ isUkUser() ? CountryGlobalVariables.UK.trainingLastOn :
					CountryGlobalVariables.US.trainingLastOn }} {{ storeDetails.lastTraining | customDate: isUkUser() }}
				</div>
			</div>

			<button *ngIf="storeDetails.merchandiser" mat-button class="btn btn-blue"
				(click)="onTrainingHistory(storeDetails?.merchandiserId)">{{ isUkUser() ?
				CountryGlobalVariables.UK.trainingHistory : CountryGlobalVariables.US.trainingHistory }}</button>

		</mat-card>

		<mat-card fxFlex>
			<agm-map [ngClass]="{ 'map-size-serviced': storeDetails.merchandiser,
                     'map-size-non-serviced': !storeDetails.merchandiser }" ngClass.lt-md="map-size-mobile"
				[latitude]="storeDetails?.lat" [longitude]="storeDetails?.lon" [zoom]="16">
				<agm-marker [latitude]="storeDetails?.lat" [longitude]="storeDetails?.lon">
					<agm-info-window fxLayout="column">
						<div><strong>{{ storeDetails?.name }}</strong></div>
						<div><strong>{{ storeDetails?.chain }}-{{ storeDetails?.store }} <span
									*ngIf="storeDetails.shipTo">({{ storeDetails.shipTo }})</span></strong></div>
						<div><strong>📍 {{ storeDetails?.address }}</strong></div>
					</agm-info-window>
				</agm-marker>
			</agm-map>
		</mat-card>
	</div>

	<!-- metrics table -->
	<mat-accordion *ngIf="isUkUser()">
		<mat-expansion-panel [expanded]="true">

			<mat-expansion-panel-header style="height: auto !important;">
				<span class="table-title"> Store Metrics </span>
			</mat-expansion-panel-header>

			<app-generic-metrics-table [data]="ukStoreMetrics"
				[columns]="ukStoreMetricsColumns"></app-generic-metrics-table>

		</mat-expansion-panel>
	</mat-accordion>

	<mat-card class="mat-elevation-z0" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px"
		fxLayoutAlign.gt-sm="center center" fxLayoutGap.gt-sm="20px">
		<button mat-button class="btn btn-blue" fxLayout="row" fxLayoutAlign="center center"
			[matMenuTriggerFor]="auditMenu">
			<mat-icon>storefront</mat-icon>
			<span>Audit</span>
		</button>
		<mat-menu #auditMenu="matMenu">
			<button mat-menu-item (click)="startAudit()">
				<mat-icon>play_arrow</mat-icon>Start
			</button>

			<button mat-menu-item (click)="onScheduleAudit()" *appUserRole="{excludedRoles: [RolesEnum.TRS]}">
				<mat-icon>date_range</mat-icon>Schedule
			</button>

			<button mat-menu-item (click)="onAuditHistory(storeDetails?.id)">
				<mat-icon>history</mat-icon>History
			</button>
		</mat-menu>

		<button *ngIf="false" mat-button class="btn btn-blue" fxLayout="row" fxLayoutAlign="center center">
			<mat-icon>question_answer</mat-icon>
			<span>Linking U</span>
		</button>
	</mat-card>

</mat-card>