import { Injectable } from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {LoginService} from "../../core/services/login.service";
import {AppConstants} from "../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  appInsights: ApplicationInsights;
  constructor(
    private router: Router,
    private loginSvc: LoginService,
    private constants: AppConstants
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();

    const telemetryInitializer = (envelope) => {
      envelope.tags['ai.user.authUserId'] = this.loginSvc._eid;
    };

    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }

  logUsageEvent(nameOfLabelUsage: string) {
    const prop = {};
    prop[this.constants.APP_INSIGHTS_USAGE_TYPE_NAME] = nameOfLabelUsage;

    if (this.loginSvc._eid) {
      prop[this.constants.APP_INSIGHTS_USAGE_USER] = this.loginSvc._eid;
    }

    this.logEvent(this.constants.APP_INSIGHTS_USAGE_TYPE_KEY, prop);
  }
}
