<div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="40" fxFlex.sm="60">
      <mat-card class="p-0 mat-elevation-z6 box">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="35px" class="bg-primary box-header">
          <mat-icon class="mat-icon-xlg">error</mat-icon>
        </div>
        <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
            <p class="box-text err-msg">
              This browser is <b>not</b> supported. <br> 
              Please access this website using Google Chrome, Safari or Microsoft Edge for Mobile devices.
            </p>
        </mat-card-content>
      </mat-card>
    </div>
</div>
  