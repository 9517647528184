import {Injectable} from '@angular/core';
import {default as moment} from 'moment';
import {BrowserType} from '../../shared/enum/browser-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  // for creating/updating/sending to server purpose
  public static dateToServerDate(dateTime: any) {
    const localDate = new Date(dateTime);
    const offset = moment(localDate).utcOffset();
    return moment(localDate).add(offset, 'm').toDate();
  }

  // for displaying purpose
  public static dateFromServer(dateTime: any) {
    const offset = new Date(dateTime).getTimezoneOffset();
    const serverDate = new Date(dateTime);
    return moment(serverDate).add(offset, 'm').toDate();
  }

  public static detectBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edg') > -1:
        return BrowserType.EDGE;
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return BrowserType.OPERA;
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return BrowserType.CHROME;
      case agent.indexOf('trident') > -1:
        return BrowserType.IE;
      case agent.indexOf('firefox') > -1:
        return BrowserType.FIREFOX;
      case agent.indexOf('safari') > -1:
        return BrowserType.SAFARI;
      default:
        return BrowserType.OTHERS;
    }
  }

  public static checkBrowserCompatibility(): boolean {
    const browserName = UtilsService.detectBrowserName();

    let compatibleBrowsers: string[] = [BrowserType.CHROME, BrowserType.SAFARI, BrowserType.EDGE];
    if (compatibleBrowsers.includes(browserName)) {
      return true;
    }

    return false;
  }
}
