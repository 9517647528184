import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { LoginService } from '../../../core/services/login.service';
import { CountryGlobalVariables } from '../../constants/country-global-variables';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  info;

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    const idx = this.infoConstants.findIndex(i =>
      i.NAME.toLocaleLowerCase() === this.data.topic.toLowerCase()
      && i.TYPE === this.data.type
    );
    if (idx === -1) {
      this.displaySnackBar(this.data + ' does not have any information.');
      this.close();
      return;
    }

    this.info = this.infoConstants[idx];
  }

  close() {
    this.dialogRef.close();
  }

  private displaySnackBar(msg: string) {
    this.snackBar.open(msg, 'Close', {
      duration: 10 * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  get infoConstants() {
    if (this.loginService.isUkUser()) {
      return CountryGlobalVariables.UK.INFO_CONSTANTS;
    } else {
      return CountryGlobalVariables.US.INFO_CONSTANTS;
    }
  }
}
