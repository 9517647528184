import { UserAuthService } from './../../shared/services/user-auth.service';
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate, Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureService } from '../services/feature.service';
import { NotFoundOrAuthorizedService } from "../services/not-found-or-authorized.service";

@Injectable({
	providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {
	constructor(
		private featureSvc: FeatureService,
		private userAuthService: UserAuthService,
		private notFoundOrAuthorizedService: NotFoundOrAuthorizedService,
		private router: Router
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


		if (this.userAuthService.isAuthenticated()) {
			const feature = route.data.feature;
			const features = this.featureSvc.features;
			const restrictedFeatures = this.featureSvc.restrictedFeatures;

			if (!feature) {
				if (restrictedFeatures === null && features === null) {
					this.initFeatureControl();
				}
				this.notFoundOrAuthorizedService.hide();
				return true;
			} else {
				if (restrictedFeatures && features) {
					return this.isAuthorized(feature);
				} else {
					Promise.all(this.initFeatureControl()).then(r => {
						return this.isAuthorized(feature);
					});
				}
			}
		}
	}

	isAuthorized(feature: string) {
		const hasAccess = this.featureSvc.hasAccess(feature);

		if (!hasAccess) {
			this.redirect();
		}
		return hasAccess;
	}

	initFeatureControl(): Promise<boolean>[] {
		const promiseList: Promise<boolean>[] = [];

		promiseList.push(
			new Promise((release, reject) => {
				this.featureSvc.getFeatures().subscribe(
					result => {
						this.featureSvc.features = result;
						release(true);
					}, error => {
						this.featureSvc.features = null;
						reject(error);
					}
				);
			})
		);

		promiseList.push(
			new Promise((release, reject) => {
				this.featureSvc.getRestrictedFeatures().subscribe(
					result => {
						this.featureSvc.restrictedFeatures = result;
						release(true);
					}, error => {
						this.featureSvc.restrictedFeatures = null;
						reject(error);
					}
				);
			})
		);

		return promiseList;
	}

	redirect() {
		this.router.navigate(['my-events']);
	}
}
