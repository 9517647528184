<div class="table-overflow">
	<table mat-table [dataSource]="dataSource">
		<ng-container [matColumnDef]="col.colDef" *ngFor="let col of columns">

			<!-- Column Header -->
			<th class="{{ col.colWidthClass }}" mat-header-cell *matHeaderCellDef>
				{{ col.colHeader }}
			</th>

			<!-- Column Content -->
			<span *ngIf="col.colType === getColumnType.FULL_PERCENTAGE">
				<td mat-cell *matCellDef="let element">
					<span *ngIf="element[col.colDef]?.target != null && element[col.colDef]?.actual != null">
						{{ element[col.colDef]?.target }}%
						(<span [ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightActual }">{{element[col.colDef]?.actual}}%</span>)
					</span>
					<span *ngIf="element[col.colDef]?.target == null || element[col.colDef]?.actual == null"> - </span>
				</td>
			</span>
			<span *ngIf="col.colType === getColumnType.PARTIAL_PERCENTAGE">
				<td mat-cell *matCellDef="let element"> 
					<span *ngIf="element[col.colDef]?.target != null && element[col.colDef]?.actual != null">
						{{ element[col.colDef]?.target }} 
						(<span [ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightActual }">{{element[col.colDef]?.actual}}%</span>)
					</span>
					<span *ngIf="element[col.colDef]?.target == null || element[col.colDef]?.actual == null"> - </span>
				</td>
			</span>
			<span *ngIf="col.colType === getColumnType.RATIO">
				<td mat-cell *matCellDef="let element"> 
					<span *ngIf="element[col.colDef]?.target != null && element[col.colDef]?.actual != null">
						{{ element[col.colDef]?.target }} /
						<span [ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightActual }">{{element[col.colDef]?.actual}}</span>
					</span>
					<span *ngIf="element[col.colDef]?.target == null || element[col.colDef]?.actual == null"> - </span>
					</td>
			</span>
			<span *ngIf="col.colType === getColumnType.STRING">
				<td mat-cell *matCellDef="let element"
					[ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightValue }"> 
					<span *ngIf="element[col.colDef]?.value != null">{{ element[col.colDef]?.value }}</span>
					<span *ngIf="element[col.colDef]?.value == null"> - </span>
			</td>
			</span>
			<span *ngIf="col.colType === getColumnType.NUMBER">
				<td mat-cell *matCellDef="let element"> 
					<span *ngIf="element[col.colDef]?.value != null"
						[ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightValue }">
						{{ element[col.colDef]?.value }}</span>
					<span *ngIf="element[col.colDef]?.value == null"
						[ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightValue }">
						- </span>
				</td>
			</span>
			<span *ngIf="col.colType === getColumnType.DATE">
				<td mat-cell *matCellDef="let element"> 
					<span *ngIf="element[col.colDef]?.value != null"
						[ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightValue }">
						{{ element[col.colDef]?.value | customDate }} </span>
					<span *ngIf="element[col.colDef]?.value == null"
						[ngClass]="{ 'highlight-metric': element[col.colDef]?.highlightValue }">
						- </span>
				</td>
			</span>

		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
</div>
