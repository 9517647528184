<div>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <app-generic-view
    [config]="buildConfig()" [sort]="sortOrders"
    [filterForm]="form" [sortHeaders]="sortHeaders"
    [parentSubject]="dataSubject" [length]="length"
    (filterEmitter)="refreshData($event)"
    (viewDetailsEmitter)="goToStoreDetails($event)"
  ></app-generic-view>
</div>
