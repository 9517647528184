<mat-card
  fxLayout="row"
  *ngFor="let dat of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
  class="padding-10 ghost-ui"
>
  <div
    fxFlex
    fxLayout="column"
    fxLayoutGap="10px"
    class="shimmer"
  >
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      class="shimmer"
    ></div>

    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <div fxFlex class="shimmer"></div>
      <div fxFlex class="shimmer"></div>
      <div fxFlex class="shimmer"></div>
    </div>
  </div>
</mat-card>
