import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { NotFoundOrAuthorizedService } from '../../../core/services/not-found-or-authorized.service';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { ZoneUpdateService } from '../../../core/services/zone-update.service';
import { Zone } from '../../../shared/models/zone-update/zone.model';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { LoginService } from '../../../core/services/login.service';
import { Country } from '../../../shared/enum/country.enum';

@Component({
  selector: 'app-zone-update',
  templateUrl: './zone-update.component.html',
  styleUrls: ['./zone-update.component.scss']
})
export class ZoneUpdateComponent implements OnInit {
  terr: number;
  p_rte: string;

  formData: UntypedFormGroup;
  territoryList = [];
  pRouteList: Zone[] = [];
  zoneList = [];
  isUpdateSuccess = false;

  filteredPRouteList: ReplaySubject<Zone[]> = new ReplaySubject<Zone[]>(1);
  pRouteFilterCtrl: UntypedFormControl = new UntypedFormControl();

  // error list
  messageList = [];
  showNotification = false;

  protected _onDestroy = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              public terrService: TerritoryService,
              private msgBanner: MsgBannerService,
              private zoneService: ZoneUpdateService,
              private notFoundOrAuthorizedService: NotFoundOrAuthorizedService,
              private titleService: Title,
              private loginService: LoginService) {
    this.titleService.setTitle('Zone Updates');
    this.notFoundOrAuthorizedService.show();
  }

  ngOnInit(): void {
    this.initForm();

    this.route.queryParams.subscribe((params) => {
      this.terr = params.terr ? Number(params.terr) : null;
      this.p_rte = params.p_rte;

      this.displayLeftTopNavBars(params.display_navbars);
      this.loadFormData();
    });
  }

  initForm() {
    this.formData = this.fb.group({
      terr: [null, [], []],
      p_rte: [null, [], []],
      zone: [null, [this.zoneValidtor], []]
    });

    this.pRouteFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => { this.filterPRouteList()});
  }

  filterPRouteList() {
    if(!this.pRouteList) {
      return;
    }

    let search = this.pRouteFilterCtrl.value;
    if (!search) {
      this.filteredPRouteList.next(this.pRouteList);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPRouteList.next(
      this.pRouteList.filter(p => p.pRoute.toLowerCase().indexOf(search) > -1)
    );
  }

  loadFormData() {
    if (this.loginService._country === Country.UK)
      return;

    this.showNotification = false;
    this.loadTerritoryData();
  }

  loadTerritoryData() {
    this.terrService.getUserAssignedTerritories().subscribe(
      (response) => {
        this.territoryList = response;
        this.populateTerritoryField();
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        }
        this.showNotification = true;
      }
    );
  }

  populateTerritoryField() {
    if (this.terr == null) {
      this.terr = this.terrService.getTerritory();
      this.terr = this.terr == null ? this.territoryList[0] : this.terr;
    } else if (!this.territoryList.includes(this.terr)) {
      this.showNotification = true;
      this.msgBanner.addMsgError(this.messageList,
        'The provided territory ' + String(this.terr) + ' is incorrect or not assigned to your username.');
      this.terr = this.terrService.getTerritory();
    }

    this.formData.controls.terr.setValue(this.terr);
    this.loadPRoutData();
    this.loadZonesData();
  }

  loadPRoutData() {
    this.pRouteList = [];
    this.filteredPRouteList.next([]);

    this.zoneService.getPRoutesByTerritory(this.terr).subscribe(
      (response: Zone[]) => {
        this.pRouteList = response;
        this.filteredPRouteList.next(response);
        this.populateProutField();
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
        }
        this.showNotification = true;
      }
    );
  }

  populateProutField() {
    if (this.p_rte == null)
      return;

    let pRoute = this.pRouteList.find(p => p.pRoute === this.p_rte);
    if (pRoute != undefined) {
      this.formData.controls.p_rte.setValue(pRoute);
      this.formData.controls.zone.setValue(pRoute.zone == null ? null : pRoute.zone);
    }
  }

  onPRouteChange() {
    let pRoute = this.formData.controls.p_rte.value;
    if (pRoute) {
      this.formData.controls.zone.setValue(pRoute.zone == null ? null : pRoute.zone);
    }
  }

  loadZonesData() {
    this.zoneList = [];

    this.zoneService.getZonesByTerritory(this.terr).subscribe(
      (response: String[]) => {
        this.zoneList = response;
      },
      (error) => {
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );
  }

  onTerritoryChange() {
    this.terr = this.formData.controls.terr.value;
    this.formData.controls.p_rte.setValue(null);
    this.formData.controls.zone.setValue(null);

    this.showNotification = false;
    this.loadPRoutData();
    this.loadZonesData();
  }

  onSave() {
    const data: Zone = {
      terr: Number(this.formData.controls.terr.value),
      pRoute: String(this.formData.controls.p_rte.value?.pRoute),
      zone: String(this.formData.controls.zone.value).padStart(4, '0')
    }

    this.zoneService.updateZone(data).subscribe(
      (response) => {
        this.isUpdateSuccess = true;
      },
      (error) => {
        this.showNotification = true;
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
      }
    );

  }

  displayLeftTopNavBars(flag) {
    if (flag === '1') {
      this.notFoundOrAuthorizedService.hide();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  zoneValidtor(control: AbstractControl): { [key: string]: boolean } | null {
    let input = control.value;
    if (input === null || input === undefined) {
      return null;
    }

    if ((input.length < 0 || input.length > 4) && input != 'Unassigned') {
      return { 'zoneLength': true };
    }

    if (input.length > 0 && input.length <= 4 && !/^[0-9]+$/.test(input)) {
      return { 'zoneFormat': true };
    }
    return null;
  }

  refresh() {
    window.location.reload();
  }
}
